.tab {
  font-style: normal;
  font-weight: 700;
  font-size: 1.7rem;
  line-height: 2.5rem;
  background-color: #000000;
  color: #ffffff;
  border-radius: 2rem;
  padding: 0.4rem 1.4rem;
  margin-right: 0.8rem;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  &.viewMobile {
    background: #ffffff;
    border-radius: 2rem;
    color: #000000;
    // width: 50%;
    text-align: center;
    padding: 0.2rem 1.5rem;
    margin-right: 1rem;
  }

  &.tab_active {
    background-color: #ff3e6c;
    color: #fff;

    &.viewMobile {
      background: #000000;
      box-shadow: 0px 2px 5px rgba(103, 103, 103, 0.25);
      font-style: normal;
      font-weight: 700;
      font-size: 1.7rem;
      line-height: 2.5rem;
      color: #ffffff;
    }
  }
}
