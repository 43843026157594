.search_block {
  padding: 0rem 2rem 1rem;
  .search_relative_container {
    position: relative;
    height: 5rem;
    margin-bottom: 0rem;
    z-index: 10;
    .search_friend {
      padding: 0.8rem 1rem 0.8rem 2.2rem;
      z-index: 1;
      background: #ffffff;
      border-radius: 7px;
      border: 1px solid #f0f0f0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .scope {
        padding-right: 2rem;
      }
      input {
        padding: 0.7px 0;
        margin-right: 1rem;
        font-size: 1.6rem;
        line-height: 1.9rem;
        color: #000000;
        border: none;
        width: 100%;
        height: 3rem;
        &::placeholder {
          color: #adadad;
        }
      }
      button {
        background: #f9f9f9;
        color: #d3d3d3;
        box-shadow: none;
        flex-shrink: 0;
        width: 3rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        border-radius: 50%;
        padding: 0;
        outline: none;
        img {
          width: 1.2rem;
        }
      }
    }
  }
}
