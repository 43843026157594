.breadcrumbs {
  display: flex;
  align-items: center;
  font-family: 'Circe'; // TODO: add fallback fonts
  margin-bottom: 1.2rem;

  .crumb {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: #000000;
    margin-right: 3.1rem;
    border-bottom: 1px solid #000000;
    position: relative;

    &:after {
      content: '';
      background: url('/images/icons/categ-map-arrow.svg');
      width: 1.1rem;
      height: 0.9rem;
      display: inline-block;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto 1rem;
    }
  }
}

.container {
  .headline {
    font-weight: bold;
    font-size: 3.2rem;
    line-height: 4.7rem;
    position: relative;
    margin-bottom: 2rem;

    &:after {
      content: '';
      background: url('/images/icons/quest&ask.svg');
      width: 4.9rem;
      height: 4.9rem;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
    }
  }

  .questions {
    font-family: 'Circe'; // TODO: add fallback fonts

    .viewMobile & {
      padding-top: 2rem;
    }

    .question {
      background: #ffffff;
      border: 1px solid rgba(234, 234, 234, 0.5);
      box-sizing: border-box;
      box-shadow: 0px 0px 10px #d9e8ed;
      border-radius: 12px;
      margin-bottom: 2.5rem;
      display: block;
      text-align: left;

      .questionTitle {
        padding: 2rem 3rem 2rem 5.5rem;
        font-weight: bold;
        font-size: 1.8rem;
        line-height: 160%;
        color: #000000;
        position: relative;
        display: block;
        cursor: pointer;

        .viewMobile & {
          font-size: 1.4rem;
          line-height: 1.8rem;
        }

        &:before,
        &:after {
          content: '';
          margin: auto;
          content: '';
          position: absolute;
          top: 0;
          left: 3rem;
          bottom: 0;
          width: 1.6rem;
          height: 0.2rem;
          background-color: #000000;
          transition: all 0.2s;
        }
        &:before {
          transform: translate(-50%, -50%);
        }
        &:after {
          transform: translate(-50%, -50%) rotate(90deg) scale(1, 1);
        }
      }

      .questionContent {
        padding: 0 3rem 0 5.5rem;
        font-size: 1.8rem;
        line-height: 160%;
        color: #000000;
        display: block;
        opacity: 0;
        overflow: hidden;
        transition: opacity 0.25s linear;

        .viewMobile & {
          font-size: 1.4rem;
          line-height: 1.8rem;
        }
      }

      &.question_open {
        .questionTitle {
          &:after {
            transform: translate(-50%, -50%) rotate(90deg) scale(0, 1);
          }
        }

        .questionContent {
          opacity: 1;
          padding-bottom: 2rem;
        }
      }
    }
  }
}
