.cashback {
  width: 100%;
  height: 100%;
  font-family: 'Circe';
  display: block;

  .container {
    padding: 0 3rem;
    .cash_img {
      width: 100%;
      height: 100%;
      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }
    .cashback_info {
      .title {
        font-style: normal;
        font-weight: bold;
        font-size: 2.8rem;
        line-height: 100%;
        color: #000000;
        margin-bottom: 1.5rem;
      }
      .subtitle {
        font-style: normal;
        font-weight: normal;
        font-size: 1.7rem;
        line-height: 130%;
      }

      .stage {
        .stage_title {
          font-style: normal;
          font-weight: normal;
          font-size: 1.7rem;
          line-height: 130%;
          margin-bottom: 0.5rem;
        }
        .stage_item {
          display: flex;
          margin-bottom: 0.5rem;

          .left {
            .img_block {
              width: 2.5rem;
              height: 2.5rem;
              flex-shrink: 0;
              img {
                max-width: 100%;
                max-height: 100%;
                object-fit: cover;
              }
            }
          }
          .right {
            .text {
              font-style: normal;
              font-weight: normal;
              font-size: 1.7rem;
              line-height: 130%;
              color: #000000;
              margin-left: 1.5rem;
            }
          }
        }
      }
    }
    .bottom_text {
      margin-top: 1.5rem;
      margin-bottom: 4rem;
      font-style: normal;
      font-weight: normal;
      font-size: 1.7rem;
      line-height: 130%;
      color: #000000;
    }
  }
}
