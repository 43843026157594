.container {
  padding: 2rem 2rem 14rem;
}
.item {
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.7rem;
  line-height: 1.9rem;
  color: #000;
  &.selected {
    background-color: #f9f9f9;
    border-radius: 1rem;
  }
  .icon_container {
    width: 1.8rem;
    height: 1.4rem;
    svg {
      width: 1.8rem;
      height: 1.4rem;
    }
  }
}
.btn_container {
  position: fixed;
  bottom: 8rem;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    width: 26rem;
    height: 5rem;
  }
}
