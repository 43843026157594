// @import '../AboutPage/AboutPage.scss';

.title {
  font-size: 3.2rem;
}
.subtitle {
  margin-top: 1rem;
  color: #000000;
}
.title.red {
  font-size: 2rem;
  color: #ff3e6c;
  line-height: 2.4rem;

  .viewMobile & {
    font-size: 1.8rem;
  }
}
.contacts-links {
  margin-top: 2rem;

  .contacts-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 3rem;

    .viewMobile & {
      flex-direction: column;
      align-items: start;
    }

    .item-container {
      width: 33.3%;

      .viewMobile & {
        width: 100%;
        margin-bottom: 3rem;
      }

      &:last-child {
        width: fit-content;
        margin-top: 4.5rem;

        .viewMobile & {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }

    .contacts-item {
      display: flex;
      // align-items: center;
      width: fit-content;
      background: transparent;
      border: 0;
      text-align: left;
      padding: 0;

      .left {
        .img-block {
          background: #f0f0f0;
          width: 5.2rem;
          height: 5.2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          transition: all 0.3s ease-in-out;

          svg {
            max-width: 2.2rem;
            max-height: 2.5rem;
            transition: all 0.3s ease-in-out;

            path {
              transition: all 0.3s ease-in-out;
            }
          }
        }
      }
      .right {
        margin-left: 1.5rem;
        margin-top: 0.4rem;
        .title {
          font-size: 1.6rem;
          line-height: 2.4rem;
          color: #000000;
        }
        .subtitle {
          margin-top: 0rem;
          color: #000000;

          &.red-link {
            color: #ff3e6c;
            text-decoration: underline;
          }
        }
      }
      &:hover {
        .img-block {
          background: #ff3e6c;
          box-shadow: 0px 4px 10px rgba(255, 0, 76, 0.42);
          svg {
            path {
              fill: #ffffff;
            }
          }
        }
      }
    }

    &.social-links {
      margin-top: 2rem;
      .item-container {
        &:last-child {
          width: 33.3%;
          margin-top: 0;

          .viewMobile & {
            width: 100%;
          }
        }
        .img-block {
          &.vk {
            background: #436eab;
          }
          &.inst {
            background: #ff034e;
          }
          &.fb {
            background: #415e9b;
          }
        }
      }
    }
  }
}
.separator {
  width: 100%;
  background: #e4e4e4;
  opacity: 1;
  margin: 2.8rem 0;
  height: 1px;
}
.separator.about {
  margin-bottom: 3rem;
}
