.add_friend_container {
  display: flex;
  padding: 2rem;
  background: #f8f8f8;

  .item {
    margin-right: 1rem;
    background-color: #ffffff;
    position: relative;
    padding: 1.4rem;
    display: flex;
    align-items: center;
    border-radius: 10px;
    .img_block {
      width: 2rem;
      flex-shrink: 0;
      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }
    .text {
      margin-left: 1rem;
      font-style: normal;
      font-weight: normal;
      font-size: 1.5rem;
      line-height: 100%;
      color: #212121;
    }
    .new_count {
      position: absolute;
      background: #ff3e6c;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      top: -1rem;
      right: -1rem;
      width: 3rem;
      height: 3rem;
      font-style: normal;
      font-weight: bold;
      font-size: 1.5rem;
      line-height: 2.2rem;
      color: #ffffff;
    }
  }
}
