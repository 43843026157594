.search_container {
  padding: 2rem 0 0 2rem;
}
.title_container {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}
.header_black {
  color: #212121;
  padding: 0 2.5rem;
  font-size: 2.8rem;
  line-height: 4.1rem;
  margin-top: 1rem;
  font-weight: bold;
}
.count {
  color: #bebebe;
  padding: 0 2.5rem;
  font-size: 1.5rem;
  line-height: 2.1rem;
}
.sub_categories_container {
  border-bottom: 0.8rem solid #f8f9fb;
  padding-bottom: 2.5rem;
}
.flat_list {
  padding: 0 2.5rem;
  padding-top: 1.5rem;
  display: flex;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
.categ_card {
  width: 8rem;
  height: 8rem;
  background-color: #fafafa;
  border-radius: 1rem;
  overflow: hidden;
  margin-right: 0.5rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
}
.categ_img_block {
  width: 4.5rem;
  height: 4.5rem;
  align-items: center;
  // justify-content: center;
}
.categ_img {
  width: 100%;
  height: 100%;
  // marginTop: 5;
  object-fit: contain;
  margin-bottom: 0.5rem;
}
.categ_name {
  font-size: 1rem;
  line-height: 1.2rem;
  text-align: center;
  padding: 0 0.2rem;
  min-height: 2.4rem;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
.all_categories_header_container {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header {
  color: #ff3e6c;
  padding: 0 2.5rem;
  font-size: 1.8rem;
  line-height: 2.1rem;
  margin-top: 3rem;
  font-weight: bold;
}
.orientation_icon {
  padding: 0 2.5rem;
  margin-top: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.row {
  flex-direction: row;
  display: flex;
  margin: 0 2rem;
  justify-content: space-between;
  padding: 2rem 0;
  border-bottom: 0.1rem solid #f0f0f0;
  > div {
    width: 50%;
  }
  &.horizontal {
    > div {
      width: 100%;
    }
  }
}
