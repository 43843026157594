// @import './FaqPage.scss';

.item {
  display: flex;
  background: #ffffff;
  border-radius: 10px;
  width: calc(50% - 6rem);
  min-height: 16rem;
  padding: 2.5rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0px 4px 40px #f0f0f0;
  color: #000000 !important;
  transition: all 0.3s ease-in-out;
  position: relative;

  &.item_mobile {
    width: 100%;
    margin-right: 0;
    padding: 2rem;
    // justify-content: center;
    // align-items: center;
  }

  &:nth-child(2n) {
    margin-right: 0;
  }

  .right-arrow {
    position: absolute;
    bottom: 2.5rem;
    right: 2rem;
    overflow: hidden;
    text-align: right;
    width: 3.5rem;

    img {
      transition: all 0.3s ease-in-out;
      transform: translateX(-4rem);
    }

    .skybox {
      position: absolute;
      width: 1rem;
      height: 2rem;
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
      background: -webkit-gradient(
        linear,
        right top,
        left top,
        color-stop(36.98%, #ffffff),
        to(rgba(255, 255, 255, 0))
      );
      background: linear-gradient(270deg, #ffffff 36.98%, rgba(255, 255, 255, 0) 100%);
      left: 0;
      top: -0.25rem;
      z-index: 2;
    }
  }

  .left {
    svg {
      width: 3.3rem;
      max-height: 3.2rem;

      path {
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .right {
    padding-left: 2rem;
  }

  .headline {
    font-size: 2rem;
    line-height: 2.9rem;
    margin-top: 0;
  }

  .desc {
    margin-top: 1rem;
    font-size: 1.4rem;
    line-height: 1.6rem;

    .desc-item {
      display: flex;
      margin-bottom: 1.5rem;

      &:last-child {
        margin-bottom: 0;
      }

      .before {
        width: fit-content;
        flex-shrink: 0;
      }
      .txt {
        padding-left: 1rem;
      }
    }
  }

  &:not(.item_mobile):hover {
    transform: translateY(-1rem);
    box-shadow: 0px 4px 60px #eaeaea;

    .left {
      svg {
        path {
          fill: #ff3e6c;
        }
      }
    }
    .right-arrow {
      img {
        transform: translateX(0);
      }
    }
  }
}
