// @import '../AboutPage/AboutPage.module.scss';

.block-first {
  .left {
    width: 100%;

    .btn {
      display: flex;
      align-items: center;
      padding: 1rem 2.5rem;
      margin: 0;
      margin-top: 3rem;
      background: #fff;
      box-shadow: 0 4px 20px #eaeaea;
      color: #000;
      height: 4.2rem;
      width: 100%;
      box-sizing: border-box;
      font-family: Circe;
      font-weight: 700;
      font-size: 1.6rem;
      text-align: center;
      border-radius: 30px;
      border: none;
      img {
        width: 1.75rem;
        padding-right: 1rem;
      }

      .viewMobile & {
        justify-content: center;
      }
    }
  }
  .right {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 6rem;

    .circle-diag {
      background: #ff3e6c;
      box-shadow: 0px 4px 10px rgba(255, 0, 76, 0.42);
      width: 25rem;
      height: 25rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .innercircle {
        background: #ffffff;
        box-shadow: 0px 4px 40px #a32341;
        border-radius: 50%;
        width: 18rem;
        height: 18rem;
      }
      .percent {
        bottom: 0rem;
        right: -3rem;
        position: absolute;
        font-family: 'Circe';
        font-weight: bold;
        font-size: 2.4rem;
        color: #ff3e6c;
      }
    }
  }

  .info-text-flex {
    display: flex;
    margin-top: 5rem;
    font-family: 'Circe';
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2.4rem;
    justify-content: space-between;
    width: 100%;

    .left-half {
      color: #000000;
      text-align: right;
      position: relative;
      width: calc(50% - 1rem);

      .line {
        position: absolute;
        width: 1px;
        height: 16rem;
        background: #020202;
        top: -17rem;
        right: 0.3rem;
      }
      .point {
        width: 0.5rem;
        height: 0.5rem;
        background: #020202;
        border-radius: 50%;
        position: absolute;
        right: 0.1rem;
        top: -17.3rem;
      }
    }
    .right-half {
      position: relative;
      color: #ff3e6c;
      width: calc(50% - 1rem);
      text-align: left;

      .line {
        position: absolute;
        width: 1px;
        height: 3rem;
        background: #ff3e6c;
        top: -4rem;
        left: 0.3rem;
      }
    }
  }
}

.advantages {
  .title {
    font-size: 2.4rem;
    line-height: 3.5rem;
    text-align: center;
  }

  .advantages-flex {
    display: flex;
    justify-content: space-between;
    margin-top: 3.5rem;

    .viewMobile & {
      flex-direction: column;
      justify-content: initial;
    }

    .item {
      width: 30%;

      &:nth-child(2) {
        width: 33%;
      }

      .viewMobile & {
        width: 100%;
        margin-bottom: 3rem;
      }

      .icon {
        width: 4rem;
        height: 4rem;
        background: #ff3e6c;
        box-shadow: 0px 4px 10px rgba(255, 0, 76, 0.42);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 1.6rem;
        }
      }

      &:last-child {
        img {
          width: 2.6rem;
        }
      }

      .title.red {
        margin-top: 2rem;
        font-size: 2rem;
        line-height: 2.9rem;
        color: #ff3e6c;
        text-align: left;
      }
      .subtitle {
        margin-top: 1.5rem;
      }
    }
  }
}
.wantbepartner {
  .title {
    padding-top: 3rem;
    font-size: 2.4rem;
    line-height: 3.5rem;
  }

  .buttons-flex {
    display: flex;
    align-items: center;
    margin-top: 3rem;

    .viewMobile & {
      flex-direction: column;
    }

    .btn-block {
      margin-top: 3rem;

      .viewMobile & {
        width: 100%;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          padding-right: 1rem;
        }
      }
    }

    .or {
      padding: 0 2rem;
      font-size: 1.4rem;

      .viewMobile & {
        margin: 2rem 0;
      }
    }
  }
  // padding-bottom:10rem;
}

.btn_container {
  width: 100%;

  .btn {
    width: 100%;
    box-sizing: border-box;
    padding: 1.3rem 0;
    height: 4.2rem;
    margin: 0 auto;
    display: flex;
    font-family: Circe;
    font-weight: 700;
    font-size: 1.6rem;
    text-align: center;
    border-radius: 30px;
    border: none;
  }
}
