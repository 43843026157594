.burger_menu {
  display: flex;
  align-items: center;
  margin-left: 2rem;
  .count {
    width: 2.2rem;
    height: 2.2rem;
    background-color: #ff3e6c;
    border-radius: 50%;
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 1;
    span {
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: bold;
      font-size: 1.4rem;
      line-height: 1.1rem;
      color: #ffffff;
    }
  }
  button.burger {
    position: relative;
    width: 2rem;
    height: 2rem;
    background: none;
    box-shadow: none;
    top: 2px;

    span {
      position: absolute;
      left: 50%;
      width: 2rem;
      height: 2px;
      background: #000000;

      &:nth-child(1) {
        top: 0;
        transform: translateX(-50%) translateY(0);
        transition: top 0.3s cubic-bezier(0.21, 0.83, 0.26, 0.87) 0.3s,
          transform 0.3s cubic-bezier(0.21, 0.83, 0.26, 0.87);
      }
      &:nth-child(2) {
        top: 8px;
        opacity: 1;
        transform: translateX(-50%);
      }
      &:last-child {
        top: 16px;
        transform: translateX(-50%) translateY(0);
        transition: top 0.3s cubic-bezier(0.21, 0.83, 0.26, 0.87) 0.3s,
          transform 0.3s cubic-bezier(0.21, 0.83, 0.26, 0.87);
      }
    }

    &.open {
      top: 0px;
      span {
        &:nth-child(1) {
          top: 50%;
          transform: translateX(-50%) translateY(0) rotate(45deg);
          transition: top 0.3s cubic-bezier(0.21, 0.83, 0.26, 0.87),
            transform 0.3s cubic-bezier(0.21, 0.83, 0.26, 0.87) 0.3s;
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:last-child {
          top: 50%;
          transform: translateX(-50%) translateY(0) rotate(-45deg);
          transition: bottom 0.3s cubic-bezier(0.21, 0.83, 0.26, 0.87),
            transform 0.3s cubic-bezier(0.21, 0.83, 0.26, 0.87) 0.3s;
        }
      }
    }
  }
}
