.share_btn {
  width: 3.2rem;
  height: 3.2rem;
  background: #ff3e6c;
  box-shadow: 0 0.4rem 1rem rgba(255, 0, 76, 0.42);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  flex-shrink: 0;
  padding: 0 !important;

  svg {
    width: 1.8rem;
    height: 1.4rem;
    object-fit: contain;
  }
}
