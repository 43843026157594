.preview_article {
  width: 100%;
  height: 100%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .bottom {
    position: absolute;
    z-index: 1;
    bottom: 3rem;
    left: 3rem;
    width: calc(100% - 6rem);
    color: #fff;
    .title {
      font-weight: bold;
      font-size: 3.6rem;
      line-height: 3.8rem;
      pointer-events: none;
    }
    .subtitle {
      font-size: 2.4rem;
      line-height: 2.8rem;
      margin-top: 1.4rem;
      pointer-events: none;
    }
  }
}
.top_shadow {
  position: absolute;
  top: 0;
  left: 0;
  height: 20rem;
  width: 100%;
  transform: rotate(180deg);
  z-index: 1;
  background: linear-gradient(360deg, rgba(0, 0, 0, 0.7) -11.26%, rgba(255, 255, 255, 0) 62.78%);
  transition: opacity 0.3s ease;
}
.bottom_shadow {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 30rem;
  width: 100%;
  background: linear-gradient(1.84deg, rgba(0, 0, 0, 0.5) 41.8%, rgba(255, 255, 255, 0) 98.32%);
  z-index: 1;
  transition: opacity 0.3s ease;
}
.product_article {
  width: 100%;
  height: 100%;
  position: relative;
  background: #fff;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .bottom {
    position: absolute;
    bottom: 3rem;
    left: 3rem;
    width: calc(100% - 6rem);
    color: #fff;
    z-index: 999999;
    transition: opacity 0.3s ease;
  }

  .product_preview {
    background: #ffffff;
    box-shadow: 0 0.4rem 4rem #f0f0f0;
    border-radius: 1rem;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;

    .prod_img {
      width: 5rem;
      height: 5rem;
      flex-shrink: 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .prod_info {
      margin-left: 1rem;
      .prod_title {
        font-weight: bold;
        font-size: 1.7rem;
        line-height: 2.5rem;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: #000;
      }
      .prod_price {
        font-size: 1.5rem;
        line-height: 2.2rem;
        color: #747474;
      }
    }
  }
  .see_more {
    font-size: 1.5rem;
    line-height: 2.2rem;
    color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3rem;
    padding: 0.6rem 3rem 0.4rem 3rem;
    width: fit-content;
    margin: 1rem auto;
    display: block;
  }
  .trigger {
    width: 1.2rem;
    display: block;
    margin: 3.5rem auto 0;
  }
  .plus_icon {
    border: none;
    border-radius: 50%;
    background-color: #ff3e6c;
    width: 3.6rem;
    height: 3.6rem;
    position: absolute;
    bottom: 1rem;
    right: 0rem;
    padding: 0;
    img {
      width: 1.8rem;
    }
  }
  .loader {
    margin: 0 auto;
    padding: 0;
  }
}

.hidden {
  opacity: 0;
}
