.content {
  padding-left: 8rem;
  width: calc(100% - 60rem);

  &.content_mobile {
    width: 100%;
    padding-left: 0;
  }
}

.contentTitle {
  font-weight: bold;
  font-size: 3.2rem;
  line-height: 4.7rem;
  position: relative;
  color: #0a0a0a;
  margin-bottom: 2rem;
}
