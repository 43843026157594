.spinner {
  padding: 3rem;
  display: flex;
  justify-content: center;

  > img {
    width: 4rem;
    height: 4rem;
  }
}
