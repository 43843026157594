.hide {
  display: none !important;
}

.show_cashback {
  display: block !important;
}

.right_header {
  display: flex;
  .more_img {
    width: 3.2rem;
    height: 3.2rem;
    background: #f9fafc;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 0.8rem;
    img {
      width: 1.5rem;
    }
  }
  .share_img {
    width: 3.2rem;
    height: 3.2rem;
    background: #ff3e6c;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    img {
    }
  }
}

.product_container {
  font-family: 'Circe';
  position: relative;
  .add_button_container {
    display: flex;
    align-items: center;
    justify-content: center;
    &.fixed {
      position: fixed;
      z-index: 3;
      bottom: 2rem;
      left: 50%;
      transform: translate(-50%, 8rem);
      transition: transform 0.3s ease;

      &.show {
        transform: translate(-50%, 0);
      }
    }
  }
  .add_button {
    font-weight: bold;
    font-size: 1.7rem;
    line-height: 2.5rem;
    width: 25rem;
    height: 5rem;
  }
  .product_slider {
    .category {
      font-style: normal;
      font-weight: bold;
      font-size: 1.7rem;
      line-height: 2.5rem;
      color: #ff004c;
      padding: 0 2rem;
    }
    .product_name {
      padding: 0 2rem;
      font-style: normal;
      font-weight: bold;
      font-size: 2.8rem;
      line-height: 100%;
      color: #000000;
      overflow: hidden;
    }
    .swiper_container {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
      padding-bottom: 1rem;

      .slide {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 32rem;
        height: 29rem;
        margin-top: 1rem;
        margin-bottom: 1rem;

        img {
          max-width: 90%;
          max-height: 90%;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .price_block {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 2.8rem 2rem 3.5rem;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: 1px;
        height: 4.2rem;
        background: #f6f6f6;
        top: 47%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .left {
        .price_name {
          font-style: normal;
          font-weight: normal;
          font-size: 1.2rem;
          line-height: 1.8rem;
          color: #747474;
        }
        .price {
          font-style: normal;
          font-weight: bold;
          font-size: 1.7rem;
          line-height: 2.5rem;
          color: #ff004c;
        }
      }
      .right {
        .cashback_name {
          font-style: normal;
          font-weight: normal;
          font-size: 1.2rem;
          line-height: 1.8rem;
          color: #212121;
        }
        .cashback {
          display: flex;
          align-items: center;
          font-style: normal;
          font-weight: bold;
          font-size: 1.7rem;
          line-height: 2.5rem;
          color: #000000;
          .img_block {
            margin-left: 1.2rem;
            img {
              max-width: 100%;
              max-height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
  .idea_gift {
    padding: 2rem;
    width: calc(100% - 4rem);
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    background: #f8f8f8;
    display: flex;
    align-items: center;

    .margin {
      box-sizing: border-box;
      width: 1rem;
      flex-shrink: 0;
      height: 2rem;
      background: transparent;
    }

    .block {
      background: #ffffff;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 1.4rem 1.8rem 1.4rem 2rem;
      flex-shrink: 0;
      display: flex;
      align-items: center;

      .img_block {
        width: 2.4rem;
        height: 2.4rem;

        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: cover;
        }
      }
      .text {
        font-style: normal;
        font-weight: normal;
        font-size: 1.5rem;
        line-height: 100%;
        color: #212121;
        margin-left: 1rem;

        span {
          font-weight: bold;
        }
      }

      &.idea {
        border: 1px solid #aa11ff;
        margin-right: 1.7rem;
      }
      &.gift {
        border: 1px solid #fbbb00;
        margin-right: 1rem;
      }
    }
  }
  .desc_container {
    padding: 2.5rem 2rem;
    border-bottom: 2px solid #f8f8f8;
    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 1.7rem;
      line-height: 2.5rem;
      color: #000000;
      margin-bottom: 1.3rem;
    }
    .text {
      font-style: normal;
      font-weight: normal;
      font-size: 1.5rem;
      line-height: 130%;
      color: #2d2d2d;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;

      &.text_show {
        -webkit-line-clamp: 200;
      }
    }
    .more {
      color: #ff004c;
      font-style: normal;
      font-weight: normal;
      font-size: 1.5rem;
      line-height: 130%;
    }
    .price_upd {
      font-style: normal;
      font-weight: normal;
      font-size: 1.5rem;
      line-height: 130%;
      color: #747474;
    }
  }
  .wish_container {
    padding: 2.5rem 2rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .left,
    .right {
      display: flex;
      align-items: center;
      .img_block {
        width: 2.7rem;
        height: 2.7rem;

        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: cover;
        }
      }
      .text {
        margin-left: 1.1rem;
        .top {
          font-style: normal;
          font-weight: normal;
          font-size: 1.7rem;
          line-height: 2.5rem;
          color: #000000;
        }
        .bottom {
          font-style: normal;
          font-weight: normal;
          font-size: 1.2rem;
          line-height: 1.8rem;
          color: #747474;
        }
      }
    }
  }
  .character_container {
    border-top: 1px solid #f8f8f8;
    padding: 2.5rem 2rem;
    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 1.7rem;
      line-height: 2.5rem;
      color: #000000;
      margin-bottom: 1.3rem;
    }
    .inner_content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        .name {
          font-style: normal;
          font-weight: normal;
          font-size: 1.5rem;
          line-height: 130%;
          color: #747474;
          margin-bottom: 0.5rem;
        }
      }
      .right {
        .info {
          font-style: normal;
          font-weight: normal;
          font-size: 1.5rem;
          line-height: 130%;
          color: #2d2d2d;
          margin-bottom: 0.5rem;
        }
      }
    }
  }
  .more_products {
    padding: 2.5rem 0rem 1rem;
    border-top: 0.8rem solid #eaeaea;

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 1.7rem;
      line-height: 2.5rem;
      padding: 0 2rem;
      display: flex;
      align-items: center;
      color: #000000;
    }
  }
}

.prod_list {
  margin-top: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .card_item {
    width: calc(50% - 2rem);
    padding: 0 1rem;
    padding-bottom: 2.5rem;
    margin-top: 2rem;
    border-bottom: 1px solid #f0f0f0;
    height: 24.8rem;
    position: relative;

    .card_border {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        height: 104%;
        width: 1px;
        background-color: #f0f0f0;
        right: -1rem;
        top: 0;
      }
    }
    .card_container {
      .top {
        position: relative;
        .drop_down {
          position: relative;
          top: 0;
          width: 15px;
          height: 3px;
          margin-left: auto;
          right: 0.5rem;

          span {
            width: 3px;
            height: 3px;
            background: #d4d4d4;
          }
          .drop1,
          .drop2,
          .drop3 {
            position: absolute;
            border-radius: 50%;
          }
          .drop1 {
            left: 0px;
          }
          .drop2 {
            left: 5px;
          }
          .drop3 {
            left: 10px;
          }
        }
        .clouds {
          display: flex;
          flex-wrap: wrap;
          z-index: 2;
          position: absolute;
          min-height: 4.6rem;
          height: 100%;
          top: 0;
          left: 0;

          .cloud {
            border-radius: 30px;
            padding: 0.4rem 0.8rem 0.2rem;
            font-style: normal;
            font-weight: bold;
            font-size: 1rem;
            line-height: 1.5rem;
            color: #ffffff;
            max-height: 1.6rem;
            height: 100%;
            width: fit-content;
            margin-right: 0.3rem;
            margin-bottom: 0.2rem;
            &.idea {
              background: #fbbb00;
            }
            &.hit {
              background: #8a14ff;
            }
            &.wish {
              background: #31b2ff;
            }
          }
        }
      }
      .img_block {
        z-index: 1;
        width: 11.8rem;
        height: 15.7rem;
        margin-bottom: 1rem;
        margin-right: auto;
        margin-left: auto;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .cashback {
          position: absolute;
          right: 0;
          bottom: 0;
          display: flex;
          padding: 0.3rem 0.5rem;
          align-items: center;
          justify-content: center;
          background: rgba(0, 0, 0, 0.6);
          border-radius: 10px;

          .cash_img {
            width: 14px;
            height: 14px;
            margin-right: 0.3rem;

            img {
              max-width: 100%;
              max-height: 100%;
              object-fit: cover;
            }
          }
          .cash {
            font-style: normal;
            font-weight: bold;
            font-size: 1rem;
            line-height: 71%;
            color: #ffffff;
            margin-top: 0.3rem;
          }
        }

        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: cover;
        }
      }
      .title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        height: 3.4rem;
        text-overflow: ellipsis;
        margin-bottom: 0.6rem;
        font-size: 1.5rem;
        line-height: 115%;
        font-style: normal;
        font-weight: normal;
        color: #000000;
      }
      .price {
        font-style: normal;
        font-weight: bold;
        font-size: 1.5rem;
        line-height: 100%;
        color: #000000;
        margin-bottom: 1rem;
      }
      .partner {
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 100%;
        color: #747474;
        min-height: 1rem;
        height: 100%;
      }
    }
    .add_button {
      width: 2.7rem;
      height: 2.7rem;
      position: relative;
      left: 0rem;
      bottom: 1.5rem;
      margin-left: auto;

      svg {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }
  }
}

.dealer_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 2px solid #f8f8f8;
  padding: 2rem;
  .left_block {
    display: flex;
    flex-direction: column;

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 1.7rem;
      line-height: 2.5rem;
      color: #363636;
      margin-bottom: 0.5rem;
    }
    .name {
      font-style: normal;
      font-weight: bold;
      font-size: 1.5rem;
      line-height: 2.2rem;
      color: #828d99;
    }
  }
  .right_block {
    .img_block {
      width: 7rem;
      height: 3rem;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
  }
}
.partner_info,
.categ_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 2px solid #f8f8f8;
  padding: 1.7rem 2rem;

  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 1.7rem;
    line-height: 2.5rem;
    color: #363636;
  }
  .img_block {
    width: 1.3rem;
    height: 2.2rem;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }
}

.row {
  flex-direction: row;
  display: flex;
  margin: 0 2rem;
  justify-content: space-between;
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-bottom-width: 0.1rem;
  border-bottom-color: #f0f0f0;
  border-bottom-style: solid;
  > div {
    width: 50%;
  }
}
