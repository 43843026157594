.detailsContainer {
  background: #f0f0f0;
  padding: 2.5rem 5rem;

  .viewMobile & {
    margin: 1.6rem;
    border-radius: 2rem;
    padding: 1.8rem;
  }

  .detailsContainerLabel {
    font-weight: bold;
    font-size: 2.6rem;
    line-height: 3.8rem;
    text-align: center;
    color: #000000;
    margin-top: 0;
  }

  .details {
    display: flex;
    margin-top: 2rem;
    justify-content: space-between;

    .viewMobile & {
      margin-top: 0;
    }

    .detailsAmountContainer,
    .detailsTypeContainer {
      width: 50%;
    }

    .detailsAmountContainer {
      .detailsAmountLabel {
        font-family: 'Circe';
        font-size: 1.6rem;
        line-height: 1.9rem;
        color: #000000;
        font-weight: normal;
        text-align: left;
      }

      .detailsAmount {
        font-weight: 500;
        font-size: 2.4rem;
        line-height: 2.8rem;
        margin-top: 0.5rem;
        margin-bottom: 1.6rem;
      }

      .detailsPaymentSystem {
        font-size: 1.3rem;
        line-height: 1.5rem;

        > img {
          padding-right: 1rem;
        }
      }
    }

    .detailsTypeContainer {
      font-size: 1.4rem;
      line-height: 1.6rem;
      text-align: right;

      .detailsTypeIcon {
        img {
          width: 4rem;
        }
      }

      .detailsType,
      .detailsDate {
        margin-top: 1rem;
      }
    }
  }
}

.senderContainer {
  padding: 2rem 5rem 0 5rem;

  .viewMobile & {
    margin: 0 1.6rem 5rem;
    padding: 0;
  }

  .senderContainerLabel {
    font-family: 'Circe';
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.6rem;
    text-align: left;

    .viewMobile & {
      padding-left: 2rem;
      margin-bottom: 1rem;
    }
  }

  .sender {
    .viewMobile & {
      padding: 0 1.6rem;
      box-shadow: 0px 4px 40px #f0f0f0;
      border-radius: 20px;
    }
  }
}
