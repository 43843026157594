.container {
  margin: 2rem auto;
  text-align: center;
  font-size: 1.5rem;
  line-height: 132.5%;
  color: #000000;
  a {
    color: #ff3e6c;
    &:hover {
      text-decoration: underline;
    }
  }
}
