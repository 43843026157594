.container {
  flex: 1;
  padding: 0 2rem;

  &.viewMobile {
    padding: 0 2rem;
  }
}

.title {
  font-size: 3.2rem;
  line-height: 4.7rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.sectionTitle {
  font-size: 2rem;
  line-height: 2.9rem;
  margin-bottom: 1.8rem;
  font-family: Circe;
  font-weight: bold;
  color: #ff3e6c;
}
