.container {
  position: relative;

  .confetti {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .video_gif {
    width: 100%;
    height: 73%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .donates {
    padding-top: 10rem;
    max-width: 144rem;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 9rem;
    .top_donates {
      display: flex;
      position: relative;
      // margin-top: 10rem;

      .item {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        &.item1 {
          left: 75%;
          transform: translateY(-10%);
        }
        &.item2 {
          left: 68%;
          transform: translateY(180%);
        }
        &.item3 {
          left: 63%;
          transform: translateY(53%);
          transition: all 0.5s;
          // opacity: 0;
        }
        &.item4 {
          left: 59%;
          transform: translateY(280%);
          transition: all 0.5s;
          // opacity: 0;
        }
        &.item5 {
          left: 30%;
          transform: translateY(190%);
        }
        &.item6 {
          left: 33%;
          transform: translateY(-5%);
          transition: all 0.5s;
          // opacity: 0;
        }
        &.item7 {
          left: 18%;
          transform: translateY(-30%);
        }
        &.item8 {
          left: 7%;
          transform: translateY(50%);
          transition: all 0.5s;
          // opacity: 0;
        }
        &.item9 {
          left: 19%;
          transform: translateY(155%);
          transition: all 0.5s;
          // opacity: 0;
        }

        .img_block {
          img {
          }
        }
        .price {
          background: #ff3e6c;
          border-radius: 50px;
          padding: 0.1rem 0.6rem;
          font-style: normal;
          font-weight: bold;
          font-size: 1.3rem;
          line-height: 1.5rem;
          display: flex;
          align-items: center;
          color: #ffffff;
          width: fit-content;
          transform: translateY(-60%);
        }
      }
    }

    .product {
      display: flex;
      justify-content: center;
      position: relative;
      width: fit-content;
      margin: 0 auto;

      .item {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        &.item1 {
        }
        &.item2 {
        }
        &.item5 {
        }
        &.item7 {
        }
        .img_block_pic {
          img {
          }
        }
        .price_pic {
          background: #ff3e6c;
          border-radius: 50px;
          padding: 0.1rem 0.6rem;
          font-style: normal;
          font-weight: bold;
          font-size: 1.3rem;
          line-height: 1.5rem;
          display: flex;
          align-items: center;
          color: #ffffff;
          width: fit-content;
          transform: translateY(-60%);
        }
      }
      .circle_progress {
        width: 24rem !important;
        height: 24rem;
        z-index: 3;

        svg {
          width: 24rem;
          height: 24rem;
        }
      }
      .blur {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background: rgba(255, 255, 255, 0.7);
        backdrop-filter: blur(4px);
        border-radius: 50%;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 20rem;
        height: 20rem;
        text-align: center;
        z-index: 3;

        .blur_img {
          position: absolute;
          width: 5.8rem;
          height: 4.3rem;
          top: 40%;
          left: 50%;
          transform: translate(-50%, -50%);

          img {
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
          }
        }

        .blur_text {
          font-weight: bold;
          font-size: 1.8rem;
          line-height: 2.1rem;
          position: absolute;
          bottom: 3rem;
        }
      }
      .white {
        position: absolute;
        width: 22rem;
        height: 22rem;
        top: 4%;
        z-index: 1;
        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: cover;
        }
      }
      .img_block {
        position: absolute;
        top: 27%;
        width: 7.7rem;
        height: 7.7rem;
        text-align: center;
        z-index: 2;
        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: cover;
        }
      }
      .price {
        z-index: 2;
        position: absolute;
        top: 68%;
        font-style: normal;
        font-weight: bold;
        font-size: 1.8rem;
        line-height: 2.1rem;
        display: flex;
        align-items: center;
        text-align: center;
        color: #ff3e6c;
      }
    }
  }
  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 6.4rem;
    line-height: 105%;
    text-align: center;
    color: #1d1d1f;
    margin-bottom: 3.5rem;
  }
  .subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 3.6rem;
    line-height: 130%;
    text-align: center;
    color: #1d1d1f;
    padding-bottom: 8rem;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding-top: 0rem;
    padding-bottom: 5rem;
    position: relative;

    .confetti {
      height: calc(100% - 35rem);
    }

    .video_gif {
      width: 100%;
      height: 50%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      object-fit: cover;
    }

    .donates {
      margin-bottom: 5rem;
      .top_donates {
        display: flex;
        position: relative;
        // margin-top: 10rem;

        .item {
          position: absolute;
          display: flex;
          flex-direction: column;
          align-items: center;
          &.item1 {
            left: 6%;
            transform: translateY(-60%);
          }
          &.item2 {
            left: 15%;
            transform: translateY(235%);
          }
          &.item3 {
            left: 72%;
            transform: translateY(225%);
          }
          &.item4 {
            left: 80%;
            transform: translateY(-45%);
          }

          .img_block {
            width: 5rem;
            height: 5rem;
            img {
              max-width: 100%;
              max-height: 100%;
              object-fit: cover;
            }
          }
          .price {
            background: #ff3e6c;
            border-radius: 50px;
            padding: 0.1rem 0.6rem;
            font-style: normal;
            font-weight: bold;
            font-size: 0.9rem;
            line-height: 1.1rem;
            display: flex;
            align-items: center;
            color: #ffffff;
            width: fit-content;
            transform: translateY(-60%);
          }
        }
      }

      .product {
        display: flex;
        justify-content: center;
        position: relative;

        .circle_progress {
          width: 20rem !important;
          height: 20rem;

          svg {
            width: 20rem;
            height: 20rem;
          }
        }
        .blur {
          width: 17rem;
          height: 17rem;

          .blur_img {
            width: 4.8rem;
            height: 3.3rem;
          }
          .blur_text {
            font-size: 1.6rem;
            line-height: 1.8rem;
            bottom: 2.5rem;
          }
        }
        .white {
          width: 18rem;
          height: 18rem;
        }
        .img_block {
          width: 5.5rem;
          height: 5.5rem;
          img {
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
          }
        }
        .price {
          font-size: 1.4rem;
          line-height: 1.6rem;
        }
        .item {
          .img_block_pic {
            width: 5rem;
            height: 5rem;

            img {
              max-width: 100%;
              max-height: 100%;
              object-fit: cover;
            }
          }
          .price_pic {
            font-size: 0.9rem;
            line-height: 1.1rem;
          }
        }
      }
    }
    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 3.2rem;
      line-height: 130%;
      text-align: center;
      color: #1d1d1f;
      margin-bottom: 1.5rem;
      margin-top: 11rem;
    }
    .subtitle {
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 130%;
      text-align: center;
      color: #1d1d1f;
      padding-bottom: 0;
    }
  }
}

.title_mob {
  font-style: normal;
  font-weight: bold;
  font-size: 3.2rem;
  line-height: 100%;
  text-align: center;
  color: #1d1d1f;
  margin-bottom: 2.8rem;
  padding: 0 2rem;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 6rem;
  a {
    background: #ff3e6c;
    box-shadow: 0px 4px 22px rgba(255, 0, 76, 0.42);
    border-radius: 50px;
    font-style: normal;
    font-weight: bold;
    font-size: 1.7rem;
    line-height: 2.5rem;
    text-align: center;
    color: #ffffff;
    padding: 1.35rem 8.8rem;
    width: fit-content;
  }
}

.ios_android {
  display: flex;
  justify-content: center;
  margin-top: 3rem;

  .link {
    .item {
      margin-right: 1.1rem;
      width: 14.2rem;
      height: 4.8rem;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }
  }
}
