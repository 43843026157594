.container {
    .inner_content {
        padding: 14rem 13.8rem 17.6rem;
        max-width: 144rem;
        // width: 100%;
        margin: 0 auto;

        .title {
            font-style: normal;
            font-weight: 600;
            font-size: 4.8rem;
            line-height: 5.7rem;
            text-align: center;
            color: #000000;
            margin-bottom: 6.6rem;
        }

        .item {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 14rem;
            align-items: center;
            .left {
                width: 50%;
                .title {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 3.6rem;
                    line-height: 130%;
                    color: #1D1D1F;
                    margin-bottom: 3.5rem;
                    text-align: left;
                }
                .subtitle {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 2.1rem;
                    line-height: 150%;
                    color: #868686;
                    text-align: left;
                }
            }
            .right {
                max-width: 50%;

                .img_block {
                    width: 50rem;
                    margin: 0 auto;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
        }
        .btn_block {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .btn_title {
                font-style: normal;
                font-weight: 600;
                font-size: 3.6rem;
                line-height: 130%;
                text-align: center;
                color: #1D1D1F;
                margin-bottom: 3.5rem;
            }
            .btn_subtitle {
                font-style: normal;
                font-weight: 600;
                font-size: 2.1rem;
                line-height: 150%;
                text-align: center;
                color: #868686;
                margin-bottom: 5rem;
            }
            button, a {
                width: fit-content;
                background: #FF3E6C;
                box-shadow: 0px 4px 22px rgba(255, 0, 76, 0.42);
                border-radius: 50px;
                font-style: normal;
                font-weight: bold;
                font-size: 2.4rem;
                line-height: 3.5rem;
                text-align: center;
                color: #FFFFFF;
                padding: 1rem 6rem;
                outline: none;
                border: none;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .container {
        .inner_content {
            padding: 5rem 3rem;
            width: unset;
    
            .title {
                font-size: 3.2rem;
                line-height: 130%;
                text-align: left;
                margin-bottom: 3rem;
            }
    
            .item {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                width: 100%;
                margin-bottom: 5rem;
                align-items: center;
                .left {
                    width: 100%;
                    margin-bottom: 3rem;
                    .title {
                        font-size: 2.1rem;
                        line-height: 130%;
                        margin-bottom: 2rem;
                        text-align: left;
                    }
                    .subtitle {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 1.7rem;
                        line-height: 130%;
                        color: #868686;
                    }
                }
                .right {
                    width: 100%;
                    max-width: unset;
    
                    .img_block {
                        width: 100%;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }
            .btn_block {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .btn_title {
                    font-size: 2.1rem;
                    line-height: 130%;
                    margin-bottom: 2rem;
                }
                .btn_subtitle {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 1.7rem;
                    line-height: 130%;
                    text-align: center;
                    color: #868686;
                    margin-bottom: 3rem;
                }
                button, a {
                    font-size: 1.7rem;
                    line-height: 2.5rem;
                    padding: 1.35rem 6rem;
                }
            }
        }
    }
}