.content {
  padding: 0 1.5rem 4rem;

  .headline {
    margin-top: 2rem;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.1rem;
    text-align: center;
    text-transform: uppercase;
    color: #121212;
  }
  .top_block {
    width: calc(100% - 4rem);
    margin-top: 2rem;
    padding: 2rem 2rem 1.5rem;
    background: #ffffff;
    box-shadow: 0px 0.4rem 4rem #f0f0f0;
    border-radius: 2rem;
    display: flex;
    justify-content: space-between;
    position: relative;

    .left {
      .title {
        font-size: 1.4rem;
        line-height: 1.6rem;
      }
      .sum {
        font-size: 2.4rem;
        line-height: 2.8rem;
        margin-top: 0.5rem;
      }
      .type_block {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 1.5rem;
        left: 2rem;

        .rub_wrapper {
          width: 2.4rem;
          height: 2.4rem;
          background: #ff3e6c;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 1rem;
          }
        }
        .text {
          font-size: 1.3rem;
          line-height: 1.5rem;
          padding-left: 1rem;
        }
      }
    }
    .right {
      .icon_wrapper {
        margin-left: auto;
        width: 2.8rem;
        height: 2.8rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        &.red {
          background: #ff3e6c;
          img {
            width: 1.8rem;
            transform: translate(-0.05rem, -0.05rem);
          }
        }
        &.fiol {
          background: #7926ff;
          img {
            width: 1.6rem;
          }
        }
      }
      .text {
        font-size: 1.3rem;
        line-height: 1.5rem;
        text-align: right;
        margin-top: 1.2rem;
      }
      .time {
        margin-top: 1rem;
        font-size: 1.3rem;
        line-height: 1.5rem;
        color: #9d9d9d;
        text-align: right;
      }
    }
  }
  .subtitle {
    margin-top: 2.5rem;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: #090909;
    margin-left: 1.5rem;
    font-weight: bold;
  }
  .bottom_block {
    margin-top: 1rem;
    padding: 2rem 1.5rem 1.5rem 2rem;
    background: #ffffff;
    box-shadow: 0px 0.4rem 4rem #f0f0f0;
    border-radius: 2rem;
    display: flex;
    .left {
      flex-shrink: 0;
      img {
        width: 3.6rem;
        height: 3.6rem;
        box-shadow: 0px 1px 16px rgba(148, 158, 183, 0.5);
        border-radius: 50%;
      }
    }
    .center {
      padding-left: 1rem;
      .name {
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 1.9rem;
      }
      .teg {
        font-size: 1.3rem;
        line-height: 1.5rem;
        color: #747474;
      }
      .sum {
        margin-top: 1.5rem;
        border-radius: 5rem;
        font-weight: bold;
        font-size: 1.3rem;
        line-height: 1.5rem;
        color: #ffffff;
        width: fit-content;
        padding: 0.4rem 1rem 0.3rem 1rem;
        &.red {
          background: #ff3e6c;
        }
        &.fiol {
          background: #7926ff;
        }
      }
      .type {
        margin-top: 1rem;
        color: #d0d0d0;
        font-size: 1.3rem;
        line-height: 1.5rem;
        font-weight: 300;
      }
    }
    .right {
      margin-left: auto;
      img {
        max-width: 4rem;
        max-height: 4rem;
        display: block;
      }
    }
  }
}
