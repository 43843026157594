.footer_container {
  font-family: 'Circe';
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0px 0px 10px rgba(191, 191, 191, 0.25);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background: #ffffff;
  transition: transform 0.3s ease;

  &.hide_footer {
    transform: translateY(8rem);
  }

  > div {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;

    .navLink {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      font-size: 1rem;
      line-height: 1.2rem;
      color: #cecece;
      width: 100%;
      padding: 7px 0 8px 0;

      &.notification_link {
        position: relative;

        .notification_count {
          display: flex;
          background: #ff3e6c;
          border-radius: 50%;
          font-size: 1.2rem;
          font-weight: 500;
          line-height: 1.2rem;
          color: #ffffff;
          position: absolute;
          top: 0;
          right: -0.7rem;
          width: 1.8rem;
          height: 1.8rem;
          align-items: center;
          justify-content: center;
          span {
            position: relative;
            top: 0.1rem;
          }
        }
      }

      .icon {
        position: relative;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: auto;
          height: 2.5rem;
        }

        .active {
          display: none;
        }
      }

      .title {
        padding-top: 0.5rem;
      }

      &.active {
        color: #000000;
        text-decoration: none;
        .not_active {
          display: none;
        }
        .active {
          display: block;
        }

        .icon {
          // svg {
          //   border: 0.1rem solid #000000;
          // }
          svg {
            path {
              fill: #000000;
            }
            circle {
              fill: #000000;
            }
            &.compas {
              path {
                fill: #ffffff;
              }
            }
          }
        }
      }
    }
  }
}

.footer {
  background: #f9fafc;
  padding: 3rem 3rem 3rem 3.5rem;
  font-family: 'Circe';

  .first_row {
    display: flex;
    justify-content: space-between;

    .social_links {
      align-items: center;
      display: flex;
      padding-top: 0.5rem;

      .link_item {
        margin-left: 2.5rem;
        height: fit-content;
        svg {
          max-width: 2.8rem;
          height: auto;
        }
      }
    }
  }
  .second_row {
    text-align: center;
    margin-top: 4.6rem;

    a {
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 2.2rem;
      color: #1e1e1e;
      text-decoration: none;
      padding: 0 1.2rem;
      font-family: 'Circe';
    }
  }
  .third_row {
    font-size: 1.2rem;
    line-height: 2.2rem;
    text-align: center;
    color: #747474;
    margin-top: 4rem;
    font-family: 'Circe';
  }
}

.foot_info {
  text-align: center;
  font-size: 1.3rem;
  line-height: 1.5rem;
  width: 25rem;
  color: #cccccc;
  margin: 0 auto;
  margin-top: 3rem;
  img {
    margin-top: 1.5rem;
  }
}
