.red_title {
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 2.1rem;
  color: #ff3e6c;
  padding: 0 2rem;
}
.category_container {
  margin-top: 3rem;
  padding: 0 2rem;
}
.search_input {
  flex-direction: row;
  align-items: center;
  display: flex;
  border-radius: 5rem;
  border: 0.1rem solid #eaeaea;
  margin: 0 2.5rem;
  margin-top: 2.5rem;
  padding: 1rem 1rem 1rem 2rem;
}
.search_txt {
  font-size: 1.6rem;
  line-height: 2.6rem;
  color: #d4d4d4;
  margin-left: 1.5rem;
}
.last_icon {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.empty_ideas {
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 2.1rem;
  color: #eaeaea;
  margin-top: 3rem;
  text-align: center;
  padding: 0 2rem;
}
