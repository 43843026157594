.gesture_container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
}
.left,
.right {
  width: 50%;
  height: 100%;
}
.close_button {
  position: absolute;
  z-index: 1;
  top: 4rem;
  right: 1rem;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3 ease;
  opacity: 1;
  &.hidden {
    opacity: 0;
  }
}
