.search_container {
  padding-top: 2rem;
}
.row {
  flex-direction: row;
  display: flex;
  margin: 0 2rem;
  justify-content: space-between;
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-bottom-width: 0.1rem;
  border-bottom-color: #f0f0f0;
  border-bottom-style: solid;
  > div {
    width: 50%;
  }
}
.empty_container {
  position: 'relative';
  padding-top: 7rem;
}
.empty_txt {
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #4f5665;
  text-align: center;
  margin-top: 3rem;
}
