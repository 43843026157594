.item {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #eaeaea;
  padding: 0;
  color: #212529;

  &:first-child {
    border-bottom: none;
  }
}
