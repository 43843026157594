.btn_container {
}
.btn {
  width: 14rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  line-height: 2.4rem;
  border-radius: 2.5rem;
  border: none;
  font-family: 'Circe';
  img {
    width: 2.4rem;
  }

  &.with_icon {
    img {
      margin-right: 1rem;
    }
  }

  &.red {
    background-color: #ff3e6c;
    box-shadow: 0px 0.4rem 1rem rgba(255, 0, 76, 0.42);
    color: #ffffff;
  }
  &.white {
    background: #ffffff;
    box-shadow: 0 0.4rem 1rem rgba(200, 200, 200, 0.42);
    border-radius: 3rem;
    color: #000;
  }
  &.withoutShadow {
    box-shadow: none;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.8;
  }
}
