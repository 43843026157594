.tabs {
  display: flex;
  margin-bottom: 2.2rem;

  &.viewMobile {
    // background: #f9f9f9;
    // border-radius: 2rem;
    // padding: 0.4rem 1rem;
    // justify-content: space-between;
  }
}
