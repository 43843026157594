.profile {
  padding: 0rem 3rem 2.3rem;
  background-color: #f7f7f7;

  &.without_counts_pad {
    padding-bottom: 0;
  }

  &.pad {
    padding-top: 8rem;
  }
  .container {
    display: flex;
    margin-bottom: 2.8rem;
    &.without_counts_mrg {
      margin-bottom: 1.5rem;
    }
    .left {
      position: relative;
      width: calc(100% - 9rem);
      .name {
        font-weight: bold;
        font-size: 2.4rem;
        line-height: 120%;
        color: #000000;
        margin-bottom: 1rem;
      }
      .bio {
        font-size: 1.7rem;
        line-height: 130%;
        color: #000000;

        min-height: 2.1rem;
        // max-width: 22rem;
        // min-width: 22rem;
        width: fit-content;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;

        &.open {
          -webkit-line-clamp: 20;
        }
      }
      .toggle {
        font-size: 1.7rem;
        line-height: 130%;
        color: #ff3e6c;
      }
    }
    .right {
      margin-left: auto;
      flex-shrink: 0;
      .avatar {
        width: 100%;
        max-width: 7.6rem;
        max-height: 7.6rem;
        height: 100%;
        img {
          width: 7.6rem;
          height: 7.6rem;
          object-fit: cover;
          border-radius: 50%;
        }
      }
    }
  }
  .counts {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    .friends {
      display: flex;
      width: 50%;
      align-items: center;
      .images {
        position: relative;
        width: 5rem;
        .photo {
          position: absolute;
          border-radius: 50%;
          background-color: #ffffff;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 2.6rem;
          height: 2.6rem;
          object-fit: cover;
          &.photo1 {
            transform: translate(-100%, -50%);
          }
          &.photo2 {
            transform: translate(-30%, -50%);
          }
        }
      }
      .count {
        margin-left: 0.9rem;
        font-size: 1.5rem;
        color: #000000;
      }
    }
    .bday {
      display: flex;
      margin-right: 3rem;
      align-items: center;
      .img_block {
        width: 2.4rem;
        height: 2.4rem;

        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: cover;
        }
      }
      .date {
        margin-left: 0.9rem;
        font-size: 1.5rem;
        color: #000000;
      }
    }
  }
}

.supply_btn {
  padding-bottom: 1rem;
  position: relative;
  z-index: 1;
  button {
    font-size: 1.5rem;
    line-height: 2.2rem;
    font-weight: bold;
    font-family: 'Circe';
    border-radius: 0.7rem;
    width: 100%;
    height: 5.2rem;
    box-sizing: border-box;
    svg {
      width: 2.4rem;
      height: 2.4rem;
    }
    div {
      margin-left: 1rem;
      padding-top: 0.2rem;
    }
  }
}
