.friendlist_title {
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.1rem;
  color: #ff3e6c;
  padding-left: 1.5rem;
  margin-top: 2.2rem;
}
.request_friend_link {
  display: flex;
  margin: 0 2rem;
  padding: 3rem 1.5rem;
  border-bottom: 1px solid #eaeaea;
  -webkit-box-pack: justify;
  justify-content: space-between;
  .left {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.8rem;
    color: #2d2d2d;
  }
  .right {
    display: flex;
  }
  .notification {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ff3e6c;
    width: 2.2rem;
    height: 2.2rem;
    box-shadow: 0px 4px 10px rgba(255, 0, 76, 0.2);
    color: #ffffff;
    margin-right: 1.4rem;
    border-radius: 50%;
  }
}

.empty_title {
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #a0a0a0;
  text-align: center;
  margin-top: 10rem;
}

.empty_search_title {
  padding: 6.5rem;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #4f4f4f;
}

.new_user_idea_container {
  font-family: 'Circe';
  padding: 3rem;
  background: #f8f9fb;
  border-radius: 10px;
  .new_user_idea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      .img_block {
        width: 4rem;
        height: 4rem;
        img {
          max-width: 100%;
          max-height: 100%;
          border-radius: 50%;
        }
      }
      .phone_block {
        margin-left: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .phone {
          font-style: normal;
          font-weight: normal;
          font-size: 1.6rem;
          line-height: 1.9rem;
          color: #2d2d2d;
        }
        .add_user {
          font-style: normal;
          font-weight: normal;
          font-size: 1.3rem;
          line-height: 1.5rem;
          color: #747474;
        }
      }
    }
    .right {
      a {
        background: #ff3e6c;
        box-shadow: 0px 4px 10px rgba(255, 0, 76, 0.2);
        border-radius: 30px;
        padding: 0.5rem;
        font-style: normal;
        font-weight: normal;
        font-size: 1.4rem;
        line-height: 1.6rem;
        color: #ffffff;
      }
    }
  }
}

.empty {
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: #919191;
  margin-top: 3rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.empty_list {
  margin-top: 3rem;
  align-items: center;
  justify-content: center;
}
.empty_title {
  font-weight: bold;
  font-size: 1.7rem;
  line-height: 2.2rem;
  color: #000;
  text-align: center;
}
.empty_subtitle {
  font-size: 1.5rem;
  line-height: 1.9rem;
  color: #2d2d2d;
  text-align: center;
  margin-top: 1.5rem;
}
.empty_red {
  font-size: 1.5rem;
  line-height: 1.9rem;
  color: #ff3e6c;
  text-align: center;
  margin-top: 0.5rem;
}
.empty_container {
  padding: 0 3rem;
  margin-top: 2rem;
}
.empty_btn {
  margin-top: 2rem;
  width: 100%;
  padding: 0;
  height: 4.2rem;
}
.calendar {
  margin-right: 0.9rem;
}
