.header_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.5rem 2rem 0 2rem;
  position: relative;

  .left {
    margin-left: 0.6rem;
    .img_block {
      width: 1.2rem;
      height: 2.1rem;
      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }
  }
  .center {
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    font-size: 1.8rem;
    line-height: 2.1rem;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    min-height: 2.1rem;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0.5rem 0;
    max-width: 70%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .right {
  }
}
.inner_content {
  padding: 0 2rem;

  .headline {
    font-size: 2.4rem;
    line-height: 2.8rem;
    color: #000000;
    font-weight: 500;
  }
  .back_button {
    display: flex;
    align-items: center;
    padding: 1rem 2.5rem 1rem 2.5rem;
    margin-bottom: 2rem;
    img {
      padding-right: 1rem;
    }
    div {
      font-weight: bold;
      font-size: 1.6rem;
      line-height: 1.6rem;
      color: #1a1a1a;
    }
  }

  .filter_content {
    padding: 3rem 0;

    .title {
      font-weight: 500;
      color: #b1b1b1;
      font-size: 1.6rem;
    }

    .filter_items {
      margin-top: 2rem;

      .item {
        padding: 2rem 2rem;
        border-bottom: 1px solid #eaeaea;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1.4rem;
        margin-bottom: 1.85rem;

        &:first-child {
          border-top: none;
        }
        .value {
          display: flex;
          align-items: center;
          font-weight: 500;
          svg {
            margin-left: 1rem;
            transform: rotate(-90deg);
          }
        }
      }
    }
    .price_slider {
      padding: 0 2rem;
      margin-top: 1.85rem;
      .title {
        font-style: normal;
        font-weight: normal;
        font-size: 1.5rem;
        line-height: 100%;
        color: #000000;
        margin-bottom: 1.75rem;
      }
      .inputs {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.5rem;

        .input_block {
          display: flex;
          align-items: center;
          background: #f0f0f0;
          border-radius: 5px;
          padding: 0.8rem 1rem;
          width: 13.5rem;
          box-sizing: border-box;

          .text {
            font-style: normal;
            font-weight: normal;
            font-size: 1.5rem;
            line-height: 100%;
            color: #747477;
          }
          .input {
            font-style: normal;
            font-weight: normal;
            font-size: 1.5rem;
            line-height: 100%;
            color: #000000;
            margin-left: 1.5rem;
            background: transparent;
            outline: none;
            border: none;
            // height: 1.3rem;
            width: 100%;
          }
        }
      }
    }
  }

  .filter_variables_content {
    margin-top: 2rem;
    .item {
      padding: 1.5rem 1.2rem;
      color: #1a1a1a;
      font-size: 1.6rem;
      border-radius: 1.5rem;

      &.active {
        background: url('/images/icons/red_accept.svg') #f9f9f9;
        background-size: 1.8rem auto;
        background-repeat: no-repeat, no-repeat;
        background-position: calc(100% - 3rem) center;
      }
    }
  }

  .accept_button {
    background: #ff3e6c;
    box-shadow: 0px 4px 10px rgba(255, 0, 76, 0.42);
    border-radius: 30px;
    border: none;
    outline: none;
    padding: 0;
    width: 26rem;
    font-weight: bold;
    font-size: 1.8rem;
    color: #ffffff;
    height: 5rem;
    position: absolute;
    bottom: 8rem;
    left: 50%;
    transform: translateX(-50%);
  }
}
.rc_filter {
  > div:first-child {
    height: 0.5rem;
    width: calc(100% - 1rem);
    left: 0.5rem;
  }
  > div:nth-child(2),
  > div:nth-child(3) {
    width: 2.4rem;
    height: 2.4rem;
    margin-top: -1.2rem;
  }
}
