.container {
  * {
    box-sizing: border-box;
  }

  flex: 1;
  padding-left: 5rem;

  &.viewMobile {
    padding: 0 2.2rem;
  }
}

.breadcrumbs {
  display: flex;
  align-items: center;
  font-family: 'Circe'; // TODO: add fallback fonts
  margin-bottom: 1.2rem;

  .crumb {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: #000000;
    margin-right: 3.1rem;
    border-bottom: 1px solid #000000;
    position: relative;

    &:after {
      content: '';
      background: url('/images/icons/categ-map-arrow.svg');
      width: 1.1rem;
      height: 0.9rem;
      display: inline-block;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto 1rem;
    }
  }
}

.title {
  font-size: 26px;
  line-height: 38px;
  font-weight: bold;
  margin-bottom: 1rem;

  .viewMobile & {
    font-size: 18px;
    line-height: 21px;
  }
}

.content {
  margin-bottom: 1.8rem;
}

.block {
  padding: 1.8rem 0;
  border-bottom: 1px solid #eaeaea;

  &.block_details {
    display: flex;
    justify-content: space-between;
    padding-top: 0;
  }

  &.block_address {
    .blockContent {
      position: relative;
      padding-left: 2.8rem;

      &:before {
        content: '';
        background: url('/images/icons/location-icon.svg') no-repeat;
        background-size: contain;
        position: absolute;
        top: 0;
        left: 0;
        width: 1.8rem;
        height: 1.8rem;
      }
    }
  }

  &.block_recipient {
    .blockContent {
      position: relative;
      padding-left: 2.8rem;

      &:before {
        content: '';
        background: url('/images/icons/recipient-icon.svg') no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        width: 1.4rem;
        height: 1.4rem;
      }

      input {
        border: none;
        font-size: 1.4rem;
        line-height: 1.8rem;
        color: #1a1a1a;
      }

      > div {
        padding-bottom: 1rem;
      }
    }
  }

  &.block_payment {
    .blockContent {
      position: relative;
      padding-left: 2.8rem;

      &:before {
        content: '';
        background: url('/images/icons/black-rubble.svg') no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        width: 1.6rem;
        height: 1.6rem;
      }

      > div {
        padding-bottom: 1rem;
      }
    }
  }

  &.block_last,
  &.block_payment {
    border-bottom: 0;
    padding-bottom: 0;
  }

  .blockTitle {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 1rem;
  }

  .blockContent {
    font-size: 14px;
    line-height: 16px;
  }
}

.orderPhoto {
  width: 8rem;
  height: 8rem;
  justify-content: flex-end;
  display: flex;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}

.workTime {
  margin-top: 1.5rem;
  font-size: 1.4rem;
  line-height: 1.6rem;
  padding-left: 2.8rem;
  color: #1a1a1a;
}

.orderDetails {
  min-width: 70%;

  .orderDate {
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;

    .viewMobile & {
      font-size: 14px;
      line-height: 16px;
      font-weight: normal;
    }
  }

  .orderStatus {
    border-radius: 50px;
    font-size: 1.3rem;
    line-height: 1.5rem;
    color: #ffffff;
    width: fit-content;
    padding: 0.3rem 1rem;
    margin: 0.6rem 0 1rem;
    background-color: #65718a;

    &.purple {
      background-color: #b01cf5;
    }

    &.blue {
      background-color: #00b5f1;
    }

    &.red {
      background-color: #f51c1c;
    }

    &.pink {
      background-color: #ff3e6c;
    }
  }

  .orderDeliveryDate {
    font-size: 14px;
    line-height: 16px;
    position: relative;
    padding-left: 2.8rem;

    &:before {
      content: '';
      background: url('/images/icons/calendar.svg') no-repeat;
      position: absolute;
      top: 0;
      left: 0;
      width: 1.6rem;
      height: 1.6rem;
    }
  }

  .orderPhoto {
    width: 4rem;
    height: 4rem;
    text-align: right;
    flex-shrink: 0;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      border-radius: 4px;
    }
  }
}

.questionButton {
  .viewMobile & {
    display: flex;
    justify-content: center;
    margin-bottom: 1.2rem;
    height: 4.2rem;
  }

  button {
    background: #ff3e6c;
    box-shadow: 0px 4px 10px rgba(255, 0, 76, 0.42);
    border-radius: 30px;
    border: none;
    font-family: 'Circe';
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2.4rem;
    display: flex;
    align-items: center;
    color: #ffffff;
    padding: 0.9rem 3rem;
    box-sizing: border-box;
    width: auto;

    .viewMobile & {
      background: #ffffff;
      box-shadow: 0px 4px 20px #eaeaea;
      color: #000000;
    }

    img {
      padding-right: 1rem;
    }
  }
}
