// @import '../AboutPage/AboutPage.module.scss';

.headline {
  font-weight: bold;
  font-size: 3.2rem;
  line-height: 4.7re;
  position: relative;

  .viewMobile & {
    font-size: 1.8rem;
    line-height: 2.1rem;
    color: #ff004c;
  }
}

.subline {
  margin-top: 1rem;
  font-size: 1.4rem;
  line-height: 160%;
}

.ask-block {
  margin-top: 9rem;
  display: flex;
  align-items: center;
  padding: 2.5rem 4rem;
  justify-content: space-between;
  background: #f9fafc;
  border-radius: 10px;
  width: 100%;
  position: relative;
  left: -4rem;

  .btn-block {
    margin-top: 0 !important;
  }

  .headline {
    margin-top: 0;
    font-size: 3.2rem;
    line-height: 4.7rem;
    color: #000000;
    font-weight: bold;
  }
}
.ask-block .subline {
  font-size: 1.4rem;
  line-height: 160%;
  color: #000000;
  margin-top: 1rem;
}
