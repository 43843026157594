html {
  font-size: 62.5%;
}
html,
body {
  font-family: 'Circe' !important;
  position: relative;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}
a {
  text-decoration: none;
}

#root > div {
  height: 100%;
}

@media (max-width: 1400px) {
  html,
  body {
    font-size: 53.5%;
  }
}
@media (max-width: 1200px) {
  html,
  body {
    font-size: 43.5%;
  }
}

@media (max-width: 768px) {
  html,
  body {
    font-size: 62.5%;
  }
}

#page_content {
  display: flex;
  flex-direction: column;
}

.ReactModalPortal {
  z-index: 6000;
  position: relative;
}

.ReactModalPortal .popup-content {
  padding: 0;
  border: none;
}

.ReactModalPortal .popup-content.full-open-content {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 93rem;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Body--open {
  overflow: hidden !important;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

* {
  box-sizing: initial;
  outline: none;
}
button:focus {
  outline: none;
}
button {
  cursor: pointer;
}

.circle_progress_mini {
  width: 2.1rem !important;
  height: 2.1rem;
}

.circle_progress_menu {
  width: 6.5rem !important;
  height: 6.5rem;

  svg {
    width: 6.5rem !important;
    height: 6.5rem;
  }
}

.circle_progress_mini svg {
  width: 2.1rem !important;
  height: 2.1rem;
}
.page {
  background-color: #ffffff !important;
}
.rai-spinner-inner {
  /* border-top: 0.225em solid !important; */
}
.top-categories-pagination {
  position: absolute !important;
  margin: 0 !important;
  left: 49.3% !important;
  transform: translateX(-50%) !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  bottom: -1.5rem !important;
}
.swiper-pagination-bullet {
  background: #f0f0f0 !important;
  opacity: 1 !important;
}
.swiper-pagination-bullet-active {
  background: #ff3e6c !important;
}
.list ul {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.list li {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}
.list .item-content {
  padding-left: 0;
  min-height: auto;
}
.item-inner {
  padding: 0 !important;
}
.item-inner > div[slot='inner-start'] {
  width: 100%;
}
.item-inner:after {
  display: none;
}
.swipeout {
  border-radius: 2rem;
  // box-shadow: 0 0.4rem 3rem #f0f0f0;
  box-shadow: none;
  background: #ffffff;
  margin-top: 0.5rem;
  margin-bottom: 1.2rem;
}
.draggable-source--is-dragging {
  visibility: hidden !important;
}
.draggable-source--is-dragging svg {
  display: none !important;
}
.draggable--original {
  visibility: hidden !important;
  display: none !important;
}
.draggable--original svg {
  visibility: hidden !important;
}
.draggable-mirror {
  box-shadow: 0px 4px 40px rgba(179, 179, 179, 0.514) !important;
  z-index: 999999;
  margin-top: 0 !important;
  border-radius: 2rem !important;
}
.draggable-mirror > div {
  border-radius: 2rem !important;
  margin-top: 0 !important;
}

.Toastify__toast-container--top-center {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 999999;
}
.Toastify__toast {
  padding: 0;
  box-shadow: none;
  background-color: rgba(2, 2, 2, 0.79);
  border-radius: 5rem;
  height: auto;
  min-height: auto;
  width: fit-content;
  .Toastify__toast-body {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    padding: 1rem 3rem 1rem 3rem;
    > div {
      font-weight: 500;
      font-size: 1.4rem;
      color: #ffffff;
      line-height: 1.6rem;
      margin: 0;
      text-align: center;
      // white-space: nowrap;
    }
  }
}

.swiper-container-horizontal > .swiper-pagination-bullets {
  margin-left: 0;
}
