.container {
  padding-top: 0.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 0.1rem solid #eaeaea;
  margin: 1.5rem 2rem;
}
.txt {
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #4f5665;
}
.everywhere {
  flex-direction: row;
  display: flex;
  align-items: center;
}
.red_txt {
  color: #ff3e6c;
  font-size: 1.4rem;
  line-height: 2.2rem;
  padding-left: 0.5rem;
}
