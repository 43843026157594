.item {
  display: flex;
  flex-direction: row;
  margin: 0 2rem;
  padding: 0;
  border-bottom: 0.1rem solid #eaeaea;
  align-items: center;
  position: relative;

  &.bday {
    border: none;

    .user_touchable {
      padding-top: 2rem;
    }
  }
}
.full_border {
  padding: 0 2rem;
  margin: 0;
}
.bottom_padding {
  padding-bottom: 4.5rem;
}
.user_touchable {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3rem 0;
  flex: 1;
  flex-grow: 1;
}
.user_img_container {
  width: 4.5rem;
  height: 4.5rem;
}
.user_img {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 5rem;
  object-fit: cover;
}
.center {
  margin-left: 1rem;
  padding-right: 1rem;
  flex: 1;
}
.verify_icon_container {
  position: relative;
  top: -0.2rem;
  padding-left: 0.7rem;
  img {
    width: 2rem;
    height: 2rem;
  }
}
.name_container {
  flex-direction: row;
  display: flex;
}
.name {
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: #2d2d2d;
  // padding-Top: 4;
  // flex: 1;
}
.teg {
  font-size: 1.3rem;
  line-height: 1.5rem;
  color: #adadad;
  padding-top: 0.4rem;
}
.right {
  flex-shrink: 0;
  margin-left: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 3;
}
.birthdate {
  font-size: 1.3rem;
  line-height: 1.5rem;
  color: #919191;
  margin-left: auto;
}
.btn_container {
  margin-left: auto;
  width: 13rem;
}
.menu_container {
  position: relative;
  margin-left: auto;
  z-index: 2;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.inner_container {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 1.8rem;
  }
}
.red_txt {
  font-size: 1.5rem;
  line-height: 2.2rem;
  color: #ff3e6c;
}
.bottom_container {
  position: absolute;
  flex-direction: row;
  display: flex;
  bottom: 2.5rem;
  left: 3rem;
  justify-content: space-between;
  width: calc(100% - 10rem);
}
.btn {
  //   width: (Dimensions.get('window') .width - 100) / 2 - 15;
  height: 3.8rem;
}
.btn_txt {
  font-size: 1.5rem;
}
.bottom_action {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  .user_touchable {
    padding-bottom: 1rem;
  }
  .right {
    margin-left: 0;
    padding-bottom: 3rem;
    width: 100%;
    .btn_container {
      margin-left: 0;
      width: 100%;
      > div > div {
        width: 49%;
      }
    }
  }
}

.bday_border {
  padding-bottom: 1.2rem;
  border-bottom: 0.1rem solid #eaeaea;
}
.donate_btn {
  height: 5.2rem;
  background: #ff3e6c;
  box-shadow: 0px 4px 10px rgba(255, 0, 76, 0.42);
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  width: calc(100% - 6.2rem);
  margin: 0 auto;
  margin-top: -2rem;
  font-family: 'Circe';

  .img_donate {
    margin-right: 1rem;
    width: 2.2rem;
    height: 2.2rem;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }
  .text_donate {
    font-style: normal;
    font-weight: 700;
    font-size: 1.7rem;
    line-height: 2.5rem;
    color: #ffffff;
  }
}
