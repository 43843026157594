.payment_iframe {
  height: 85vh;
  width: 100%;
}
.wait_txt {
  font-size: 2rem;
  line-height: 2.4rem;
  text-align: center;
  margin-top: 2rem;
  &.dark {
    color: #ffffff;
  }
}
.container {
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}
