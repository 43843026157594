.container {
  overflow: hidden;
  .inner_content {
    padding: 13.4rem 6.4rem 13rem;
    max-width: 144rem;
    // width: 100%;
    margin: 0 auto;
    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 4.8rem;
      line-height: 5.7rem;
      text-align: center;
      color: #000000;
      margin-bottom: 7rem;
    }
    .wish_stat {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-bottom: 13rem;

      .item {
        .img_block {
          width: 5rem;
          height: 5rem;
          margin-bottom: 4rem;
          img {
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
          }
        }
        .count {
          font-style: normal;
          font-weight: bold;
          font-size: 4.8rem;
          line-height: 100%;
          color: #1a1a1a;
          margin-bottom: 1.5rem;
        }
        .subtitle {
          font-style: normal;
          font-weight: normal;
          font-size: 2.1rem;
          line-height: 130%;
          color: #747474;
        }
      }
    }
    .wish_completed {
      background: #f5f5f7;
      border-radius: 2rem;

      .inner_content_wish {
        padding: 10rem;
        .title_wish {
          font-style: normal;
          font-weight: 600;
          font-size: 4.8rem;
          line-height: 130%;
          color: #1d1d1f;
          margin-bottom: 5rem;
        }
        .review_block {
          display: flex;
          justify-content: space-between;

          .item {
            flex-basis: 30%;
            .img_block {
              text-align: center;

              img {
                max-width: 100%;
                max-height: 100%;
                object-fit: cover;
                &.scale {
                  transform: scale(1.1);
                  transition: 0.3s;
                }
              }
            }
            .text {
              font-style: normal;
              font-weight: 500;
              font-size: 2.1rem;
              line-height: 130%;
              color: #1d1d1f;
              margin: 1rem 2.2rem 0 3.1rem;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .inner_content {
      padding: 5rem 0rem 0rem;
      .title {
        font-size: 3.2rem;
        line-height: 130%;
        text-align: left;
        margin-bottom: 3rem;
        padding: 0 3rem;
      }
      .wish_completed {
        background: #f5f5f7;
        border-radius: 0;
        .inner_content_wish {
          padding: 3rem 1.5rem;
          .title_wish {
            font-size: 3.2rem;
            line-height: 100%;
            margin-bottom: 2.5rem;
          }
          .review_block {
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            .item {
              justify-content: center;
              align-items: center;
              display: flex;
              flex-direction: column;
              flex-basis: 100%;
              margin-bottom: 3rem;
              .img_block {
                img {
                  max-width: 100%;
                  max-height: 100%;
                  object-fit: cover;
                }
              }
              .text {
                font-style: normal;
                font-weight: 500;
                font-size: 1.4rem;
                line-height: 130%;
                color: #1d1d1f;
                margin: 1rem 4.2rem 0 5.2rem;
              }
            }
          }
        }
      }
    }
  }
}

.wish_stat_mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 3rem;
  margin-bottom: 2.8rem;

  .card {
    background: #f8f9fb;
    border-radius: 10px;
    margin-bottom: 2rem;
    width: 100%;
    .inner {
      padding: 3.1rem 0;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .img_container {
        width: 5rem;
        height: 5rem;
        margin-bottom: 1.5rem;
        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: cover;
        }
      }
      .counter {
        font-style: normal;
        font-weight: normal;
        font-size: 3.2rem;
        line-height: 100%;
        text-align: center;
        color: #1a1a1a;
        margin-bottom: 0.5rem;
      }
      .subline {
        font-style: normal;
        font-weight: normal;
        font-size: 1.4rem;
        line-height: 100%;
        text-align: center;
        color: #747474;
      }
    }
  }
}

.modal_window_desk {
  outline: none;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);

  &.full_open {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
  }
}

.modal_content {
  width: 100%;
  height: 100%;
  border-radius: 0;
  overflow: hidden;

  .modal_inner_content {
    position: relative;
    display: flex;
    justify-content: center;
    top: 9rem;

    .logo {
      position: absolute;
      cursor: pointer;
      left: 5rem;
      top: -5rem;
    }

    .modal_close_button {
      position: absolute;
      cursor: pointer;
      right: 5rem;
      top: -5rem;
    }

    .modal_container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-family: 'Circe';
      position: relative;

      .video {
        width: 46rem;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .modal_window_desk {
    &.full_open {
      height: unset;
      max-height: unset;
    }
  }
  .modal_content {
    .modal_inner_content {
      top: 0rem;

      .logo {
        position: absolute;
        cursor: pointer;
        left: 2rem;
        top: 2rem;
        z-index: 2;
        width: 9rem;

        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: cover;
        }
      }

      .modal_close_button {
        position: absolute;
        cursor: pointer;
        right: 2rem;
        top: 2rem;
        z-index: 2;
      }

      .modal_container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-family: 'Circe';
        position: relative;
        z-index: 1;

        .video {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
