.container {
  cursor: pointer;
  svg {
    line {
      transition: all 0.3s ease;
    }
  }
  &:hover {
    svg {
      line {
        stroke: #ff3e6c;
      }
    }
  }
}
