.modal_window_desk {
  background: #ffffff;
  border-radius: 10px;
  padding: 4rem 5rem;
  width: calc(100vw - 10rem);
  max-width: 73rem;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  position: absolute;
  max-height: calc(100% - 10rem);
  overflow: auto;

  .close_btn {
    position: absolute;
    cursor: pointer;
    right: 3rem;
    top: 3rem;
  }
}
.content {
  width: 43rem;
  margin: 0 auto;
  margin-top: 5rem;
  .title {
    font-weight: bold;
    font-size: 3.2rem;
    line-height: 4.7rem;
    color: #0a0a0a;
    text-align: center;
  }
  .form {
    margin-top: 1.5rem;
  }
  .btn_container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.msg {
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #ff004c;
  padding: 0 2rem;
  margin-bottom: 2rem;
}

@media screen and (max-width: 768px) {
  .modal_window_desk {
    padding: 0 2rem;
    height: 100%;
    max-height: 100%;
    width: calc(100% - 4rem);
  }
  .content {
    width: 100%;
    padding-bottom: 3rem;
  }
}
