.user_profile_block {
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 4px 40px #f0f0f0;
  padding: 2rem 2.5rem 2rem 3rem;
  margin: 0 2rem;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;

  .user_img {
    width: 4.4rem;
    height: 4.4rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      box-shadow: 0px 1px 16px rgba(148, 158, 183, 0.5);
      border-radius: 50%;
    }
  }

  .user_info {
    padding-left: 1rem;

    .user_name {
      font-weight: 500;
      font-size: 1.6rem;
      color: #000000;
    }

    .user_teg {
      font-size: 1.3rem;
      color: #747474;
      margin-top: 0.5rem;
    }
  }

  .logout {
    margin-left: auto;
  }
}

.profile_title {
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.1rem;
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
  color: #ff3e6c;
}

.profile_main {
  display: block;
}
.profile_main .profile_title {
  padding-left: 4rem;
}

.nav {
  padding: 0 3.8rem;
  .nav_item {
    display: flex;
    align-items: center;
    margin-bottom: 2.7rem;
    .img_block {
      width: 2.5rem;
      height: 2.5rem;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .text {
      margin-left: 1.355rem;
      font-weight: bold;
      font-size: 1.7rem;
      line-height: 2.5rem;
      color: #000000;
    }
    .icon {
      margin-left: auto;
      width: 1.8rem;
      height: 1.8rem;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .count {
      width: 2.2rem;
      height: 2.2rem;
      margin-left: auto;
      background-color: #ff3e6c;
      border-radius: 50%;
      position: relative;
      span {
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: bold;
        font-size: 1.4rem;
        line-height: 2.1rem;
        color: #ffffff;
      }
    }
  }
}

.profile_main {
  padding: 1.45rem 2.4rem 2.2rem;
  .profile_item {
    display: block;
    margin-bottom: 1.4rem;
    &:last-child {
      .item_wrapper {
        background: #8a14ff;
        .info {
          .text {
            color: #b2a7ff;
          }
        }
      }
    }
    .item_wrapper {
      padding: 2rem 1.5rem 1.3rem 2rem;
      background: #ff3e6c;
      border-radius: 10px;
      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.9rem;
        .name {
          display: flex;
          align-items: center;
          .text {
            font-weight: bold;
            font-size: 1.9rem;
            line-height: 130%;
            color: #ffffff;
          }
          .img_block {
            width: 2.6rem;
            height: 2.6rem;
            margin-left: 1rem;
            img {
              max-width: 100%;
              max-height: 100%;
              object-fit: cover;
            }
          }
        }
        .sum {
          margin-left: auto;
          font-weight: bold;
          font-size: 2.4rem;
          line-height: 130%;
          color: #ffffff;
        }
      }
      .info {
        display: flex;
        .text {
          font-size: 1.5rem;
          line-height: 130%;
          color: #ffa0bd;
        }
        .icon {
          width: 0.7rem;
          height: 1.3rem;
          margin-left: auto;
          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
    }
  }
}

.profile_info {
  background: #f8f9fb;
  padding-bottom: 2.8rem;
  .info_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.7rem 2.6rem 2.7rem 3.4rem;
    .left {
      .title {
        font-weight: bold;
        font-size: 1.7rem;
        line-height: 2.5rem;
        color: #000000;
        margin-bottom: 0.6rem;
      }
      .subtitle {
        font-size: 1.5rem;
        line-height: 130%;
        color: #747474;
      }
    }
    .right {
      width: 6.5rem;
      height: 6.5rem;
      margin-left: 4.2rem;
      position: relative;

      .progress_count {
        position: absolute;
        top: 50%;
        left: 53%;
        transform: translate(-50%, -50%);
        font-weight: bold;
        font-size: 1.7rem;
        line-height: 2.5rem;
        color: #000000;
      }
    }
  }
  .profile_swiper {
    padding: 0 1.6rem;

    .swiper_slide {
      width: 19.2rem;

      .slide_content {
        width: 16.9rem;
        height: 16.2rem;
        padding: 1.5rem;
        border-radius: 10px;
        position: relative;
        background: #ffffff;

        &.disabled {
          .title {
            color: #d2d2d2;
          }
          .subtitle {
            color: #c9c9c9;
          }
          .button {
            background: #e6e6e6;
          }
        }

        .top_icon {
          display: flex;
          margin-bottom: 1rem;
          .icon {
            svg {
              path {
              }
            }
          }
          .success {
            margin-left: auto;
            display: flex;
            align-items: center;
            .img_block {
              width: 1.6rem;
              height: 1.217rem;
              img {
                max-width: 100%;
                max-height: 100%;
              }
            }
            .text {
              font-size: 1.2rem;
              line-height: 130%;
              color: #c0c0c0;
              margin-left: 0.9rem;
            }
          }
        }
        .title {
          font-weight: bold;
          font-size: 1.7rem;
          line-height: 2.5rem;
          color: #000000;
          margin-bottom: 0.6rem;
        }
        .subtitle {
          font-size: 1.2rem;
          line-height: 130%;
          color: #747474;
          margin-bottom: 1.9rem;
        }
        .button {
          background: #ff3e6c;
          border-radius: 30px;
          height: 3.2rem;
          width: 14.2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;

          .text {
            font-size: 1.5rem;
            line-height: 2.2rem;
            color: #ffffff;
          }
        }
      }
    }
  }
}

.profile_about {
  padding: 2.4rem 4.2rem 1.7rem 3.6rem;

  a {
    color: #000000;
  }

  .about__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 2.9rem;
    font-size: 1.6rem;

    .item_name {
      font-size: 1.7rem;
      line-height: 2.5rem;

      &.logout {
        color: #ff3e6c;
      }
    }
  }
}
