.menu {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  font-family: 'Circe'; // TODO: add fallback fonts

  &.menu_mobile {
    margin-top: 0;
  }
}
