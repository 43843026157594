.container {
  padding: 0 2rem;
}
.items_container {
  margin-top: 1.5rem;

  .item {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    padding: 0 1rem;

    .icon {
      width: 2.8rem;
      height: 2.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 2rem;
      }
      &.share_icon {
        svg {
          width: 2.8rem;
          height: 2.8rem;
        }
      }
    }
    .item_name {
      font-size: 1.5rem;
      color: #212121;
      line-height: 1.8rem;
      margin-left: 1.5rem;
    }
  }
}
.search_container {
  margin-top: 1.5rem;
  position: relative;
  width: calc(100% + 4rem);
  left: -2rem;
}
