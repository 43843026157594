.wish_cart_preview {
  display: flex;
  background: #ffffff;
  box-shadow: 0px 4px 30px #f0f0f0;
  border-radius: 2rem;
  width: calc(100% - 4rem);
  padding: 1.5rem 2rem 1.5rem 2rem;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 1.2rem;
  position: relative;
  overflow: hidden;
  .text {
    margin-left: 2rem;
    overflow: hidden;
    padding: 0;
    padding-right: 1.6rem;
    .cart_name {
      color: #000000;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 1.4rem;
      line-height: 1.6rem;
    }
    .cart_status {
      font-weight: bold;
      font-size: 1.3rem;
      line-height: 1.5rem;
      color: #0e0e0e;
      padding-top: 0.5rem;

      &.gray {
        font-weight: normal;
        color: #747474;
      }
    }
  }
  .wish_img {
    width: 4rem;
    height: 4rem;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}


