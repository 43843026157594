.wish_item {
  margin-top: 0.5rem;
  margin-bottom: 3.6rem;

  &.idea_item {
    margin-bottom: 2rem;
  }
}

.idea_name {
  font-family: 'Circe';
  font-size: 1.2rem;
  color: #767676;
}

.wish_cart {
  display: flex;
  background: #ffffff;
  width: 100%;
  height: 7.8rem;
  padding: 0;
  align-items: center;
  position: relative;
  overflow: hidden;
  user-select: none;
  margin: 0;
  box-shadow: none;

  &.idea_cart {
    height: auto;
    padding: 1rem;
    width: auto;
  }

  .circlestat {
    width: 2.1rem;
    flex-shrink: 0;
    position: relative;

    img {
      width: 1.05rem;
      height: 1.05rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
    }
  }

  .text {
    margin-left: 1rem;
    overflow: hidden;
    padding-right: 2.6rem;
    width: 100%;
    margin-left: 2.6rem;

    .cart_name {
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: #000000;
      overflow: hidden;
      font-weight: 500;
      // white-space: nowrap;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-bottom: 0.8rem;

      img {
        vertical-align: initial;
        padding-right: 0.5rem;
      }
    }
    .cart_status {
      display: flex;
      align-items: center;

      .status_text {
        font-size: 1.3rem;
        line-height: 1.5rem;
        color: #747474;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-left: 0.8rem;
      }
    }
  }
  .wish_img {
    margin-left: auto;
    width: 7.8rem;
    height: 7.8rem;
    flex-direction: row;
    align-content: center;
    justify-content: flex-end;
    flex-shrink: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
.wishmenu__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // width: 50%;
  font-size: 1rem !important;
  line-height: 1.2rem !important;
  text-align: center;
  color: #ffffff;
  width: 8rem !important;
  padding: 0 !important;
  text-decoration: none;

  &:after {
    // display:none;
    background: #ffffff;
    margin-left: 0;
    margin-right: 0;
  }

  .icon {
    height: 2.1rem;
    display: flex;
    align-items: center;
  }
  .title {
    height: 2.5rem;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
  }

  &.del {
    background: #ff3e6c;
  }
  &.hide {
    background: #ae14ff;
  }
  &.edit {
    background: #004cbd;
  }
  &.more {
    background: #ffffff;
    color: #000000;
  }
}

.arrow {
  img {
    transform: rotate(180deg);
  }
}
.progress {
}
