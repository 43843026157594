.btn_container {
  margin-top: 1.5rem;
  padding: 0 2rem;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}
.without_margin {
  margin-top: 0;
  padding: 0;
}
.btn_content {
  // width: 48%;
}
.btn {
  // width: 48%;
  // min-width: 13rem;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
  height: 4.2rem;
  padding: 0 2rem;
}
.white_btn {
  border: 0.1rem solid #eaeaea;
  background-color: #fff;
}
.red_btn {
  background-color: #ff3e6c;
}
.btn_txt {
  font-size: 1.4rem;
}
.white_btn_txt {
  color: #727272;
}
.red_btn_txt {
  color: #fff;
  line-height: 2rem;
}
.icon_img {
  padding-right: 1rem;
  transform: translateY(0.2rem);
  img {
    width: 1.6rem;
  }
}
