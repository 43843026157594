.container {
  font-family: 'Circe';
  padding: 0 2rem;

  .item {
    display: flex;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #eaeaea;
    margin-top: 1.5rem;
    &:first-child {
      margin-top: 0;
    }
    .left {
      flex-shrink: 0;
      flex-basis: 35%;
      margin-right: 1.4rem;
      .procent {
        font-style: normal;
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 100%;
        color: #ff3e6c;
        margin-bottom: 0.3rem;
      }
      .title {
        font-style: normal;
        font-weight: 700;
        font-size: 1.7rem;
        line-height: 100%;
        color: #ff3e6c;
      }
    }
    .right {
      font-style: normal;
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 130%;
      color: #212121;
    }
  }
}
