.payment_section {
  padding: 0 1rem;
  overflow-x: hidden;
  width: calc(100% + 2rem);
  position: relative;
  left: -2rem;
  padding-bottom: 8rem;
  .payment_form {
    position: relative;
    margin-top: 2rem;
    .first_block {
      padding: 0 2rem 2rem 2rem;
      background: #ffffff;
      box-shadow: 0px 4px 40px #f0f0f0;
      border-radius: 1rem;
      position: relative;
      z-index: 3;
      &.dark {
        background: #363636;
        box-shadow: none;
        .item_name {
          color: #ffffff;
        }
        .input_item,
        input {
          background: #363636;
          border-color: #454545;
          color: #ffffff;
          box-shadow: none;
        }
      }
    }
    .second_block {
      width: 100%;
      height: 21.5rem;
      position: absolute;
      bottom: -7.7rem;
      right: -3rem;
      background: #f4f4f4;
      border-radius: 1rem;
      z-index: 1;
      &.dark {
        background: #454545;
        box-shadow: none;
        .item_name {
          color: #ffffff;
        }
        .input_item,
        input {
          background: #363636;
          border-color: #454545;
          color: #ffffff;
          box-shadow: none;
        }
      }

      .payment_item {
        width: fit-content;
        position: absolute;
        right: 5rem;
        z-index: 1;
        bottom: 1.5rem;
        display: flex;
        align-items: baseline;

        .item_name {
          padding-right: 15px;
        }
      }
    }
  }
  .payment_item {
    position: relative;
    .item_name {
      font-weight: bold;
      font-size: 1.4rem;
      line-height: 1.6rem;
      color: #1a1a1a;
      padding-left: 0.5rem;
      padding-top: 2.5rem;
    }
    .input_item {
      background: #ffffff;
      border: 1px solid #efefef;
      box-shadow: 0px 4px 40px #f0f0f0;
      border-radius: 1rem;
      padding: 1rem 2rem;
      justify-content: center;
      display: flex;
      align-items: center;
      text-align: center;
      margin-top: 1rem;
      input {
        color: #000000;
        font-size: 1.8rem;
        line-height: 2.1rem;
        border: none;
        width: 100%;
        text-align: center;
      }
      &.cvv {
        width: 3rem;
      }
      &.error {
        border: 1px solid #ff3e6c;
      }
    }
    .bankCard_img {
      position: absolute;
      left: 1.3rem;
      width: 3.7rem;
      height: 2.2rem;
    }

    &.flex {
      display: flex;
      .input_item {
        margin-right: 0.7rem;
      }
      .right {
        margin-left: auto;
        input {
          width: 3.2rem;
          text-align: center;
        }
      }
      .card_date {
        display: flex;
        .input_item {
          width: 3.2rem;
          padding: 1rem 0.8rem;
        }
      }
    }
  }
}

.CardHolderMessage {
  font-size: 1.4rem;
  text-align: center;
  margin-top: 2rem;
  color: #ff3e6c;
}

.bottom_container {
  margin-top: 10rem;
}

.red_btn_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12rem;

  .red_btn {
    width: 26rem;
    height: 5rem;
  }
}
