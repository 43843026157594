.container {
  background: #f5f5f7;
  .inner_content {
    padding: 9rem 25rem;
    max-width: 144rem;
    // width: 100%;
    margin: 0 auto;

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 3.6rem;
      line-height: 5.3rem;
      color: #000000;
      margin-bottom: 6.5rem;
    }
    .question_block {
      .question {
        margin-bottom: 3rem;
        background: #ffffff;
        border-radius: 10px;

        .wrapper {
          padding: 1.9rem 2.5rem 1.9rem 7.5rem;
          display: flex;
          align-items: center;
          cursor: pointer;

          .quest {
            font-style: normal;
            font-weight: bold;
            font-size: 2.1rem;
            line-height: 3.1rem;
            color: #000000;
            position: relative;

            &::before {
              content: '';
              position: absolute;
              margin: auto;
              top: 0;
              left: -5rem;
              bottom: 0;
              width: 2.5rem;
              height: 0.2rem;
              background-color: #000000;
              transition: all 0.2s;
            }

            &::after {
              content: '';
              margin: auto;
              content: '';
              position: absolute;
              top: 0;
              left: -3.7rem;
              bottom: 0;
              width: 2.5rem;
              height: 0.2rem;
              background-color: #000000;
              transition: all 0.2s;
              transform: translate(-50%, -50%) rotate(90deg) scale(1, 1);
            }

            &.active {
              &::after {
                transform: translate(-50%, -50%) rotate(90deg) scale(0, 1);
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    background: #f5f5f7;
    .inner_content {
      padding: 5rem 3rem;
      width: unset;

      .title {
        font-size: 3.2rem;
        line-height: 4.7rem;
        margin-bottom: 3rem;
      }
      .question_block {
        .question {
          margin-bottom: 1rem;
          background: #ffffff;
          border-radius: 10px;

          .wrapper {
            padding: 1.5rem 1.5rem 1.1rem 4.8rem;
            display: flex;
            align-items: center;

            .quest {
              font-style: normal;
              font-weight: bold;
              font-size: 1.4rem;
              line-height: 2.1rem;
              color: #000000;
              position: relative;

              &::before {
                content: '';
                position: absolute;
                margin: auto;
                top: -0.1rem;
                left: -3rem;
                bottom: 0;
                width: 1.6rem;
                height: 0.2rem;
                background-color: #000000;
                transition: all 0.2s;
              }

              &::after {
                content: '';
                margin: auto;
                content: '';
                position: absolute;
                top: 0rem;
                left: -2.2rem;
                bottom: 0;
                width: 1.6rem;
                height: 0.2rem;
                background-color: #000000;
                transition: all 0.2s;
                transform: translate(-50%, -50%) rotate(90deg) scale(1, 1);
              }

              &.active {
                &::after {
                  transform: translate(-50%, -50%) rotate(90deg) scale(0, 1);
                }
              }
            }
          }
          .answer {
            font-style: normal;
            font-weight: normal;
            font-size: 1.4rem;
            line-height: 1.6rem;
            color: #000000;
            padding: 0 2.5rem 1.9rem 5rem;
          }
        }
      }
    }
  }
}
