.wishlist_container {
  background-color: #ffffff;
  border-radius: 20px 20px 0 0;
  padding: 3rem 0rem 8rem;
  box-shadow: 0px 10px 5px #fff, 0px -30px 0px #f7f7f7;

  .title {
    font-weight: bold;
    font-size: 2rem;
    line-height: 120%;
    color: #000000;
    padding: 0 2rem 0.6rem;
    margin: 0 1rem;
  }

  .empty_wishlist {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 5rem;
    .emoji {
      font-size: 5rem;
      display: inline-block;
      margin-bottom: 1rem;
    }
    .text,
    .subline {
      font-weight: bold;
      font-size: 1.7rem;
      line-height: 2.5rem;
      text-align: center;
      color: #000000;
    }
    .subline {
      font-weight: normal;
      margin-bottom: 5.1rem;
    }
  }
}
