.title {
  font-weight: 500;
  font-size: 1.8rem;
  color: #ff3e6c;
  font-family: 'Circe';
  text-align: center;
}
.wishBS_page_content {
  height: 100% !important;
}
.content {
  .user_ref {
    margin: 0 2rem;
    padding: 3rem 1.5rem;
    border-bottom: 1px solid #eaeaea;
    .item_content {
      display: flex;
      align-items: center;
      .user_img {
        width: 3.2rem;
        height: 3.2rem;
        flex-shrink: 0;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
          box-shadow: 0px 1px 16px rgba(148, 158, 183, 0.5);
        }
      }
      .user_name {
        font-size: 1.6rem;
        line-height: 1.9rem;
        margin-left: 1.2rem;
        color: #2d2d2d;
        .user_teg {
          font-size: 1.3rem;
          line-height: 1.5rem;
          padding-top: 0.4rem;
          color: #adadad;
        }
      }
    }
  }
}
