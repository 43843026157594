.menu {
  width: 24rem;

  &.menu_mobile {
    width: 100%;
  }
}

.menuTitle {
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-family: 'Circe'; // TODO: add fallback fonts
}
