.container {
  padding-top: 2rem;
  padding-bottom: 0;
}
.separator {
  background-color: #eaeaea;
  height: 0.1rem;
  width: calc(100% - 5rem);
  margin: 1rem auto 1.5rem;
}
.info {
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #4f5665;
  padding: 0.5rem 2.5rem;
}
.list {
  padding-left: 2.5rem;
  padding-right: 0.5rem;
  padding-bottom: 15;
  display: flex;
  flex-wrap: nowrap;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
.categ_item {
  padding: 1.5rem;
  font-size: 1.4rem;
  line-height: 1.8rem;
  background-color: #f9fafc;
  border-radius: 1rem;
  margin-right: 2rem;
  color: #000;
  flex-shrink: 0;
}
