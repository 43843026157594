.container {
  background: #f8f8f8;
  padding: 0 2rem;
  margin-bottom: 0.4rem;
  border-radius: 1rem;
  display: inline-flex;
  align-items: center;
}
.title {
  font-size: 1.5rem;
  line-height: 1.8rem;
  font-weight: bold;
  margin-right: 2rem;
}
.list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  justify-content: space-between;
}
.item {
  width: calc(33% - 0.5rem);
  height: 6rem;
  border: 0.1rem solid #ffffff;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin-top: 1rem;
  box-sizing: border-box;
  border-radius: 1rem;
  cursor: pointer;
  &:nth-child(3n) {
    margin-right: 0;
  }
  &.active {
    border-color: #ff3e6c;
  }
  .name {
    font-size: 1.2rem;
    line-height: 1.4rem;
    flex: 1;
    padding-left: 1rem;
  }
  .img_container {
    width: 2rem;
    height: 5rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
.select_container {
  position: relative;
  width: 10.3rem;
  height: auto;

  .select_item_active,
  .select_item {
    align-items: center;
    display: flex;
    height: 5.7rem;
    background: #ffffff;
    border-radius: 10px;

    &.select_item_min {
      .select_img {
        margin: 0;
      }
    }

    .select_img {
      margin-right: 1rem;
      width: 6.5rem;
      height: 3.2rem;
      display: flex;
      justify-content: center;

      img {
        max-width: 100%;
        max-height: 100%;
        width: 3.2rem;
        object-fit: contain;
      }
    }
    .arrow {
      transform: rotate(-90deg);
      width: 0.7rem;
      height: 1.27rem;
      transition: ease-out 0.3s;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .select_item_active {
    transition: ease-out 0.5s;
    &.open {
      border-radius: 10px 10px 10px 0px;
      .arrow {
        transform: rotate(90deg);
        transition: ease-out 0.3s;
      }
    }
  }
  .animation {
    position: relative;
    top: -5.55rem;
    .select_modal {
      position: absolute;
      top: 5.5rem;
      width: 65%;
      .select_item {
        border-radius: 0;

        &:last-child {
          border-radius: 0 0 10px 10px;
        }
      }
    }
  }
}
