.product_wrapper {
  position: relative;
}
.product_card {
  width: 16rem;
  height: 21.5rem;
  padding: 0 1.5rem;
  padding-top: 2.5rem;
  padding-bottom: 1rem;
  border-right-width: 0.1rem;
  border-right-style: solid;
  border-right-color: #f0f0f0;
  justify-content: space-between;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  color: #000;
}
.lastitem {
  border-right-width: 0;
}
.adaptive {
  width: calc(100% - 4.1rem);
  height: 100%;
  padding: 0 2rem;
}
.prod_img_block {
  width: 14rem;
  height: 12rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.adaptive_img_block {
  width: 100%;
  height: calc((100vw - 6.5rem) / 1.3 / 2);
}
.product_info {
  margin-top: auto;
}
.prod_img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.adaptive_img {
  width: 100%;
  height: calc((100vw - 6.5rem) / 1.3 / 2);
}
.product_name {
  font-size: 1.5rem;
  line-height: 1.8rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.product_price {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.8rem;
  padding-top: 0.5rem;
}
.add_container {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}
.add_icon {
  width: 2.8rem;
  height: 2.8rem;
  background-color: #ff3e6c;
  border-radius: 5rem;
  align-items: center;
  justify-content: center;
  display: flex;
  img {
    width: 1.4rem;
  }
}
.partner_name {
  font-size: 1rem;
  line-height: 1.4rem;
  color: #808694;
  max-width: 80%;
}
.horizontal {
  flex-direction: row;
  display: flex;
  width: 100%;
  border-right-width: 0;
  height: auto;
  min-height: 18.5rem;
  padding-bottom: 2rem;
  width: calc(100% - 4rem);
}
.horizontal_img_block {
  width: 12rem;
  height: 100%;
  flex-shrink: 0;
}
.horizontal_info {
  margin-top: 0;
  margin-left: 2rem;
  width: calc(100% - 14rem);
}
.horizontal_price {
  margin-top: 1rem;
}
.for_btn_container {
  height: 3.4rem;
  margin-top: auto;
}
.red_btn_container {
  margin-top: 0;
  align-self: flex-start;
  position: absolute;
  bottom: 2rem;
  left: 16rem;
}
.red_btn {
  width: 12rem;
  height: 3.4rem;
  margin-top: 1.5rem;
  font-size: 1.5rem;
}
.top_block {
  position: absolute;
  flex-direction: row;
  display: flex;
  width: calc(100% - 1rem);
  justify-content: space-between;
  left: 0;
  padding-left: 1rem;
  top: -1rem;
}
.top_block_horizontal {
  top: 1rem;
}
.top_item {
  padding: 0 0.8rem;
  padding-top: 0.3rem;
  padding-bottom: 0.2rem;
  border-radius: 3rem;
  overflow: hidden;
  margin-bottom: 0.2rem;
  margin-right: 0.2rem;
  width: fit-content;
}
.top_txt {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: bold;
  color: #ffffff;
}
.top_labels {
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
}
.top_wish {
  background-color: #31b2ff;
}
.top_idea {
  background-color: #fbbb00;
}
.more_btn {
  padding-right: 1rem;
  padding-left: 1rem;
  height: 3rem;
  svg {
    width: 1.8rem;
  }
}
.cacheback {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  display: flex;
  bottom: 0.5rem;
  right: 0.5rem;
  padding: 0.3rem 0.5rem;
  border-radius: 1rem;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.cacheback_txt {
  font-size: 1rem;
  line-height: 1.6rem;
  font-weight: bold;
  padding-left: 0.5rem;
  color: #ffffff;
}
