.list_container {
  padding: 0 2rem;

  .item {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    color: #000000;

    .img_block {
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .name {
      font-size: 1.4rem;
      line-height: 1.6rem;
      margin-left: 2rem;
    }
    .arrow {
      margin-left: auto;
    }
  }
}
