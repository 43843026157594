.mobile_menu {
  display: block;
  width: 100%;
  height: 100%;
  max-width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  z-index: 50;
  background: #ffffff;
  transition: transform 300ms ease-in-out;

  &.opened {
    transform: translateX(0);
  }

  .close_button {
    position: absolute;
    top: 3.5rem;
    right: 3rem;
  }
  .menu_list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15rem;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    padding-top: 9rem;

    .menu_link_item {
      text-align: center;
      padding: 1.5rem 0;
      width: fit-content;

      a {
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 32px;
        color: #1a1a1a;
        font-family: 'Circe';
        text-decoration: none;
      }
    }
    .signIn_button {
      margin-top: 4.2rem;

      a {
        font-size: 1.8rem;
        line-height: 2.1rem;
        width: 26rem;
        height: 5rem;
      }
    }

    .social_links {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      margin-top: 6rem;

      a {
        margin: 0 1rem;

        svg {
          max-width: 2.2rem;
          height: auto;
        }
      }
    }
  }
}
