.item {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 1.5rem 2rem;
  background: #ffffff;
  box-shadow: 0px 4px 40px #f0f0f0;
  border-radius: 10px;
  margin-top: 2rem;
  transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
  box-sizing: border-box;
  border: 0;

  &:hover {
    box-shadow: 0px 4px 60px #dedede;
    transform: scale(1.01);
  }
}

.details {
  display: flex;
  flex-direction: column;
}

.title {
  font-family: 'Circe';
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.6rem;
  color: #000000;
}

.status {
  border-radius: 50px;
  font-size: 1.3rem;
  line-height: 1.5rem;
  color: #ffffff;
  width: fit-content;
  padding: 0.3rem 1rem;
  margin-top: 0.7rem;
  background-color: #65718a;

  &.purple {
    background-color: #b01cf5;
  }

  &.blue {
    background-color: #00b5f1;
  }

  &.red {
    background-color: #f51c1c;
  }

  &.pink {
    background-color: #ff3e6c;
  }
}

.deliveryDay {
  margin-top: 1rem;
  font-size: 1.3rem;
  line-height: 1.5rem;
  color: #000000;
}

.photo {
  width: 4rem;
  height: 4rem;
  text-align: right;
  flex-shrink: 0;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 4px;
  }
}
