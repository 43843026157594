.label {
  display: flex;
  align-items: center;
  padding: 1.5rem 2rem;
  background: #ffffff;
  border-radius: 2rem;
  box-shadow: 0px 4px 40px #f0f0f0;
  margin-top: 1.5rem;

  &.hide_padding {
    padding-bottom: 1.5rem !important;

    .input_text {
      padding-top: 0 !important;
    }
  }

  &.checked_phone {
    padding-bottom: 2.9rem;

    .input_text {
      padding-top: 1.9rem;
    }
  }
  &.checked_email {
    padding-bottom: 2.952rem;
    .input_text {
      padding-top: 0.9rem;
    }
  }

  &.textarea {
    flex-direction: column;

    .input_text {
      justify-content: space-between;
      display: flex;
      width: 100%;

      .word_count {
        font-style: normal;
        font-weight: normal;
        font-size: 1.2rem;
        line-height: 1.4rem;
        color: #b8b8b8;
      }
    }
    .input_block {
      margin-left: 0;
    }
  }

  .input_text {
    font-weight: bold;
    font-size: 1.4rem;
    color: #1a1a1a;
    text-align: left;
    width: fit-content;
    flex-shrink: 0;
    line-height: 1.6rem;
    padding-top: 0.2rem;
  }
  .input_block {
    margin-left: 1.3rem;
    position: relative;
    width: 100%;
    text-align: right;
    textarea {
      font-size: 1.4rem;
      font-family: 'Circe';
      width: 100%;
      margin-top: 1rem;
      border: none;
      padding: 0;
      resize: none;
      overflow: auto;
    }
    input {
      border: none;
      font-size: 1.4rem;
      font-family: 'Circe';
      text-align: right;
      color: #111111;
    }
    .subtitle {
      font-size: 1.3rem;
      line-height: 1.5rem;
      color: #b0b0b0;
      display: flex;
      align-items: center;
      width: fit-content;
      margin-top: 0;
      margin-left: auto;
      .red_msg {
        color: #ff3e6c;
      }
    }
  }
}
.phoneInput {
  width: fit-content;
  margin-left: auto;
  input {
    margin-left: auto;
    width: 16.5rem !important;
    padding-right: 0;
    text-align: left !important;
    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }
  }
}
.flag_dropdown {
  border: none !important;
  background: #ffffff !important;
}
.phoneDropdown {
  width: 20rem !important;
}

@media screen and (max-width: 1400px) {
  .phoneInput {
    input {
      width: 17.5rem !important;
    }
  }
}
@media screen and (max-width: 1200px) {
  .phoneInput {
    input {
      width: 18.5rem !important;
    }
  }
}
@media screen and (max-width: 768px) {
  .phoneInput {
    width: fit-content;
    margin-left: auto;
    input {
      margin-left: auto;
      width: 16.5rem !important;
      padding-right: 0;
      text-align: left !important;
      &:focus {
        outline: none;
        border: none;
        box-shadow: none;
      }
    }
  }
}
