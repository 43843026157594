.page_content {
  width: 100%;
  height: 100%;
  background: #000;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow: hidden;
}
.swiper_container {
  //   width: calc(100% + 9rem);
  //   left: -4.5rem;
  //   position: relative;
}
