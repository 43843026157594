.container {
  padding: 1.5rem 3rem;
  position: relative;
  border-radius: 1rem;
  background: #ffffff;
  box-shadow: 0 0.4rem 4rem #f0f0f0;
  margin-top: 1.5rem;
  display: block;

  .top_block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: #999;
  }

  .textarea {
    font-family: 'Circe';
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: #000000;
    margin-top: 0.5rem;
    width: 100%;
    border: none;
    padding: 0;
    resize: none;

    &::placeholder {
      color: #999999;
      font-size: 1.3rem;
    }
  }
}
