.container {
  padding: 0 0 5rem;
  background-color: rgb(247, 247, 247);
  height: calc(100% - 6rem);
}

.content {
  padding: 0 2rem 0;
}
.input_phone {
  border: none !important;
  box-shadow: 0 0.4rem 4rem #f0f0f0;
  margin-bottom: 2rem;
}
.title_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4rem;
  img {
    width: 5rem;
    height: 5rem;
    object-fit: contain;
  }
}
.pay_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.comment_container {
  height: 3.4rem;
}
.input_block__donatesum {
  display: inline-flex;
  justify-content: right;
  background: #ffffff;
  border-radius: 1rem;
  padding: 1.44rem 1.99rem;
  margin-right: 2rem;
  align-items: center;
  width: 100%;
  margin-bottom: 0.4rem;
  border: 0.1rem solid transparent;
  &.error {
    box-sizing: border-box;
    border: 0.1rem solid #ff3e6c;
  }

  .input__text {
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.8rem;
    color: #000;
  }

  input {
    font-style: normal;
    font-size: 2.4rem;
    line-height: 2.6rem;
    color: #ff3e6c;
    max-width: 15rem;
    min-width: 10rem;
    width: 100%;
    text-align: right;
    border: none;
    font-family: 'Circe';
    background: #ffffff;
    font-weight: bold;
    position: relative;
    top: 0.2rem;
  }

  span {
    display: block;
    // height: 2.3rem;
    padding-left: 0.5rem;
    font-style: normal;
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 2.6rem;
    color: #ff3e6c;
  }

  &.sumError {
    border: 1px solid rgb(255, 62, 108);
  }
  .donatesum_content {
    display: flex;
    align-items: center;
  }
}

.checkbox_container {
  label {
    span {
      color: #000;
      font-size: 1.5rem;
      line-height: 1.8rem;
      color: #212121;
    }
    > span > span {
      background: #fff;
      border: 0.1rem solid #ececec;
      box-sizing: border-box;
      margin-right: 0.5rem;
    }
  }
}
.selector_sum {
  margin-top: 1.5rem;
  display: flex;
  overflow: auto;
  padding: 0 2rem;
  margin-bottom: 1.1rem;
  &::-webkit-scrollbar {
    display: none;
  }

  .btn {
    width: 9rem;
    height: 3.2rem;
    margin-right: 1.1rem;
    border-radius: 0.7rem;
    border: 0.1rem solid #f8f8f8;
    outline: none;
    font-family: 'Circe';
    font-size: 1.5rem;
    line-height: 2.2rem;
    padding-top: 0.3rem;
    flex-shrink: 0;
    color: #747474;
    background-color: #ffffff;

    span {
      font-size: 1.8rem;
    }
    &:last-child {
      margin-right: 0;
    }
    &.active {
      border: 0.1rem solid #ff3e6c;
    }
  }
}

.err {
  margin-top: 1.5rem;
}
.auth {
  margin-bottom: 2rem;
}

.wish_container {
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 1.5rem;
  .wish_wrapper {
    padding: 1.4rem 2.4rem 1.3rem 1.9rem;
    display: flex;

    .left {
      width: 5.6rem;
      height: 5.8rem;
      margin-right: 2rem;
      flex-shrink: 0;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
    .right {
      position: relative;
      .title {
        font-weight: bold;
        font-size: 1.5rem;
        line-height: 1.7rem;
        display: -webkit-box;
        max-width: calc(100% - 2rem);
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin: 0;
        margin-bottom: 0.4rem;
      }
      .circlestat {
        width: 2.1rem;
        flex-shrink: 0;
        position: relative;

        img {
          width: 1.05rem;
          height: 1.05rem;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: block;
        }
      }

      .cart_status {
        display: flex;
        align-items: center;

        .status_text {
          font-size: 1.3rem;
          line-height: 1.5rem;
          color: #747474;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          margin-left: 0.8rem;
        }
      }
      .arrow {
        position: absolute;
        top: 35%;
        right: 0;
        transform: rotate(-90deg);

        img {
          width: 1rem;
          height: 1.7rem;
        }
      }
    }
  }
}
