.friend_container {
  display: flex;
  //   position: relative;
  margin-right: 1.3rem;
  font-family: 'Circe';

  .modal_open {
    padding: 0 !important;
  }

  .icon {
    width: 3.4rem;
    height: 3.4rem;
    position: relative;
    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }

    .pipka {
      position: absolute;
      width: 2.4rem;
      height: 1.5rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 3rem);
    }
  }

  .friend_req {
    background: #eaeaea;
    position: absolute;
    width: 100%;
    top: 7rem;
    left: 50%;
    transform: translate(-50%, 0);

    .buttons {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      padding: 1.3rem 0;

      .btn {
        border-radius: 30px;
        font-style: normal;
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 2.2rem;
        display: flex;
        align-items: center;
        text-align: center;
        outline: none;
        border: none;
        justify-content: center;
        height: 3.2rem;
        width: 14.2rem;

        &.one_button {
          width: 20.7rem;
          justify-content: center;
          font-family: 'Circe';
        }

        &.red_button {
          background: #ff3e6c;
          box-shadow: 0px 4px 10px rgba(255, 0, 76, 0.25);
          color: #ffffff;
          font-family: 'Circe';
        }
        &.white_button {
          background: #ffffff;
          box-shadow: 0px 4px 20px #dddddd;
          color: #000000;
          font-family: 'Circe';
        }
      }
    }
  }
}

.content {
  padding: 2rem;

  .item {
    display: flex;
    align-items: center;
    padding: 0.8rem 2rem;
    background: #ffffff;
    box-shadow: 0px 1px 40px rgba(148, 158, 183, 0.18);
    border-radius: 25px;
    .title {
      font-style: normal;
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 2.2rem;
      color: #ff004c;
    }
    .icon {
      margin-left: auto;
      width: 1.7rem;
      height: 2.1rem;
      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }
  }
}
