// @import url('https://fonts.googleapis.com/css?family=Nunito');
// @import url('./fonts/Roboto/roboto.css');
@import url('./fonts/Circe/stylesheet.css');
@import url('./fonts/SFPro/stylesheet.css');

.html {
  font-size: 62.5%;
}

.html,
.body {
  font-family: 'Circe';
  position: relative;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: 62.5%;
}
.app_container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.page_content {
  flex: 1 0 auto;
}

@media screen and (min-width: 769px) {
  * {
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #eaeaea;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background-color: #eaeaea;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #bebebe;
    }
  }
}
