.inner_content.wish {
  height: calc(100% - 2.8rem);
}

.blocked {
  overflow: hidden;
}

.headline.wish,
.headline.prod {
  margin: 0;
  padding: 1rem 2rem;
  display: block !important;
  // position:sticky;
  position: relative;
  top: 0;
  background: #ffffff;
  z-index: 2;
  overflow: hidden;

  &.prod {
    position: relative;
  }
  &.wish {
    padding-bottom: 0;
  }

  //   &.active {
  //     pointer-events: all;
  //   }

  .flex_block {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .from_block {
      //width:70%;
      width: fit-content;
      display: block;
      font-family: 'Circe';
      font-size: 1.4rem;
      color: #767676;
    }

    .categ_name {
      font-weight: bold;
      font-size: 1.3rem;
      color: #ff3e6c;
    }

    .links {
      display: flex;
      flex-shrink: 0;
      width: 8rem;
      justify-content: flex-end;

      div {
        flex-shrink: 0;
        width: 3.2rem;
        height: 3.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }

      .settings_link {
        background: #ffffff;
        box-shadow: 0px 4px 10px rgba(164, 164, 164, 0.42);
        text-align: center;
        border-radius: 50%;

        img {
          width: 1.2rem;
        }
      }

      .share_link {
        background: #ff3e6c;
        box-shadow: 0px 4px 10px rgba(255, 0, 76, 0.42);
        margin-left: 1rem;

        img {
          width: 1.4rem;
        }
      }
    }
  }

  .wish_title {
    width: 100%;

    .wish_name {
      position: relative;
      z-index: 2;
      font-size: 2.4rem;
      line-height: 2.8rem;
      color: #000000;
      font-weight: 500;
      padding-right: 1rem;
      margin: 0;
      width: 100%;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .categ_name {
      font-size: 1.3rem;
      line-height: 1.5rem;
      display: block;
      color: #ff3e6c;
      font-weight: 500;
      margin-top: 0.5rem;
      width: fit-content;
    }
  }

  .wish_circle {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    padding: 2rem 0;
    padding-bottom: 0;
    height: 33rem;

    canvas {
      z-index: 10000;
    }

    .circle_content {
      height: 23rem;
      position: absolute;
      width: 95%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      z-index: 10010;
      padding-top: 3rem;

      &.delivered {
        height: 21rem;
        width: fit-content;

        .delivered_arrow {
          background: #ffffff;
          box-shadow: 0px 0px 10px rgba(146, 146, 146, 0.25);
          position: absolute;
          bottom: -5rem;
          right: -5.5rem;
          width: 6.9rem;
          height: 6.9rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;

          img {
            width: 3.1rem;
            height: 2.4rem;
            object-fit: cover;
          }
        }

        .img_block {
          width: 20rem;
          height: 20rem;
        }
      }

      .img_block {
        width: 16rem;
        height: 16rem;
        position: relative;
        margin: 0 auto;
        // backdrop-filter: blur(4px);

        img {
          max-width: calc(100% - 1rem);
          max-height: calc(100% - 1rem);
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
        }
      }

      .collected_block {
        text-align: center;
        margin-top: 2rem;

        .collected {
          font-weight: bold;
          font-size: 1.8rem;
          line-height: 2.1rem;
          color: #ff3e6c;
        }

        .total {
          font-size: 1.3rem;
          line-height: 1.5rem;
          margin-top: 0.5rem;
          color: #050505;
        }
      }

      .status_block {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background: rgba(255, 255, 255, 0.7);
        backdrop-filter: blur(4px);
        border-radius: 50%;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-weight: bold;
        font-size: 1.8rem;
        line-height: 2.1rem;
        width: 22.5rem;
        height: 22.5rem;
        text-align: center;

        img {
          margin-bottom: 1rem;
        }

        .subtitle {
          padding-top: 0.5rem;
          font-weight: normal;
        }
      }
    }
  }

  .wish_circle,
  .swiper_container,
  .circle_content {
    overflow: visible;
  }

  .wish_cart_preview {
    display: flex;
    height: fit-content;
    background: #ffffff;
    box-shadow: 0px 4px 30px #f0f0f0;
    border-radius: 20px;
    width: calc(100% - 8rem);
    padding: 1.5rem 2rem 1.5rem 2rem;
    align-items: center;
    margin-top: 0.5rem;
    margin-bottom: 1.2rem;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    opacity: 0;
    overflow: hidden;

    &.active {
      pointer-events: all;
    }

    .circlestat {
      width: 3rem;

      img {
        width: 3rem;
      }

      canvas {
        opacity: 0;
      }
    }

    .text {
      overflow: hidden;
      padding: 0;
      padding-right: 1.6rem;
      padding-left: 1.5rem;
      width: calc(100% - 4rem);

      .cart_name {
        color: #000000;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 1.4rem;
        line-height: 1.6rem;
        // opacity:0;
      }

      .cart_status {
        font-weight: bold;
        font-size: 1.3rem;
        line-height: 1.5rem;
        color: #0e0e0e;
        padding-top: 0.5rem;
      }
    }

    .wish_img {
      width: 4rem;
      height: 4rem;
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      opacity: 0;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}

.stat_block {
  display: flex;
  align-items: center;
  padding: 0 2rem;
  padding-top: 1rem;
  background: #ffffff;
  z-index: 3;

  > div {
    display: flex;
    align-items: center;
    margin-right: 1.5rem;
    font-size: 1.4rem;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    img {
      margin-right: 0.5rem;
      width: 2rem;
    }
  }

  .liked_users {
    margin-right: 0;
    margin-left: auto;
    display: flex;
    align-items: center;
    .text {
      font-size: 1.4rem;
      color: #363636;
      padding-right: 1rem;
    }
    .users {
      position: relative;
      .photo {
        width: 2.5rem;
        height: 2.5rem;
        object-fit: cover;
        z-index: 1;
        border-radius: 50%;
        margin-right: 0;

        &.photo2 {
          z-index: 2;
          transform: translateX(-0.5rem);
        }
      }
    }
  }
}

.actions_block {
  display: flex;
  padding: 1.5rem 2rem 1.5rem 2rem;
  margin-left: 0;
  position: relative;
  width: calc(100% - 4rem);
  background: #ffffff;
  z-index: 2;
  overflow: hidden;

  .swiper_slide {
    width: fit-content;
  }

  .btn {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.7rem 1.5rem 0.8rem 1.5rem;
      border: none;
      font-family: 'Circe';
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: #b1b9c9;
      background: #f9fafc;
      border-radius: 20px;
      width: fit-content;

      span {
        transform: translateY(1px);
      }

      img {
        padding-right: 0.5rem;
        max-width: 2.8rem;
      }

      &:focus {
        outline: none;
      }
    }

    &.red_btn {
      button {
        background: #ff3e6c;
        box-shadow: 0px 4px 10px rgba(255, 0, 76, 0.42);
        border-radius: 30px;
        font-weight: bold;
        color: #ffffff;
        padding: 0.7rem 1.5rem 0.8rem 1.5rem;

        img {
          padding-right: 1rem;
        }
      }
    }

    &.grad_btn {
      margin: 0 auto;

      button {
        background: linear-gradient(90.35deg, #11ddff -21.76%, #ac17ff 99.96%);
        box-shadow: 0px 4px 4px rgba(132, 74, 255, 0.4);
        color: #ffffff;
      }
    }
  }
}

.more_details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  padding: 1.5rem 2rem;
  margin-top: 3rem;

  a.prod_link {
    font-weight: bold;
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: #363636;

    img {
      height: 1.2rem;
      padding-left: 1rem;
    }
  }

  .partner_link {
    img {
      max-height: 1.5rem;
    }
  }
}

.wish_information_container {
  padding-bottom: 1rem;
  height: fit-content;

  .comment_block {
    margin: 0.5rem 2rem;
    margin-top: 0;

    .comment_text {
      font-size: 1.4rem;
      line-height: 1.6rem;
      color: #363636;
      position: relative;
      word-break: break-all;

      .user_name {
        font-weight: bold;
        margin-right: 0.5rem;
      }
    }
  }
}

.wish_history_comment {
  // padding:0 2rem;
  padding-bottom: 2rem;
  font-size: 1.4rem;
  line-height: 2rem;
  position: relative;

  .title {
    font-weight: 500;
    font-size: 1.4rem;
    color: #000000;
    margin-top: 2rem;
    padding: 0 2rem;
  }

  .comment_item {
    display: flex;
    padding: 2rem;

    .user_img {
      flex-shrink: 0;

      img {
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        box-shadow: 0px 1px 16px rgba(148, 158, 183, 0.5);
        object-fit: cover;
      }
    }

    .center {
      padding-left: 1.2rem;
      width: 24.8rem;

      .comment_text {
        word-break: break-word;
        display: inline;
        padding-right: 0.5rem;
      }

      span {
        font-weight: bold;
      }

      .answer {
        display: none;
      }
    }

    .right {
      margin-left: auto;
      font-size: 1rem;
      line-height: 1.2rem;
      text-align: right;

      .likes {
        cursor: pointer;
        display: flex;
        justify-content: flex-end;
        margin-top: 0.3rem;

        img {
          width: 1.3rem;
        }

        span {
          padding-left: 0.3rem;
        }
      }
    }

    span.time {
      color: #d0d0d0;
      // padding-left:0.5rem;
      font-weight: normal;
      display: inline-block;
    }

    .sum {
      background: #ff3e6c;
      border-radius: 50px;
      font-family: 'Circe';
      font-weight: bold;
      font-size: 1.4rem;
      color: #ffffff;
      padding: 0.3rem 1rem 0.4rem 1rem;
      width: fit-content;
      padding: 0.3rem 1rem 0.1rem 1rem;
      margin-top: 0.5rem;
    }
  }

  .history_item_preview {
    .comment-item {
      padding: 2rem 2rem 0 2rem;
    }
  }

  .hidden_comments {
    display: none;
    // position: absolute;
    // background: #ffffff;
    // top: 8rem;
    // padding: 0 2rem;
    // width: calc(100% - 4rem);
    // left: 0;
  }

  .show_all {
    color: #ff3e6c;
    font-weight: 500;
    cursor: pointer;
    // margin-left: 5rem;
    margin: 0 auto;
    width: fit-content;
  }
}

.flex_link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  border-top: 1px solid #eaeaea;
  font-size: 1.4rem;
  color: #363636;
  font-weight: bold;

  > div:first-child {
    width: calc(100% - 2rem);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.provider {
  display: flex;
  border-top: 1px solid #eaeaea;
  padding: 2rem;

  .left {
    width: calc(100% - 8rem);
    font-size: 1.4rem;

    .title {
      font-weight: bold;
      color: #363636;
    }

    .subtitle {
      color: #828d99;
      margin-top: 0.5rem;
    }

    a {
      color: #828d99;
      text-decoration: underline;
      display: block;
      width: fit-content;
      margin-top: 0.5rem;
    }
  }

  .right {
    width: 7rem;
    flex-shrink: 0;
    text-align: right;

    img {
      max-width: 100%;
      max-height: 7.5rem;
    }
  }
}

.create_date {
  border-top: 1px solid #eaeaea;
  font-size: 1.4rem;
  color: #828d99;
  padding: 2rem;

  time {
    padding: 0 0.5rem;
  }
}

.description_content_block {
  .info_block {
    display: flex;
    justify-content: space-between;
    padding: 1rem 3rem;

    > div {
      width: 50%;

      .title {
        font-size: 1.2rem;
        color: #4b4b4b;
      }
    }

    .sum_block {
      display: block;
      padding: 0;

      .sum {
        margin-top: 0.5rem;
      }
    }

    .donates_block {
      width: calc(50% - 2rem);
      border-left: 1px solid #eaeaea;
      padding-left: 3rem;

      .donates_count {
        font-weight: bold;
        font-size: 1.8rem;
        margin-top: 0.5rem;
      }
    }
  }

  .donate_button_block {
    button {
      width: fit-content;
      height: 4rem;
      padding: 0 2rem;
      text-transform: none;

      .plus {
        padding-bottom: 0.2rem;
      }
    }
  }

  .short_descr {
    padding: 2rem;
    margin: 3rem 0 0 0;
    background: #ffffff;
    border-radius: 0;
    border-top: 1px solid #eaeaea;
  }

  .charact_block {
    padding: 2rem;
    border-top: 1px solid #eaeaea;

    .title {
      font-weight: bold;
      font-size: 1.4rem;
      color: #1a1a1a;
    }

    .charact_item {
      display: flex;
      justify-content: space-between;
      font-size: 1.3rem;
      line-height: 1.5rem;
      margin-top: 1rem;

      .name {
        width: calc(50% - 1.5rem);
        color: #828d99;
      }

      .val {
        width: calc(50% - 1.5rem);
      }
    }

    .bottom-_lock {
      margin-top: 2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .show_all {
        font-weight: bold;
        font-size: 1.4rem;
        color: #ff3e6c;
      }
    }
  }
}

.comment_input__suggestions {
  background: #ffffff;
  box-shadow: 0px 4px 40px #f0f0f0;
  padding: 1rem 0;
  font-size: 1.6rem;
  line-height: 1.8rem;
  border-radius: 1rem;
  overflow: hidden;
}
.comment_input__highlighter {
  border: none !important;
}
.comment_input__suggestions__item {
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  img {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 1.5rem;
    box-shadow: 0px 1px 16px rgba(148, 158, 183, 0.5);
  }
}
.comment_input__suggestions__item--focused {
  background: #f8f9fb;
}

.mentions {
  color: #ff3e6c;
  background: #ffffff;
  position: relative;
  z-index: 1;
}

.ment_user {
  color: #ff3e6c;
  &:hover {
    color: #ff3e6c;
  }
}

.present_container {
  padding: 0 2rem;
  font-family: 'Circe';
  position: relative;
  height: 100%;
  .user_teg {
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 235.19%;
    color: #767676;
    margin-bottom: 1rem;
    z-index: 1;
    position: relative;

    .is_validated {
      position: relative;
      top: -0.2rem;
      .img_check {
        width: 1.3rem;
        height: 1.3rem;
        margin-left: 1rem;
        opacity: 1;
        cursor: pointer;
      }
      .tooltip_hovered {
        position: absolute;
        opacity: 0;
        background-color: #ffffff;
        color: #000000;
        background: #ffffff;
        box-shadow: 0px 0px 40px #f0f0f0;
        border-radius: 5px;
        padding: 0.5rem;
        right: -1rem;
        top: 4rem;
        font-style: normal;
        font-weight: normal;
        font-size: 1.3rem;
        line-height: 1.9rem;
        min-width: 14.5rem;

        &::before {
          content: '';
          display: block;
          width: 0;
          height: 0;
          bottom: 2.5rem;
          right: 2.5rem;
          position: absolute;
          border-top: 1.4rem solid transparent;
          border-bottom: 0rem solid transparent;
          border-right: 1.3rem solid #ffffff;
          box-shadow: 0px 0px 40px #f0f0f0;
        }
      }
      .img_check:hover + .tooltip_hovered {
        opacity: 1 !important;
        transition: all 0.3s ease-out;
      }
    }
  }
  .wish_headline {
    font-style: normal;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 2.8rem;
    color: #000000;
  }

  .img_block {
    text-align: center;
    margin: 7rem;

    img {
    }
  }
  .message_container {
    margin-bottom: 2.5rem;
    .message_title {
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 1.6rem;
      color: #000000;
      margin-bottom: 1.4rem;
    }
    .message_text {
      font-style: normal;
      font-weight: normal;
      font-size: 1.4rem;
      line-height: 1.6rem;
      color: #363636;
      padding: 2rem;
      background: #f9f9f9;
      border-radius: 10px;
    }
    img {
      width: 16px;
      height: 16px;
      position: absolute;
      left: 4rem;
    }
  }
  .user {
    display: flex;
    align-items: center;
    .img_block {
      width: 30px;
      height: 30px;
      margin: 0;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .name {
      margin-left: 1.2rem;
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 1.6rem;
      color: #2d2d2d;
    }
  }
  .buttons_container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 2rem;

    .red_button {
      font-style: normal;
      font-weight: bold;
      font-size: 1.8rem;
      line-height: 2.1rem;
      color: #ffffff;
      padding: 1rem 1.5rem;
      background: #ff3e6c;
      box-shadow: 0px 4px 10px rgba(255, 0, 76, 0.42);
      border-radius: 30px;
      margin-bottom: 1.8rem;
    }
    .see_later_button {
      font-style: normal;
      font-weight: normal;
      font-size: 1.3rem;
      line-height: 1.5rem;
      text-decoration: underline;
      color: #ff3e6c;
    }
  }
}

.donate_btn {
  height: 5.2rem;
  background: #ff3e6c;
  box-shadow: 0px 4px 10px rgba(255, 0, 76, 0.42);
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  width: calc(100% - 4.2rem);
  margin: 0 auto;
  margin-top: 2rem;
  font-family: 'Circe';

  .img_donate {
    margin-right: 1rem;
    width: 2.2rem;
    height: 2.2rem;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }
  .text_donate {
    font-style: normal;
    font-weight: 700;
    font-size: 1.7rem;
    line-height: 2.5rem;
    color: #ffffff;
  }
}

.selector_sum {
  font-family: 'Circe';
  margin-top: 1.5rem;
  display: flex;
  overflow: auto;
  padding: 0 2rem;
  margin-bottom: 1.1rem;
  &::-webkit-scrollbar {
    display: none;
  }

  .btn {
    width: 9rem;
    height: 3.2rem;
    margin-right: 1.1rem;
    border-radius: 0.7rem;
    border: 0.1rem solid #f9fafc;
    border: none;
    outline: none;
    font-family: 'Circe';
    font-size: 1.5rem;
    line-height: 2.2rem;
    padding-top: 0.3rem;
    flex-shrink: 0;
    color: #747474;
    background-color: #f9fafc;

    span {
      font-size: 2rem;
    }
    &:last-child {
      margin-right: 0;
    }
    &.active {
      border: 0.1rem solid #ff3e6c;
    }
  }
}

.completed_btn {
  height: 5.2rem;
  background: linear-gradient(90.35deg, #11ddff -21.76%, #ac17ff 99.96%);
  box-shadow: 0px 4px 4px rgba(132, 74, 255, 0.4);
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  width: calc(100% - 4rem);
  margin: 0 auto;
  font-family: 'Circe';

  .img_completed {
    // margin-right: 1rem;
    width: 2.2rem;
    height: 2.2rem;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }
  .text_completed {
    font-style: normal;
    font-weight: 700;
    font-size: 1.7rem;
    line-height: 2.5rem;
    color: #ffffff;
  }
}

.delivered_block {
  font-family: 'Circe';
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  .delivered_title {
    font-style: normal;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.1rem;
    text-align: center;
    color: #1f1f1f;
    margin-bottom: 0.7rem;
  }
  .delivered_subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2.2rem;
    text-align: center;
    color: #747474;
    margin-bottom: 1rem;
  }
  .delivered_photo_btn {
    height: 5.2rem;
    background: linear-gradient(90.35deg, #11ddff -21.76%, #ac17ff 99.96%);
    box-shadow: 0px 4px 4px rgba(132, 74, 255, 0.4);
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;

    .img_delivered {
      margin-right: 1rem;
      width: 2.2rem;
      height: 2.2rem;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }
    .text_delivered {
      font-style: normal;
      font-weight: 700;
      font-size: 1.7rem;
      line-height: 2.5rem;
      color: #ffffff;
      font-family: 'Circe';
    }
  }
}

.modal_more {
  margin-right: 1rem;

  .icon {
    background: #f9fafc;
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 1.8rem;
    }
  }
}
