.top_block {
  padding: 1.5rem 1.7rem 1.9rem 1.7rem;
  background: #f0f0f0;
  border-radius: 10px;
  margin: 0 2.1rem;
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    line-height: 2rem;
    color: #000000;
    margin-top: 1.6rem;
    margin-bottom: 0.3rem;
    .icon {
      width: 2.4rem;
      height: 2.4rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      &.balance {
        background: #ff3e6c;
        svg {
          width: 1rem;
          height: 1.2rem;
        }
      }
      &.referral {
        background: #7049e5;
        svg {
          width: 1rem;
          height: 1.2rem;
        }
      }
    }
    img {
      width: 2.4rem;
      display: block;
    }
  }
  .sum {
    font-style: normal;
    margin-bottom: 2.9rem;
    font-weight: 700;
    font-size: 2.8rem;
    line-height: 100%;
    color: #000000;
    text-align: center;
  }
  .info {
    display: flex;
    justify-content: space-between;

    &.right_half {
      text-align: right;
    }
    & > div {
      // width: 48%;
      font-style: normal;
      font-weight: 400;
      font-size: 1.3rem;
      line-height: 100%;
      color: #747474;
    }
  }
}
