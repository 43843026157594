.container {
  overflow: hidden;
  // background: linear-gradient(180deg, #EDE3DF 0%, #F1E9E7 6.09%, #F2EEED 20.31%, #F6F0F0 36.98%, #F5F1F0 56.77%, #F5F1F0 81.25%, #F5F1F0 100%);
  background: #f2edeb;
  .background {
    .inner_content {
      display: flex;
      justify-content: space-between;
      //    background: linear-gradient(180deg, #EDE3DF 0%, #F1E9E7 6.09%, #F2EEED 20.31%, #F6F0F0 36.98%, #F5F1F0 56.77%, #F5F1F0 81.25%, #F5F1F0 100%);
      max-width: 144rem;
      //    width: 100%;
      margin: 0 auto;
      .left {
        padding: 14.8rem 0 0 3rem;
        flex-shrink: 0;
        z-index: 2;
        .title {
          font-style: normal;
          font-weight: 600;
          font-size: 4.8rem;
          line-height: 5.7rem;
          color: #000000;
          margin-bottom: 3.5rem;
        }
        .subtitle {
          font-style: normal;
          font-weight: 600;
          font-size: 2.1rem;
          line-height: 150%;
          color: #86868b;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 53rem;

          &.show {
            -webkit-line-clamp: 200;
          }
        }
        .link {
          color: #ff3e6c;
          font-style: normal;
          font-weight: 600;
          font-size: 2.1rem;
          line-height: 150%;
          cursor: pointer;
        }
      }
      .right {
        display: flex;
        position: relative;
        height: 70rem;
        .blur {
          position: absolute;
          left: -56rem;
          top: 0rem;
          width: 18rem;
          z-index: 1;
          height: 100%;

          &.blur2 {
            left: -5rem;
            width: 10rem;
            height: 120%;
            top: -7rem;
          }
        }
        .img {
          position: absolute;
          right: 0;
          top: 0;
          height: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .background {
      background-image: none;
      height: unset;
      .inner_content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // background: linear-gradient(180deg, #EDE3DF 0%, #F1E9E7 6.09%, #F2EEED 20.31%, #F6F0F0 36.98%, #F5F1F0 56.77%, #F5F1F0 81.25%, #F5F1F0 100%);

        .left {
          padding: 0;
          padding: 5rem 3rem 2rem;
          flex-shrink: 0;
          .title {
            font-size: 3.2rem;
            line-height: 130%;
            margin-bottom: 1.5rem;
          }
          .subtitle {
            font-style: normal;
            font-weight: normal;
            font-size: 1.7rem;
            line-height: 130%;
            color: #212121;
          }
          .link {
            font-size: 1.9rem;
            line-height: 150%;
          }
        }
        .right {
          display: flex;
          position: relative;
          height: auto;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
