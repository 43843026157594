.input_block.with_sel {
  display: flex;
  background: #ffffff;
  border-radius: 10px;
  position: relative;
  border: none;
  // margin: 2rem auto;
  padding-left: 2.5rem;
  padding-top: 2rem;
  padding-bottom: 0.2rem;
  width: calc(100% - 2.5rem);
  height: 3.5rem;
  box-shadow: 0 0.4rem 4rem #f0f0f0;

  &.wrong {
    border: 0.1rem solid #ff3e6c;
  }

  .little_text {
    position: absolute;
    top: 1rem;
    left: 3rem;
    z-index: 15;
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: #999999;
  }

  input {
    box-shadow: none;
    text-align: left;
    width: 100%;
    padding-left: 45px;
    border: none;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: #1a1a1a;
    height: 3.5rem;

    &::placeholder {
      color: #999999;
    }
  }

  .accept_icon {
    position: absolute;
    right: 2.5rem;
    top: 50%;
    transform: translateY(-50%);
    visibility: hidden;
    width: 1.4rem;
    padding-left: 1rem;

    &.show {
      visibility: visible;
    }
  }

  .loader_mini {
    position: absolute;
    right: 2.5rem;
    top: 50%;
    width: 1.4rem;
    transform: translateY(-50%);
  }

  .flag_dropdown {
    border: none;
    background: none;
  }

  .icon {
    width: 2.2rem;
    height: 2.2rem;
    margin-right: 1.5rem;
    position: absolute;
    top: 50%;
    left: 1.5rem;
    transform: translateY(-50%);

    img {
      max-width: 100%;
      width: 100%;
      max-height: 100%;
    }
  }

  &.with_icon {
    padding-left: 5rem;
    .little_text {
      left: 5rem;
    }
  }
}
