.container_page {
  padding: 0 2rem;
  font-family: 'Circe';
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 2.8rem;
      line-height: 100%;
      color: #000000;
      margin-bottom: 1.7rem;
    }
    .photos {
      display: flex;
      padding: 2rem 0;
      margin-bottom: 3rem;

      .photo_img {
        width: 33.8rem;
        height: 33.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .close {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          bottom: 2.5rem;

          button {
            outline: none;
            width: 13.9rem;
            height: 2.9rem;
            padding: 0;
            margin: 0;
            border-radius: 30px;
            background: transparent;
            border: 1px solid #ffffff;
            font-style: normal;
            font-weight: 700;
            font-size: 1.7rem;
            line-height: 2.5rem;
            text-align: center;

            color: #ffffff;
          }

          img {
            max-width: 1rem;
            max-height: 1rem;
            width: 100%;
            height: 100%;
          }
        }

        img {
          max-width: 33.8rem;
          width: 100%;
          max-height: 33.5rem;
          height: 100%;
          object-fit: cover;
          border-radius: 1rem;
        }
      }
    }
    .subtitle {
      font-style: normal;
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 100%;
      color: #000000;
      margin-bottom: 1.5rem;
    }
    .comment {
      background: #ffffff;
      border: 1px solid #eaeaea;
      box-sizing: border-box;
      border-radius: 10px;
      margin-bottom: 11.5rem;
      width: 100%;
      .comment_wrapper {
        padding: 1.2rem 1.4rem 1.1rem;
        display: flex;
        align-items: center;
        .user_img {
          flex-shrink: 0;
          display: flex;
          img {
            width: 3rem;
            height: 3rem;
            object-fit: cover;
            border-radius: 50%;
          }
        }
        .input_container {
          padding: 0 1rem;
          margin-left: 1rem;

          .input {
            outline: none;
            border: none;
            font-style: normal;
            font-weight: 400;
            font-size: 1.5rem;
            line-height: 2.2rem;
            color: #000000;
            width: 100%;

            &::placeholder {
              color: #d4d4d4;
            }
          }
        }
      }
    }
    .send_btn {
      height: 5.2rem;
      background: linear-gradient(90.35deg, #11ddff -21.76%, #ac17ff 99.96%);
      box-shadow: 0px 4px 4px rgba(132, 74, 255, 0.4);
      border-radius: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      width: calc(100% - 1rem);
      margin: 0 auto;
      font-family: 'Circe';
      .text_completed {
        font-style: normal;
        font-weight: 700;
        font-size: 1.7rem;
        line-height: 2.5rem;
        color: #ffffff;
      }
    }
  }
}
