.header_container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 2rem 2.4rem 0rem;

  .left {
    margin-left: 0.6rem;
    .img_block {
      width: 1.2rem;
      height: 2.1rem;
      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }
  }
  .center {
    display: flex;
    flex-direction: column;
    align-items: center;

    .name {
      font-style: normal;
      font-weight: bold;
      font-size: 1.8rem;
      line-height: 100%;
      text-transform: uppercase;
    }
    .logo {
      width: 15rem;
      height: 6rem;
      margin: 0 auto;
      margin-top: 1rem;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 90%;
        max-height: 90%;
        object-fit: contain;
      }
    }
    .empty_txt {
      margin-top: 3rem;
      font-style: normal;
      font-weight: normal;
      font-size: 1.6rem;
      line-height: 1.8rem;
      color: #747474;
      text-align: center;
    }
  }
  .right {
  }
}

.container {
  padding-left: 2.5rem;
  padding-right: 2.5rem;

  .prod_list_shop {
    margin-top: 1.5rem;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .category_title {
      font-family: 'Circe';
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 2.1rem;
      padding: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #ff3e6c;

      .all {
        color: #000000;
        font-size: 1.6rem;
        line-height: 1.9rem;
      }

      .title_black {
        font-style: normal;
        font-weight: bold;
        font-size: 2.4rem;
        line-height: 2.6rem;
        color: #ff3e6c;
      }
    }

    .slider {
      .swiper_slide {
        width: 50%;
      }
    }
  }
}

.flat_list {
  padding-left: 2rem;
  margin-top: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-right: 2rem;
  display: flex;
  overflow-y: auto;
  position: relative;
  left: -2.5rem;
  width: calc(100% + 5rem);
  box-sizing: border-box;
  &::-webkit-scrollbar {
    display: none;
  }
}
