.sms_code_block {
  padding: 0 2rem;

  &.without_padding {
    padding: 0;
  }

  .title {
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: #1a1a1a;
    margin-top: 1rem;
  }

  .sms_code {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;

    .number_input {
      width: 4.5rem !important;
      height: 4.5rem !important;
      border-right: none;
      line-height: 4.5rem;
      border-radius: 1rem;
      font-size: 2rem;
      background: #ffffff;
      box-shadow: 0px 4px 40px #f0f0f0;
      border: none;
      text-align: center;
      -moz-appearance: textfield;
      font-family: 'Circe';
      color: #000000;
      flex-shrink: 0;
      flex-basis: auto;
      flex-grow: 0;

      &.selected {
        caret-color: #000000;
        border: 1px solid #777777 !important;
      }

      &:focus {
        border: none;
        color: #000000;
        caret-color: #000000;
      }

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
      }

      &.wrong {
        border: 1px solid #ff3e6c !important;
      }

      &.correct {
        border: 1px solid #54be00;
      }
    }
  }

  .sms_message {
    padding-top: 1.5rem;
    font-size: 1.4rem;
    line-height: 1.6rem;
    text-align: center;
    color: #ff3e6c;
    display: none;

    &.show {
      display: block;
    }
  }

  .code_timer {
    margin-top: 2.5rem;

    .send_again_password {
      text-decoration: underline !important;
      font-size: 1.4rem;
      line-height: 1.6rem;
      color: rgb(175, 20, 255);
      margin-top: 0.5rem;
      display: none;
      width: fit-content;

      &.show {
        display: block;
      }
    }

    .timer_block {
      font-size: 1.4rem;
      line-height: 1.6rem;
      color: #b0b0b0;
      margin-top: 0.5rem;
      display: none;

      &.show {
        display: block;
      }
    }
  }
}
.sms_timer {
  margin: 0 0.5rem;
}

@media screen and (min-width: 769px) {
  a.send_again_password {
    color: #ff3e6c !important;
    margin: 0 auto;
  }
}
