.menuItem {
  font-size: 1.4rem;
  color: #000000;
  padding: 0.9rem 1.6rem 0.9rem 3rem;
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;

  &:hover {
    color: #000000;
    background: #f0f0f0;
    border-radius: 10px;
  }

  &.menuItem_active {
    background: #f0f0f0;
    border-radius: 10px;
  }
}

.menuItemMobile {
  display: block;
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-weight: bold;
  color: #1a1a1a;
  padding: 2rem 1rem;
  border-bottom: 1px solid #eaeaea;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0.8rem;
    margin: auto;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;

    border-left: 5px solid #ff004c;
  }
}
