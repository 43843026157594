.container {
  display: flex;
  flex-direction: row;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #b5b5b5;
  margin: 1.5rem 0;
}
.text {
  padding-left: 0.5rem;
  cursor: pointer;
}
