.layout {
  display: flex;
  max-width: 144rem;
  margin: 5rem auto 0;
  padding: 0 4rem 10rem;
  -webkit-font-smoothing: initial;

  &.layout_mobile {
    display: block;
    margin: 0 auto;
    // padding: 0 3rem 8rem;
    padding: 0 2rem 2rem;
    font-family: 'Circe'; // TODO: add fallback fonts
  }
}
