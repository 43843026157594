.idea_container {
  margin: 0 2rem;
  border-radius: 1rem;
  background-color: #f8f9fb;
  padding: 0 3rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.idea_container_txt {
  font-size: 1.5rem;
  line-height: 1.9rem;
  color: #767676;
}
.idea_container_red_txt {
  font-size: 1.5rem;
  line-height: 1.9rem;
  color: #ff3e6c;
}
.red_txt {
  color: #ff3e6c;
}
.btn_container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  .btn {
    width: 100%;
    height: 4.2rem;
  }
}
