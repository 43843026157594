.notification_block {
  padding: 0 3rem 3rem 3rem;
  margin-bottom: 3rem;
}
.history_content_block {
  padding: 0 3rem 3rem 3rem;
  margin-bottom: 3rem;
}

.wishlist_block {
  width: calc(100% - 5rem - 25.5rem);
  padding-left: 5rem;

  .wishlist_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5rem 0 0;
    position: relative;
    z-index: 2;
    .left {
      font-weight: bold;
      font-family: 'Circe';
      font-size: 3.2rem;
      line-height: 4.7rem;
      position: relative;
      z-index: 1;
      padding: 0 1.5rem;
      white-space: nowrap;
      &.dropdown {
        cursor: pointer;
      }
      .trigger_arrow {
        padding: 1rem;
      }
      .dropdown_menu {
        top: 5rem;
        &.opened {
          display: block;
        }
      }
    }
    .right {
      margin-left: 2rem;
      .circle_button {
        cursor: pointer;
        background: #ff3e6c;
        border-radius: 50%;
        display: block;
        width: 3.2rem;
        height: 3.2rem;
        box-shadow: 0px 4px 10px rgba(255, 0, 76, 0.42);
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-width: 1.6rem;
          max-height: 100%;
          transform: translateX(0px);
        }
      }
    }
  }
}
