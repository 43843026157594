.searchmw_content {
  padding: 3rem 0 10rem 0;

  .history_container {
    margin: 0 2rem;
    margin-top: 2.5rem;
    padding-bottom: 1rem;
    border-bottom: 0.1rem solid #eaeaea;
  }

  .search_item_predict {
    padding-top: 0.7rem;
    padding-bottom: 0.3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: #f9f9f9;
    border-radius: 0.7rem;
    overflow: hidden;
    margin-right: 1rem;
    flex-shrink: 0;
  }
  .predict_inner_container {
    padding-right: 3rem;
  }
  .predict_container {
    padding: 0 2.5rem;
    left: -2.5rem;
    position: relative;
    width: 100%;
    // width: calc(100% - 5rem);
    margin-bottom: 1.5rem;
    display: flex;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .search_item_predict_txt {
    font-size: 1.6rem;
    line-height: 1.9rem;
  }

  .see_all {
    flex-direction: row;
    display: flex;
    align-items: center;
    padding: 1.5rem;
    margin: 0 2rem;
  }
  .see_all_center {
    margin-left: 1.5rem;
  }
  .red_txt {
    color: #ff3e6c;
    font-size: 1.7rem;
    line-height: 2.5rem;
  }
  .gray_txt {
    color: #b7b7b7;
    font-size: 1.5rem;
    line-height: 2.2rem;
  }
  .card {
    padding: 1rem 2rem;
    border-radius: 1rem;
    background-color: #f9fafc;
    margin: 0 2rem;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2rem;
    display: flex;
    overflow: hidden;
  }
  .left {
    flex: 1;
  }
  .card_title {
    color: #747474;
    font-size: 1.5rem;
    line-height: 2.2rem;
  }
  .card_val {
    color: #000;
    font-size: 1.5rem;
    line-height: 2.2rem;
  }
  .card_icon {
    margin-left: auto;
  }
  .articles_container {
    border-bottom: none;
  }
}
