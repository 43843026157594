.auth_section {
  background: #ffffff;
  position: relative;
}

.login_section {
  height: 100vh;
  overflow-x: hidden;
  width: 100%;
  //   background: url(/images/icons/little-point.svg), url(/images/icons/middle-point.svg),
  //     url(/images/icons/middle-point.svg), url(/images/icons/middle-point.svg),
  //     url(/images/icons/middle-point.svg), url(/images/icons/middle-point.svg),
  //     url(/images/icons/little-point.svg), url(/images/icons/middle-point2.svg),
  //     url(/images/icons/middle-point2.svg), url(/images/icons/big-point.svg);
  //   background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat,
  //     no-repeat, no-repeat;
  //   background-position: 10% 5%, 80% 1%, 5% 30%, 30% 25%, 1% 50%, 95% 40%, 82% 30%, 10% 74%, 84% 78%,
  //     14% 98%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
  position: relative;

  .auth_logo {
    text-align: center;
    height: calc(100% - 53rem);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      display: block;
      z-index: 101;
      width: 20rem;

      &.colorful_logo {
        opacity: 1;
      }
      &.logo_white {
        opacity: 0;
        position: absolute;
      }
    }
  }
  .auth_block {
    padding: 0 3.5rem;
    width: calc(100% - 7rem);
    height: 376px;
    position: relative;

    .visible {
      display: block;
    }
    .hidden {
      display: none;
    }

    .title {
      font-weight: bold;
      font-size: 2.4rem;
      line-height: 2.8rem;
      text-align: center;
      color: #1a1a1a;
      white-space: pre-line;
    }
    .subtitle {
      font-size: 1.4rem;
      line-height: 1.6rem;
      color: #1a1a1a;
      text-align: center;
      padding: 3rem 0;
    }
  }

  .bottom_info {
    position: absolute;
    bottom: 1rem;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: #d7d7d7;
    padding-bottom: 0.8rem;
  }
}

.login_back {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  opacity: 1;
  // display: none;
  // z-index: 100;
  z-index: -9999;

  .logo_white {
    display: block;
    z-index: 101;
    width: 20rem;
    margin: 0 auto;
  }

  .background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    // background-image:url('/images/login-back.svg');
    // background-repeat: no-repeat;
    // background-position:center;
    // background-size: auto 100%;
    transform: scale(0);
    height: 100vh;
    width: 100vh;
    // border-radius:1000px;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .welcome {
    position: absolute;
    width: 100%;
    min-width: 300px;
    text-align: center;
    color: #ffffff;
    bottom: 20%;
    // left: 50%;
    transform: translateX(-50%);

    .user {
      font-weight: bold;
      font-size: 2.4rem;
      line-height: 2.8rem;
    }
    .sub_welcome {
      margin-top: 3rem;
      font-size: 1.4rem;
      line-height: 1.6rem;
      color: #f1b3e9;
    }
  }

  .bottom_info {
    position: absolute;
    bottom: 2rem;
    width: 100%;
    text-align: center;
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: rgba(255, 255, 255, 0.39);
  }
}

.registration_section {
  padding: 2rem;
  overflow: auto;
  padding-bottom: 6rem;

  .confirmation_description {
    margin: 0 2rem;
    font-size: 1.4rem;
    line-height: 2.5rem;
    color: #2d2d2d;

    .confirmation_text {
      background: #f8f8f8;
      border-radius: 1.5rem;
      padding: 0.3rem 1rem;
      white-space: nowrap;
    }
  }

  .referal_text {
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: #2d2d2d;
    padding-left: 1.5rem;
    margin-top: 3rem;
    margin-bottom: 1.5rem;

    &.offerta {
      text-align: center;
      padding: 0 1.5rem;
      margin-top: 3rem;

      a {
        color: #ab18ff;
      }
    }
  }
}
.msg {
  font-size: 1.4rem;
  line-height: 1.6rem;
  margin-top: 1rem;
  color: #ff3e6c;
  text-align: center;
  display: block;
}

.input_label {
  border: none;
  position: relative;
  border-radius: 1rem;
  padding: 2.6rem 2.5rem 1rem 2.5rem;
  background: #ffffff;
  box-shadow: 0px 4px 40px #f0f0f0;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;

  .little_text {
    position: absolute;
    top: 1.6rem;
    left: 2.5rem;
    z-index: 3;
    font-size: 1.4rem;
    color: #999999;
    transition: all 0.2s ease-in-out;
  }
}

.social {
  margin-top: 2.5rem;
  .headline {
    font-size: 1.4rem;
    line-height: 1.6rem;
    text-align: center;
    color: #787878;
  }
}
.soc_block {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  margin-top: 2rem;

  .item {
    width: 5.2rem;
    height: 5.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 1rem;
    border: none;
    outline: none;
    padding: 0;

    &.vk {
      background: #5376a4;
      img {
        width: 2.4rem;
      }
    }

    &.fb {
      background: #405896;
      img {
        width: 1rem;
      }
    }

    &.google {
      background: #ffffff;
      box-shadow: 0px 4px 40px #f0f0f0;
      img {
        width: 2rem;
      }
    }
  }
}

.btn_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;

  .btn {
    width: 27.5rem;
    height: 5rem;
    text-transform: uppercase;
  }
}

.password_recovery {
  display: block;
  margin: 0 auto;
  margin-top: 1rem;
  font-size: 1.4rem;
  line-height: 1.6rem;
  text-decoration-line: underline;
  width: fit-content;
  color: #ab18ff;
  border: none;
  background: transparent;
  cursor: pointer;
}
