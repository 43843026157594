.item {
  display: flex;
  padding: 2rem 0 2.5rem 0;
  background-color: transparent;
  border: 0;
  box-sizing: border-box;
}

.userpic {
  flex-shrink: 0;
  box-shadow: 0px 1px 16px rgba(148, 158, 183, 0.5);
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.donate {
  padding: 0 2rem;
  font-size: 1.4rem;
  line-height: 1.6rem;
  text-align: left;
  display: flex;
  flex-direction: column;

  .viewMobile & {
    padding: 0 0.6rem 0 0.9rem;
  }

  .donateTitle {
    font-weight: 500;
    color: #2d2d2d;
    margin-bottom: 1rem;
  }

  .donateUsername {
    font-size: 1.6rem;
    line-height: 1.9rem;
    font-weight: 500;
    color: #2d2d2d;
    margin-bottom: 0.4rem;
  }

  .donateUserTag {
    font-size: 1.3rem;
    line-height: 1.5rem;
    color: #747474;
    margin-bottom: 1rem;
  }

  .donateComment {
    margin-bottom: 1rem;
  }

  .donateAmount {
    font-family: 'Circe';
    font-weight: bold;
    text-align: center;
    padding: 0.6rem 1.5rem 0.4rem 1.5rem;
    border-radius: 50px;
    width: fit-content;
    background: #f6f6f7;
    color: #000;

    &.donateAmount_red {
      background: #ff3e6c;
      color: #ffffff;
    }
  }

  .donateType {
    margin-top: 1rem;
    font-size: 1.3rem;
    line-height: 1.5rem;
    color: #d0d0d0;
  }

  .createdAt {
    padding-left: 0;
  }
}

.preview {
  display: flex;
  margin-left: auto;
  flex-shrink: 0;
  width: 6rem;
  height: 6rem;
  border-radius: 4px;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 4px;
  }
}

.createdAt {
  padding-left: 1rem;
  font-size: 1.3rem;
  line-height: 1.5rem;
  color: #d0d0d0;
}
