.result_section {
  .result_content {
    max-width: 41rem;
    height: calc(100vh - 7rem);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: url('/images/icons/salut.svg'), url('/images/icons/salut-right.svg'),
      url('/images/icons/bg-star.svg'), url('/images/icons/bg-star.svg'),
      url('/images/icons/bg-star.svg'), url('/images/icons/salut-right.svg'),
      url('/images/icons/bg-star.svg'), url('/images/icons/bg-star.svg'),
      url('/images/icons/bg-left-star.svg');
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat,
      no-repeat, no-repeat;
    background-position: 0 30%, 100% 0, 80% 0, 95% 38%, 105% 80%, 110% 55%, 20% 90%, 70% 95%,
      10% 10%;
    background-size: auto, auto, 11px 11px, auto, 50px 50px, 53px 53px, 26px 26px, 15px 15px, auto;
    &.error {
      background: #ffffff;
    }
    &.result_content_fd {
      height: calc(100vh - -20rem);
      justify-content: unset;
    }
  }

  .title {
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 2.8rem;
    margin-top: 3.5rem;
    color: #000000;
    text-align: center;
  }

  .subtitle {
    max-width: 25rem;
    text-align: center;
    font-size: 1.3rem;
    line-height: 1.8rem;
    margin-top: 1.5rem;
    color: #000000;
  }

  .orderLink {
    color: #ff004c;
    font-size: 1.3rem;
    font-weight: 700;
    font-family: 'Circe';
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    img {
      width: 0.6rem;
      padding-left: 0.5rem;
    }
  }

  .sum {
    font-style: normal;
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 2.8rem;
    margin-top: 1.5rem;
    color: #ff3e6c;
    text-align: center;
    background: transparent;
  }

  .comment {
    background: #f9f9f9;
    border-radius: 1rem;
    padding: 2rem;
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 1.6rem;
    margin: 2rem 3rem 0 2.5rem;
    color: #000000;
    max-width: 40rem;
    text-align: center;
  }

  .app {
    margin-bottom: 1rem;
    margin-top: 3.5rem;
    .app_content {
      padding: 2.3rem;
      background: #ffffff;
      box-shadow: 0px 4px 40px #f0f0f0;
      border-radius: 20px;

      .app_title {
        margin-bottom: 2rem;
        font-style: normal;
        font-weight: bold;
        font-size: 1.8rem;
        line-height: 2.7rem;
        text-align: center;
        color: #9a9a9a;
      }

      .android_ios {
        display: flex;

        .item {
          width: 9.3rem;
          height: 3rem;
          &.item1 {
            margin-right: 1rem;
          }
          .img {
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }

  .success_for {
    display: flex;
    align-items: center;
    margin-top: 2.5rem;

    > div {
      font-size: 1.4rem;
    }

    .user_block {
      background: #f9f9f9;
      border-radius: 2rem;
      padding: 0.7rem 1.5rem;
      margin-top: 0;
      margin-left: 1rem;
      display: flex;
      align-items: center;

      img {
        width: 2.2rem;
        height: 2.2rem;
        border-radius: 50%;
        box-shadow: 0px 1px 16px rgba(148, 158, 183, 0.5);
      }

      .user_name {
        margin-left: 1rem;
        font-size: 1.4rem;
        line-height: 1.4rem;
        max-width: 20rem;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .ok_button {
    width: 5.4rem;
    height: 5.4rem;
    background: #ff3e6c;
    border-radius: 50%;
    border: none;
    margin-top: 6rem;
    box-shadow: 0px 4px 10px rgba(255, 0, 76, 0.42);
    transform: translateY(-2rem);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      position: relative;
      left: 0.1rem;
      top: 0.2rem;
      flex-shrink: 0;
    }
  }

  .prevlink {
    color: #ff004c;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;

    img {
      padding-right: 1rem;
    }
  }
  .first_donat {
    width: 30rem;
    font-family: 'Circe';
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.6rem;
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 2.5rem;
    margin-bottom: 3rem;
  }

  .show_more {
    display: inline-block;

    .show_more_btn {
      color: #5e5e5e;
      font-size: 1.6rem;
      line-height: 2.4rem;
      text-decoration: none;
      background: #ffffff;
      height: 4.2rem;
      width: 23rem;
      display: flex;
      justify-content: center;
      box-shadow: 0px 4px 40px #f0f0f0;
      border-radius: 30px;
      align-items: center;
      transition: 0.3s ease-in-out;

      &:hover {
        color: #ffffff;
        background: #ff3e6c;
      }
    }
  }

  .product_mob {
    display: flex;
    align-items: center;
    width: 34rem;
    height: 6rem;
    border-radius: 10px;
    box-shadow: 0px 4px 40px #f0f0f0;
    margin-bottom: 1.2rem;
    position: relative;
    background-color: #ffffff;
    .img_container {
      margin-left: 1.6rem;
      width: 3.5rem;
      height: 3.5rem;
      flex-shrink: 0;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .product_info {
      margin-right: auto;
      margin-left: 1.5rem;
      .product_title {
        font-style: normal;
        font-weight: normal;
        font-size: 1.4rem;
        color: #000000;
        text-align: left;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 20rem;
      }

      .product_price {
        font-style: normal;
        font-weight: bold;
        font-size: 1.3rem;
        line-height: 1.5rem;
        color: #0e0e0e;
        text-align: left;
        max-width: 20rem;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .add_btn_img {
      width: fit-content;
      position: absolute;
      right: 2rem;
      z-index: 3;
      cursor: pointer;

      svg {
      }
    }
  }
}

@media screen and (min-width: 769px) {
  .result_section {
    .result_content {
      height: auto;
      background: transparent;
      &.result_content_fd {
        height: calc(100vh - 7rem);
        justify-content: center;
      }

      &.error {
        background: transparent;
      }

      .prevlink {
        cursor: pointer;
        font-size: 1.6rem;
      }

      .success_img {
        margin-top: 5rem;
      }
    }

    .sum {
      background: #ff3e6c;
      border-radius: 50px;
      padding: 0.6rem 1.5rem 0.5rem;
      color: #ffffff;
      margin-bottom: 0;
    }

    .comment {
      background: transparent;
    }

    .first_donat {
      font-family: 'Circe';
      font-style: normal;
      font-weight: bold;
      font-size: 2rem;
      line-height: 160%;
      text-align: center;
      color: #000000;
      margin-bottom: 3.5rem;
      width: 100%;
      margin-top: 5.5rem;
    }
    .card_container {
      display: flex;

      .product_item {
        width: calc(25% - 2rem);
        margin-right: 1.5rem;
        height: 23rem;
        min-width: 16rem;
        margin-bottom: 0rem;
      }
    }
    .show_more {
      margin-top: 3rem;
    }
  }
}
