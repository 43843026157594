.login_section {
  height: 100vh;
  overflow-x: hidden;
  width: 100%;
  //   background: url(/images/icons/little-point.svg), url(/images/icons/middle-point.svg),
  //     url(/images/icons/middle-point.svg), url(/images/icons/middle-point.svg),
  //     url(/images/icons/middle-point.svg), url(/images/icons/middle-point.svg),
  //     url(/images/icons/little-point.svg), url(/images/icons/middle-point2.svg),
  //     url(/images/icons/middle-point2.svg), url(/images/icons/big-point.svg);
  //   background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat,
  //     no-repeat, no-repeat;
  //   background-position: 10% 5%, 80% 1%, 5% 30%, 30% 25%, 1% 50%, 95% 40%, 82% 30%, 10% 74%, 84% 78%,
  //     14% 98%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  .reg_container {
    .title {
      font-size: 2rem;
      line-height: 2.2rem;
      margin-bottom: 2rem;
      text-align: left;
    }
    .bday_input {
      margin-bottom: 1.5rem;
    }
    .msg {
      font-size: 1.4rem;
      line-height: 1.6rem;
      margin-top: 1.5rem;
      color: #ff3e6c;
      text-align: center;
      display: block;
    }
    .btn_container {
      margin-top: 1.5rem;
      .btn {
        margin: 0 auto;
        width: calc(100% - 5rem);
        box-shadow: none;
        align-items: center;
        display: flex;
        justify-content: center;
        font-size: 1.8rem;
        line-height: 2.1rem;
        text-transform: none;
        font-weight: bold;
        font-family: 'Circe';
        background: #ff3e6c;
        border-radius: 3rem;
        height: 5rem;
        border: none;
        color: #ffffff;
      }
    }
  }
}
