.container {
  background: #6738b3;
  border-radius: 10px;
  margin-bottom: 3rem;
  .wrapper {
    padding: 2.5rem 2.5rem 3.2rem;

    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 2rem;
      line-height: 120%;
      color: #ffffff;
      margin-bottom: 2.1rem;

      span {
        opacity: 0.7;
      }
    }
    .list {
      .empty_ideas {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.6rem;
        padding: 2rem;
        color: #ffffff;
      }
    }
    .search {
      display: flex;
      justify-content: center;
      align-items: center;
      outline: none;
      border: none;
      height: 5.3rem;
      width: 100%;
      box-sizing: border-box;
      border: 1px solid #ffffff;
      border-radius: 7px;
      background: #6738b3;
      .icon {
        margin-right: 1rem;
      }
      .btn_text {
        font-style: normal;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 2.2rem;
        color: #ffffff;
      }
    }
  }
}
