.container {
  display: flex;
  svg {
    width: 2rem;
    path {
      transition: all 0.3s ease;
    }
  }
  span {
    padding-left: 0.5rem;
    transition: all 0.3s ease;
  }
  &:hover {
    svg {
      path {
        fill: #ff3e6c;
      }
    }
    span {
      color: #ff3e6c;
    }
  }
}
