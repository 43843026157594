.wish_background {
  background: #fff;
  padding: 0.2rem;
  box-shadow: 0px 0.4rem 4rem #f0f0f0;
  margin-top: 1.5rem;
  cursor: pointer;
  border-radius: 1rem;
  &.main {
    background: linear-gradient(
      258.32deg,
      #aa11ff -5.28%,
      #5e78ff 28.81%,
      #2cbaff 66.74%,
      #0be0ff 100.28%
    );
  }
}

.main_wish_mobile {
  background: #ffffff;
  border-radius: 0.8rem;
  display: -webkit-box;
  display: flex;
  padding: 2rem;
  width: calc(100% - 4rem);
  box-shadow: 0px 0.4rem 4rem #f0f0f0;
  position: relative;
  flex-direction: column;
  .idea_container {
    display: flex;

    .idea_info {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: calc(100% - 8rem);
      margin-left: 1.5rem;
      margin-bottom: 1.5rem;
      .name {
        font-family: 'Circe';
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 1.9rem;
        color: #000000;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 0.4rem;
      }

      .status {
        font-family: 'Circe';
        font-style: normal;
        font-weight: normal;
        font-size: 1.3rem;
        line-height: 1.5rem;
        color: #747474;
      }
    }

    .img_block {
      width: 3rem;
      height: 3rem;
      margin-left: auto;

      img {
        border-radius: 100px;
        // object-fit: cover;
        width: 3rem;
        height: 3rem;
      }
    }
  }
  .idea_user {
    background: #f8f9fb;
    border-radius: 10px;
    padding: 1.6rem 1rem 1.6rem 1.6rem;
    .top_container {
      display: flex;
      align-items: center;

      .img_block {
        width: 3rem;
        height: 3rem;

        img {
          border-radius: 100px;
          object-fit: cover;
          width: 3rem;
          height: 3rem;
        }
      }
      .nickname {
        font-family: 'Circe';
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.6rem;
        padding-left: 1rem;
        color: #000000;

        span {
          color: #bcbdbf;
          margin-left: 0.8rem;
        }
      }
    }
    .comment {
      font-size: 1.4rem;
      line-height: 1.6rem;
      margin-top: 1rem;
      color: #000000;
      position: relative;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  .bottom_container {
    margin-top: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // flex-direction: column;
    .donate_button_block {
      // margin-left: 1.5rem;
      .do_donate {
        margin: 0;
        display: flex;
        margin-right: 0rem;
        height: 3.5rem;
        align-items: center;
        justify-content: center;
        padding: 0;
        text-transform: none;
        font-size: 1.6rem;
        line-height: 2.4rem;
        font-weight: bold;
        font-family: 'Circe';
        width: 15.5rem;
        background: #ff3e6c;
        box-shadow: 0px 4px 10px rgba(255, 0, 76, 0.42);
        color: #ffffff;
        border-radius: 3rem;
        border: none;

        img {
          padding-right: 1rem;
          transform: translateY(-0.1rem);
          width: 2rem;
        }

        &.get_wish {
          background: linear-gradient(90.35deg, #11ddff -21.76%, #ac17ff 99.96%);
          box-shadow: 0px 4px 4px rgba(132, 74, 255, 0.4);
        }
      }
    }
    .stat_block {
      display: flex;
      margin-top: 0;

      .comments {
        margin-right: 0.5rem;
      }
    }
  }
  .bottom_container {
    display: flex;
    .stat_block {
      position: relative;
      bottom: 0;
      right: 0;
      font-size: 1.5rem;
      > div,
      a {
        margin-right: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        span {
          padding-left: 0.5rem;
        }
      }

      .comments {
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: 374px) {
  .bottom_container {
    flex-direction: column;
    .stat_block {
      position: relative;
      bottom: 0;
      right: 0;
      margin-top: 1rem !important;
      > div {
        margin-right: 1.5rem;
      }

      .comments {
        margin-right: 0;
      }
    }
  }
}
