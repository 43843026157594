.title {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  display: flex;
  margin: 0 2.5rem;
}
.categ_name {
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #ff3e6c;
}
.show_all {
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #000;
}
.list {
  padding: 2.5rem 0.5rem 3rem 2.5rem;
  flex-direction: row;
  display: flex;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}
.product {
  margin-right: 2rem;
}
.more_card {
  width: 15.5rem;
  height: 25rem;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  background-color: #ff3e6c;
  border-radius: 1rem;
  margin-left: 2rem;
  padding: 0 1rem;
  flex-shrink: 0;
}
.more_card_title {
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #fff;
  text-align: center;
}
.more_card_subtitle {
  margin-top: 2rem;
  text-align: center;
  font-size: 1.3rem;
  line-height: 1.6rem;
  color: #fff;
}
.more_card_svg {
  margin-top: 2rem;
}
