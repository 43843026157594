.container {
  flex: 1;
  padding-left: 5rem;

  &.viewMobile {
    padding: 0 2rem;
  }
}

.title {
  font-size: 3.2rem;
  line-height: 4.7rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.empty {
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #a0a0a0;
  text-align: center;
  padding-top: 5.2rem;
}

.orders {
  display: flex;
  flex-direction: column;
}
