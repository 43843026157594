.container {
  position: relative;
}
.selected_container {
  width: 100%;
  box-sizing: border-box;
  padding: 1.5rem 3rem;
  box-shadow: 0 0.4rem 4rem #f0f0f0;
  border-radius: 1rem;
  position: relative;
  display: flex;
  align-items: center;
  min-height: 5.8rem;
  cursor: pointer;
  margin: 1.5rem 0;
  &.wrong {
    border: 0.1rem solid #ff3e6c;
  }
}
.trigger {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  border-top-style: none;
  border-left-style: none;
  border-right: 0.1rem #2b2b2b solid;
  border-bottom: 0.1rem #2b2b2b solid;
  border-bottom-right-radius: 25%;
  transform: rotate(45deg);
  height: 0.8rem;
  width: 0.8rem;
  margin-left: 1rem;
}
.title {
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #999999;
}
.value {
  color: #000;
  font-size: 1.4rem;
  line-height: 1.8rem;
  margin-left: auto;
}

.list {
  position: absolute;
  right: 0;
  width: 100%;
  top: 6rem;
  z-index: 2;
  display: none;
  background: #ffffff;
  border-radius: 1rem;

  &.opened {
    display: block;
  }
  .list_container {
    background: transparent;
    padding: 1rem 0;
    box-sizing: border-box;
    box-shadow: 0 0.4rem 4rem #f0f0f0;
    overflow: auto;
    max-height: 16rem;
  }

  .item_name {
    font-size: 1.5rem;
    cursor: pointer;
    line-height: 1.8rem;
    transition: all 0.2s ease-in-out;
    padding: 0 3.5rem;
    height: 4rem;
    background: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000;
    display: flex;
    align-items: center;
    &:hover {
      background: #f8f9fb;
    }
    &.active {
      background: #f8f9fb;
    }
    .item_title {
      width: 5rem;
      &.full_width {
        width: auto;
      }
    }
  }
  .red_trigger {
    margin-left: 1rem;
    svg {
      width: 0.8rem;
      transition: all 0.3s ease;
    }
    &.opened {
      svg {
        transform: rotate(90deg);
      }
    }
  }
  .sub_item {
    font-size: 1.5rem;
    cursor: pointer;
    line-height: 1.8rem;
    transition: background 0.2s ease-in-out;
    padding: 0 3.5rem 0 5rem;
    height: 4rem;
    background: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000;
    display: flex;
    align-items: center;
    &.active {
      background: #f8f9fb;
    }
    &:hover {
      background: #f8f9fb;
    }
  }
  .select_icon {
    margin-left: auto;
    width: fit-content;
    img {
      width: 2rem;
    }
  }
}
