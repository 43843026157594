.history_item {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #eaeaea;
  padding: 2.5rem 0;
  color: #000000;

  .left_half {
    width: 3.6rem;
    flex-shrink: 0;
    position: relative;

    &.friend_accepted {
      &:after {
        // background-image: url(/images/icons/notification-icons/white-user-icon.svg);
        background-size: 1rem 1rem;
        background-position: center;
        background-repeat: no-repeat;
        content: '';
        position: absolute;
        width: 2rem;
        height: 2rem;
        z-index: 3;
        left: 0;
      }
      &:before {
        content: '';
        position: absolute;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        background: #00b5f1;
        background-repeat: no-repeat;
        z-index: 3;
      }
    }

    img {
      width: 3.6rem;
      height: 3.6rem;
      box-shadow: 0px 1px 16px rgba(148, 158, 183, 0.5);
      border-radius: 50%;
      object-fit: cover;
      position: relative;

      &.not_user {
        object-fit: initial;
        border-radius: 50%;
        box-shadow: none;
        img {
          border-radius: 50%;
          height: auto;
        }
      }
    }
  }

  .center {
    padding-left: 2rem;
    overflow: hidden;
    color: #000000;
  }

  .right_half {
    width: fit-content;
    margin-left: auto;
    flex-shrink: 0;
    display: flex;

    img {
      max-width: 6rem;
      max-height: 6rem;
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: #2d2d2d;

    .time {
      color: #d0d0d0;
      font-weight: normal;
      margin-left: auto;
      padding-left: 1rem;
    }
  }

  .time {
    color: #d0d0d0;
    font-weight: normal;
    margin-left: auto;
  }

  .message {
    margin-top: 0.5rem;
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  .sum {
    margin-top: 0.5rem;
    border-radius: 50px;
    padding: 0.3rem 0.8rem;
    font-style: normal;
    font-weight: bold;
    font-size: 1.3rem;
    line-height: 1.5rem;
    display: flex;
    align-items: center;
    width: fit-content;
    color: #ffffff;
    img {
      padding-right: 0.5rem;
    }
  }

  .donate-type {
    font-size: 1.3rem;
    line-height: 1.5rem;
    color: #d0d0d0;
    margin-top: 0.7rem;
  }
}

@media screen and (max-width: 768px) {
  .history_item {
    .center {
      padding-left: 1rem;

      .message {
        font-size: 1.3rem;
      }
    }
    .right_half {
      flex-direction: column-reverse;
      justify-content: center;
      img {
        max-width: 4rem;
        max-height: 4rem;
        margin-top: 1.5rem;
        margin-left: auto;
        display: block;
      }
    }
  }
}
