.item {
  flex-direction: row;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  margin-top: 0.5rem;
  color: #000;
}
.img_block {
  width: 4.5rem;
  height: 4.5rem;
}
.img {
  width: 4.5rem;
  height: 4.5rem;
  object-fit: contain;
}
.center {
  padding-left: 1rem;
}
.name {
  font-size: 1.6rem;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 2.2rem;
}
.bold {
  font-size: 1.4rem;
  line-height: 1.8rem;
  margin-top: 0.3rem;
  font-weight: bold;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.gray {
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #b1b1b1;
  margin-top: 0.3rem;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
