.input_block {
  margin-top: 1.5rem;
}
.without_margin {
  padding: 1rem 0;
}
.input_block_inner {
  flex-direction: row;
  display: flex;
  border-radius: 1rem;
  height: 6rem;
  padding: 0 2rem;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0 0.4rem 4rem #f0f0f0;
}
.bold_txt {
  //   font-weight: bold;
  font-size: 1.3rem;
  line-height: 1.8rem;
  color: #999999;
}
.black_txt {
  color: #000;
}
.rounded_inner {
  border-radius: 5rem;
}
.selected_val {
  margin-left: auto;
  flex-direction: row;
  align-items: center;
  max-width: 60%;
  display: flex;
}
.trigger {
  padding-left: 1rem;
  svg {
    width: 1.2rem;
    height: auto;
  }
}
.sel_txt {
  font-size: 1.4rem;
  padding-left: 1rem;
}
