.btn_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .btn {
    width: 26.5rem;
    height: 5rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

.msg {
  font-size: 1.4rem;
  line-height: 1.6rem;
  margin-top: 1rem;
  color: #ff3e6c;
  text-align: center;
  display: block;
}
.input_phone {
  border: none !important;
  box-shadow: 0 0.4rem 4rem #f0f0f0;
}
