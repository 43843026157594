.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search_product {
  padding: 0 2rem;
  width: calc(100% - 4rem);
  z-index: 5;
  position: relative;

  .search_block {
    position: relative;
    background: #ffffff;
    border-radius: 0.7rem;
    border: 1px solid #f0f0f0;
    padding: 0.8rem 1rem 0.8rem 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    input {
      width: 100%;
      padding: 0.7rem 0;
      margin-right: 1rem;
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: #000000;
      border: none;
      z-index: 1;
      font-family: 'Circe';
      &::placeholder {
        color: #999999;
      }
    }
    &.withBefore {
      padding: 0.8rem 1rem 0.8rem 2rem;
      input {
        margin-left: 1rem;
      }
    }
    button {
      background: #ff3e6c;
      box-shadow: 0 0.4rem 1rem rgba(255, 0, 76, 0.42);
      border: none;
      border-radius: 50%;
      color: #fff;
      outline: none;
      width: 3rem;
      height: 3rem;
      padding: 0;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .filter {
    margin-right: 2rem;
    align-items: center;
    justify-content: center;
    display: flex;
    svg: {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
}
.back_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 1.4rem;
    height: 2.2rem;
  }
}
