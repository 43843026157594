.container {
  height: auto;
  width: 45rem;
  padding: 5rem 2rem 3rem 2rem;
  overflow-y: auto;
}
.headline {
  font-family: 'Circe';
  font-weight: bold;
  font-size: 3.2rem;
  line-height: 4.7rem;
  text-align: center;
  color: #0a0a0a;
}
.title {
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: #000;
  margin-top: 2rem;
  span {
    color: #ff3e6c;
  }
}
.item {
  background: #ffffff;
  box-shadow: 0 0.4rem 4rem #f0f0f0;
  border-radius: 2rem;
  padding: 2rem 2.5rem;
  padding-right: 4.5rem;
  position: relative;
  margin-top: 2rem;
  cursor: pointer;
  &.selected {
    background: #f9f9f9;
    box-shadow: none;
  }
}
.name {
  color: #1a1a1a;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.6rem;
}
.address {
  font-size: 1.4rem;
  line-height: 1.6rem;
  margin-top: 0.5rem;
  color: #1a1a1a;
}
.days {
  font-size: 1.4rem;
  line-height: 1.6rem;
  margin-top: 0.5rem;
  color: #acacac;
}
.selected_icon {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 2rem;
}

@media screen and (max-width: 768px) {
  .container {
    width: auto;
    padding: 0 2rem 3rem 2rem;
    overflow-y: auto;
  }
  .headline {
    display: none;
  }
}
