.inner_content {
  //   display: flex;
  padding: 0 2rem;
  font-family: 'Circe';

  .photo_wrapper {
    display: flex;
    justify-content: space-around;
    padding: 1.2rem 0 1.9rem;
    .camera_btn,
    .gallery_btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      .img_block {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 7rem;
        height: 7rem;
        background: #f9fafc;
        border-radius: 50%;
        margin-bottom: 1.1rem;

        img {
        }
      }
      .text {
        font-style: normal;
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        color: #747474;
      }
      input {
        display: none;
      }
    }
  }
}
