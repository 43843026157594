.main_wish_container {
  padding: 1rem 2.5rem 2.5rem;
  display: block;

  .wish_background {
    background: linear-gradient(
      258.32deg,
      #aa11ff -5.28%,
      #5e78ff 28.81%,
      #2cbaff 66.74%,
      #0be0ff 100.28%
    );
    padding: 0.2rem;
    border-radius: 1rem;
    box-shadow: 0px 0.4rem 4rem #f0f0f0;
    margin-top: 0.5rem;
    cursor: pointer;

    .main_wish {
      background: #ffffff;
      border-radius: 0.8rem;
      display: flex;
      flex-direction: column;
      padding: 2rem 2rem 1.6rem;
      width: calc(100% - 4rem);
      min-height: 8rem;
      position: relative;

      .fire_icon {
        position: absolute;
        top: -1rem;
        right: -1rem;
        width: 3.2rem;
        height: 3.2rem;
        background: #8740ff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 1.05rem;
          height: 1.35rem;
        }
      }

      .wish {
        display: flex;
        margin-bottom: 2rem;
        .left {
          flex-shrink: 0;
          width: 7.8rem;
          height: 7.8rem;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
          }
        }
        .right {
          margin-left: 2rem;
          overflow: hidden;
          .name {
            color: #000000;
            font-weight: 600;
            // white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 1.7rem;
            line-height: 1.9rem;
            margin-bottom: 0.4rem;

            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
          .status {
            display: flex;
            align-items: center;
            .progress_container {
              flex-shrink: 0;
              width: 2.1rem;
              height: 2.1rem;
              position: relative;

              img {
                width: 1.05rem;
                height: 1.05rem;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: block;
              }
            }
            .status_text {
              font-size: 1.5rem;
              line-height: 1.7rem;
              color: #747474;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              margin-left: 0.8rem;
            }
          }
        }
      }
      .bottom_container {
        .donatesum_variants {
          display: flex;
          align-items: center;

          .donate_button_block {
            width: 100%;
            button,
            a {
              margin: 0;
              display: flex;
              height: 5.2rem;
              align-items: center;
              justify-content: center;
              padding: 0;
              text-transform: none;
              font-size: 1.5rem;
              line-height: 2.2rem;
              font-weight: bold;
              font-family: 'Circe';
              width: 100%;
              background: #ff3e6c;
              box-shadow: 0px 4px 10px rgba(255, 0, 76, 0.42);
              color: #ffffff;
              border-radius: 0.7rem;
              border: none;

              img {
                padding-right: 1rem;
                transform: translateY(-0.1rem);
                width: 2.6rem;
              }

              &.get_wish {
                background: linear-gradient(90.35deg, #11ddff -21.76%, #ac17ff 99.96%);
                box-shadow: 0px 4px 4px rgba(132, 74, 255, 0.4);
              }
            }
          }
        }
      }
    }
  }
}
