.container {
    background: #F5F5F7;
    .inner_content {
        padding: 13rem 14.6rem 11rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 144rem;
        // width: 100%;
        margin: 0 auto;

        .wish_info {
            display: flex;
            justify-content: space-between;
            margin-bottom: 6rem;

            .left {
                font-style: normal;
                font-weight: 600;
                font-size: 4.8rem;
                line-height: 130%;
                color: #1D1D1F;
                width: calc(50% - 3.5rem);
            }
            .right {
                font-style: normal;
                font-weight: 600;
                font-size: 2.1rem;
                line-height: 150%;
                color: #86868B;
                width: calc(50% - 3.5rem);
            }
        }
        .users {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 4rem;

            .user_pic {
                flex-shrink: 0;
                width: 7rem;
                height: 7rem;
                border-radius: 50px;
                margin-right: 0.9rem;
                margin-left: 0.9rem;
                object-fit: cover;

                img {
                    max-width: 100%;
                    max-height: 100%;
                    object-fit: cover;
                    border-radius: 50px;
                }

                &.user_pic_active {
                    transform: scale(1.15);
                    transition: all 0.3s ease-in-out;
                    border: 2px solid #FF3E6C;
                }
            }
        }
        .title {
            font-style: normal;
            font-weight: bold;
            font-size: 3.6rem;
            line-height: 150%;
            display: flex;
            align-items: flex-end;
            text-align: center;
            color: #000000;
            margin-bottom: 1rem;
        }
        .nickname_link {
            font-style: normal;
            font-weight: bold;
            font-size: 2.4rem;
            line-height: 100%;
            display: flex;
            align-items: flex-end;
            text-align: center;
            color: #868686;
            margin-bottom: 3.5rem;
        }
    }
}

.wishlist {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 54.5rem;
}

.link_cart {
    outline: none;
    width: calc(100% - -1rem);
}
.main {
    border-radius: 10px;
    padding: 1.15rem 2rem;
    height: 9.8rem;
}
.main_desk {
    height: 5.8rem;
}
.circle_stat {
    width: 5rem !important;
    height: 5rem !important;
    flex-shrink: 0;
}

.wish_name {
    font-style: normal;
    font-weight: bold;
    font-size: 3.6rem !important;
    line-height: 5.3rem !important;
    color: #000000;
}

.price {
    font-style: normal;
    font-weight: normal !important;
    font-size: 2.4rem !important;
    line-height: 3.5rem !important;
    color: #747474 !important;
}

.wish_img {
    width: 6rem !important;
    height: 6rem !important;
    flex-shrink: 0;
}
.svg_img {
    width: 5rem !important;
    height: 5rem !important;

    svg {
        width: 5rem !important;
        height: 5rem !important;
    }
}

.text_wish {
    margin-left: 2rem;
}



@media screen and (max-width: 768px) {
    .container {
        background: #ffffff;
        .inner_content {
            padding: 5rem 3rem 5rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: unset;
    
            .wish_info {
                display: flex;
                justify-content: space-between;
                margin-bottom: 5rem;
                flex-direction: column;
    
                .left {                  
                    font-size: 3.2rem;
                    line-height: 130%;                 
                    width: 100%;
                    margin-bottom: 2rem;
                }
                .right {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 1.7rem;
                    line-height: 130%;
                    color: #868686;
                    width: 100%;
                }
            }
            .users {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 2.5rem;
    
                .user_pic {
                    flex-shrink: 0;
                    width: 5rem;
                    height: 5rem;
                    border-radius: 50px;
                    margin-right: 0.9rem;
                    margin-left: 0.9rem;
    
                    img {
                        max-width: 100%;
                        max-height: 100%;
                        object-fit: cover;
                        border-radius: 50px;
                    }
    
                    &.user_pic_active {
                        border: 2px solid #FF3E6C;
                        transform: scale(1.25);
                        transition: all 0.3s  ease-in-out;
                    }
                }
            }
            .title {
                font-style: normal;
                font-weight: bold;
                font-size: 2.1rem;
                line-height: 150%;
                margin-bottom: 1.5rem;
                display: flex;
                align-items: flex-end;
                text-align: center;
                color: #000000;
            }
            .nickname_link {
                margin-bottom: 2.5rem;
                font-size: 1.8rem;
            }
        }
    }
    .wishlist {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 105%;
    }
    
    .main {
        border-radius: 10px;
        padding: 1.15rem 2rem;
        height: 4.8rem;
    }
    
    .text_wish {
        margin-left: 1rem;
    }
    // .text_over {
    //     overflow: hidden;
    //     white-space: nowrap;
    //     text-overflow: ellipsis;
    // }
}