.modal_window_desk {
  width: 41rem;
  height: fit-content;
  background: #ffffff;
  border-radius: 2rem;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  position: absolute;
  padding-bottom: 3rem;
}

.delete_content {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-image: url(/images/icons/salut.svg), url(/images/icons/salut-right.svg),
    url(/images/icons/bg-star.svg), url(/images/icons/bg-star.svg), url(/images/icons/bg-star.svg),
    url(/images/icons/salut-right.svg), url(/images/icons/bg-star.svg),
    url(/images/icons/bg-star.svg), url(/images/icons/bg-left-star.svg);
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat,
    no-repeat, no-repeat;
  background-position: 0 30%, 100% 0, 80% 0, 95% 38%, 105% 80%, 110% 55%, 20% 90%, 70% 95%, 10% 10%;
  background-size: auto, auto, 11px 11px, auto, 50px 50px, 53px 53px, 26px 26px, 15px 15px, auto;

  .delete_img {
    width: 7.8rem;
    height: 7rem;
    margin-top: 3rem;
  }

  .title {
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 2.8rem;
    color: #000000;
    padding: 0 3rem;
    text-align: center;
    margin-top: 4rem;
  }
  .subtitle {
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: #000000;
    margin-top: 3rem;
    padding: 0 3.5rem;
    text-align: center;
  }
}

.btn_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.select_buttons_block {
  margin-top: 4rem;
  text-align: center;

  .select_now {
    button {
      width: 25.5rem;
      height: 5rem;
      box-shadow: 0px 4px 10px rgba(255, 0, 76, 0.2);
      border-radius: 3rem;
      background: #ff3e6c;
      font-size: 1.8rem;
      line-height: 2.1rem;
      color: #ffffff;
      border: none;
      font-family: 'Circe';
    }

    .loading {
      opacity: 0.5;
      img {
        width: 1.4rem;
      }
    }
  }
  .delete_now {
    margin-top: 2rem;
    button {
      width: 25.5rem;
      height: 5rem;
      box-shadow: 0px 4px 10px rgba(200, 200, 200, 0.2);
      border-radius: 3rem;
      background: #ffffff;
      font-size: 1.8rem;
      line-height: 2.1rem;
      color: #000000;
      border: none;
    }
  }
  .select_later {
    margin-top: 2.8rem;
    button {
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 2.1rem;
      color: #9a9a9a;
      background: transparent;
      border: none;
      padding: 0;
    }
  }
}

.category_select {
  padding: 2rem;

  .title {
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 2.8rem;
    color: #000000;
    padding: 0 3rem;
    text-align: center;
    margin-bottom: 2rem;
  }

  .select_item {
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: #1a1a1a;
    padding: 1.2rem 1.5rem;
    border-radius: 15px;
    cursor: pointer;

    &.selected {
      background: #f9f9f9;
      background-image: url('/images/icons/red-big-accept.svg');
      background-repeat: no-repeat;
      background-size: 1.6rem auto;
      background-position: calc(100% - 2rem);
    }
  }
}
