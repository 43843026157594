.container {
  .inner_container {
    position: relative;
    border-radius: 1rem;
    padding: 1.5rem 2.5rem 1rem 2.5rem;
    background: #ffffff;
    box-shadow: 0px 0.4rem 4rem #f0f0f0;
    margin-bottom: 1.5rem;
    transition: 0.3s ease-in-out;
    .title {
      font-size: 1.4rem;
      color: #999999;
    }
    .input_container {
      display: flex;
      align-items: center;
      padding: 0rem;
      .flag {
        width: 3rem;
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          max-width: 100%;
          width: 2.5rem;
          max-height: 100%;
          object-fit: cover;
        }
      }
      .user_img {
        width: 3rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: cover;
        }
      }
      .input {
        border: none;
        outline: none;
        margin-left: 1rem;
      }
    }
    .loader {
      padding: 0;
      margin: 1.5rem auto;
    }
    .selected_user_block {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(-50%, -50%);
      width: 4rem;
      height: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .user_container {
      height: 26rem;
      overflow-y: scroll;
      overflow-x: hidden;
      margin: 0;
    }
  }
}

.user_item {
  display: flex;
  align-items: center;
  padding: 1rem 0rem;
  border-bottom: 1px solid lavender;

  .user_img_container {
    width: 4.5rem;
    height: 4.5rem;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .user_img {
      max-width: 100%;
      max-height: 100%;
      width: 4.5rem;
      height: 4.5rem;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  .user_info {
    margin-left: 1rem;
    .user_name {
      font-size: 1.6rem;
      line-height: 1.8rem;
      color: #2d2d2d;
      margin-bottom: 0.3rem;
    }
    .user_teg {
      font-size: 1.4rem;
      line-height: 1.6rem;
      color: #999999;
    }
  }
}
