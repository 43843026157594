.settings_content {
    padding: 2rem;

    .item {
        background: #FFFFFF;
        box-shadow: 0px 4px 40px #F0F0F0;
        border-radius: 2rem;
        height: 5.2rem;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        align-items: center;
        font-style: normal;
        font-weight: normal;
        font-size: 1.4rem;
        line-height: 1.6rem;
        color: #1A1A1A;
        padding: 0 2.4rem;
        margin-bottom: 1.5rem;

        .title{
            &.trash {
                color: #FF3E6C;
            }
        }

        img {
            max-width: 2.5rem;
            display: block;
            margin-left: auto;
        }
    }
}