.container {
  margin-top: 2rem;
  border-bottom-color: #f8f9fb;
  border-bottom-style: solid;
  border-bottom-width: 0.8rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
.shop_content {
  position: relative;
}
.shop_list {
  padding-top: 6rem;
}
.search_bar_container {
  position: absolute;
  width: 100%;
}
.search_bar {
  border-radius: 5rem;
  background-color: #fff;
  overflow: hidden;
  border-color: #eaeaea;
  border-width: 1rem;
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  margin: 1rem 1rem 0 1rem;
  padding: 0.8rem 2rem;
}
.search_txt {
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #ccc;
}
.icon_container {
  width: 3rem;
  height: 3rem;
  border-radius: 5rem;
  background-color: #ff3e6c;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.flat_list {
  padding-left: 2rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  display: flex;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
.shop_flat_list {
  padding-left: 2rem;
  margin-top: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-right: 2rem;
  display: flex;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
.title_container {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.red_title {
  padding: 0 2rem;
  color: #ff3e6c;
  font-size: 1.8rem;
  line-height: 2.1rem;
  font-weight: bold;
  flex: 1;
}
.black_title {
  font-size: 1.8rem;
  line-height: 2.8rem;
  font-weight: bold;
  padding: 0 2.5rem;
  color: #000000;
}
.black_big_title {
  font-size: 2.4rem;
  line-height: 2.6rem;
  font-weight: bold;
  padding: 0 2rem;
  color: #212121;
  flex: 1;
}
.article_item {
  width: 18.3rem;
  height: 29.2rem;
  background-color: #fff;
  overflow: hidden;
  border-radius: 1rem;
  margin-right: 2.3rem;
  position: relative;
  flex-shrink: 0;
}
.article_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.article_shadow {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.27);
  position: absolute;
  top: 0;
  left: 0;
}
.article_txt {
  color: #fff;
  position: absolute;
  bottom: 2.5rem;
  width: calc(100% - 5rem);
  transform: translateX(-50%);
  left: 45%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding: 0 0.5rem;
  font-size: 1.8rem;
  line-height: 2rem;
}
.top_categ_item {
  width: 8rem;
  height: 8rem;
  background-color: #fafafa;
  border-radius: 1rem;
  overflow: hidden;
  margin-right: 0.5rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  display: block;
  color: #000000;
}
.top_categ_item_image_block {
  width: 4.5rem;
  height: 4.5rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  // justify-content: center;
}
.top_categ_item_image {
  width: 3rem;
  height: 3rem;
  margin-top: 0.5rem;
  object-fit: contain;
}
.top_categ_item_txt {
  font-size: 1rem;
  line-height: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 0.2rem;
  min-height: 2.4rem;
}
.product_wrapper {
  margin-bottom: 1.5rem;
}
.bottom_info {
  width: 25rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  align-items: center;
  margin-bottom: 3rem;
}
.info_txt {
  font-size: 1.3rem;
  line-height: 1.5rem;
  color: #ccc;
  text-align: center;
}
.logo_container {
  margin-top: 1.5rem;
}
.categories_list {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 30rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.circle_category {
  align-items: center;
  width: 8rem;
  margin-bottom: 1rem;
  color: #000000;
  display: block;
}
.circle_category_img_container {
  width: 8rem;
  height: 8rem;
  background-color: #fafafa;
  border-radius: 5rem;
  overflow: hidden;
  align-items: center;
  display: flex;
  justify-content: center;
}
.circle_categ_img {
  width: 5rem;
  height: 5rem;
  object-fit: contain;
}
.circle_categ_txt {
  font-size: 1rem;
  line-height: 1.5rem;
  min-height: 3rem;
  text-align: center;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
.row {
  flex-direction: row;
  display: flex;
  margin: 0 2rem;
  justify-content: space-between;
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-bottom-width: 0.1rem;
  border-bottom-color: #f0f0f0;
  border-bottom-style: solid;
  > div {
    width: 50%;
  }
}
.bottom_title_container {
  margin-top: 2rem;
}
.partner_flat_list {
  padding-left: 2rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  padding-right: 5rem;
  display: flex;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
.partner_item {
  width: 14rem;
  height: 8.4rem;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 1rem;
  overflow: hidden;
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
}
.partner_bottom {
  position: relative;
  left: 5rem;
}
.partner_img {
  width: 10rem;
  height: 5rem;
  object-fit: contain;
}

.search_container {
  padding: 2rem 0 0;
}
