.headline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem;
  margin-bottom: 0;
  padding: 1.45rem 1.7rem 1.45rem;
  border-radius: 7px;
  //   min-height: 3.5rem;

  &.closed {
    background: #f8f9fb;

    .mycateg_arrow {
      margin-left: 0.7rem;
      // margin-top: 0.3rem;
      transition: all 0.3s ease;
      transform: rotate(180deg);
    }
  }

  .title {
    display: flex;
    align-items: center;
    width: 100%;

    span {
      font-size: 2rem;
      line-height: 2.1rem;
      color: #ff3e6c;
      font-weight: bold;
    }

    .mycateg_arrow {
      margin-left: 0.7rem;
      margin-top: 0.3rem;
      transition: all 0.3s ease;
    }

    .hide_icon {
      padding-left: 1rem;
    }
  }

  .menu {
    // padding: 1rem 0.5rem;
    padding: 0;
    background: transparent;
    img {
      width: 1.6rem;
    }
  }
}
.wish_list {
  display: block;
  padding: 1rem 3rem 1rem;
  margin-top: 0;
  margin-bottom: 0;

  > div:last-child {
    margin-bottom: 0;
  }
}

.wish_count {
  margin-left: auto;
  font-weight: 700;
  font-size: 1.7rem;
  line-height: 1.9rem;
  color: #d2d2d2;
}
