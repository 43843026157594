.container {

    .inner_content {
        padding: 13rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 144rem;
        // width: 100%;
        margin: 0 auto;

        .title {
            font-style: normal;
            font-weight: bold;
            font-size: 6.4rem;
            line-height: 105%;
            text-align: center;
            color: #1D1D1F;
            margin-bottom: 3.5rem;
        }
        .subtitle {
            font-style: normal;
            font-weight: 500;
            font-size: 3.6rem;
            line-height: 130%;
            text-align: center;
            color: #1D1D1F;
            margin-bottom: 6rem;
        }
        button, a {
            background: #FF3E6C;
            box-shadow: 0px 4px 22px rgba(255, 0, 76, 0.42);
            border-radius: 50px;
            font-style: normal;
            font-weight: bold;
            font-size: 2.4rem;
            line-height: 3.5rem;
            text-align: center;
            color: #FFFFFF;
            padding: 1rem 6rem;
            display: flex;
            align-items: center;
            width: fit-content;
            outline: none;
            border: none;
            margin: 0 auto;
        }
    }
}

@media screen and (max-width: 768px) {
    .container {

        .inner_content {
            padding: 5rem 0 6rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
    
            .title {
                font-style: normal;
                font-weight: bold;
                font-size: 3.2rem;
                line-height: 100%;
                text-align: center;
                color: #1D1D1F;
                margin-bottom: 1.5rem;
            }
            .subtitle {
                font-style: normal;
                font-weight: 500;
                font-size: 1.4rem;
                line-height: 130%;
                text-align: center;
                color: #1D1D1F;
                margin-bottom: 3rem;
            }
            button, a {
                background: #FF3E6C;
                box-shadow: 0px 4px 22px rgba(255, 0, 76, 0.42);
                border-radius: 50px;
                font-style: normal;
                font-weight: bold;
                font-size: 1.7rem;
                line-height: 2.5rem;
                text-align: center;
                color: #FFFFFF;
                padding: 1.35rem 5.5rem;
                display: flex;
                align-items: center;
                width: fit-content;
                outline: none;
                border: none;
                margin: 0 auto;
            }
        }
    }
}