.content {
  padding: 0 2rem 8rem;
}
.title {
  font-size: 2.4rem;
  line-height: 2.8rem;
  color: #000;
  font-weight: 500;
  padding-right: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.order_form {
  margin-top: 1.5rem;
}

.phone_input {
  padding-left: 2.5rem;
  margin-bottom: 1.5rem;
  > div:first-child {
    left: 2.5rem !important;
  }
}
.delivery_date {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  position: relative;
  z-index: 1;
  font-size: 1.3rem;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  .date {
    color: #ff004c;
    font-size: 1.6rem;
  }
}
.mini_loader {
  margin: 0;
  padding: 0;
}
.order_btn {
  width: 26rem;
  height: 5rem;
  margin: 3rem auto 0;
}

.total_list_block {
  padding: 2.5rem 2rem 0.5rem 2rem;
  max-width: 30rem;
  margin: 0 auto;

  .total_item {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    color: #1a1a1a;

    .title {
      font-size: 1.4rem;
      line-height: 1.6rem;
      text-align: left;
    }

    .sum {
      font-size: 1.4rem;
      line-height: 1.6rem;
      font-weight: bold;

      &.red {
        font-size: 1.8rem;
        line-height: 2.1rem;
        color: #ff3e6c;
      }
    }
  }
}
.err_msg {
  position: relative;
  z-index: 1;
  margin-top: 1rem;
}
.pay_selector {
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  padding-right: 0;
}
