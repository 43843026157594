.modal_video {
  padding: 0;
  border: none;
  border-radius: 0;
  width: 100%;
  overflow: auto;
  position: relative;
  padding-top: 2rem;
  height: 100%;
  max-height: 93rem;
  background: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .modal_video_content {
    width: 100%;
    border: 0;
    margin: 0 auto;
    width: calc(100% - 8rem);
    display: -webkit-box;
    display: flex;
    position: relative;
    height: 100%;
    align-items: center;
    justify-content: center;
    iframe {
      max-width: 103rem !important;
      width: 100% !important;
      height: 57.9rem !important;
      border-radius: 7px;
      background: #000000;
      margin: 0 auto;
      margin-bottom: 3.8rem;
    }
    .modal_close {
      position: absolute;
      width: initial;
      top: 1.5rem;
      right: 0;
      border: none;
      outline: none;
      background-color: transparent;
    }
  }
}

.modal_video_mobile {
  padding: 0;
  border: none;
  border-radius: 0;
  width: 100%;
  overflow: auto;
  position: relative;
  padding-top: 2rem;
  height: 100%;
  max-height: 93rem;
  background: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .modal_video_content_mobile {
    width: 100%;
    border: 0;
    margin: 0 auto;
    width: 100%;
    display: -webkit-box;
    display: flex;
    position: relative;
    height: 100%;
    align-items: center;
    justify-content: center;
    iframe {
      width: 100%;
      border-radius: 7px;
      background: #484848;
      margin: 0 auto;
    }
    .modal_close {
      position: absolute;
      width: initial;
      top: 1rem;
      right: 1.5rem;
      border: none;
      outline: none;
      background-color: transparent;
    }
  }
}

.container {
  height: 100%;
  max-width: 144rem;
  width: 100%;
  margin: 0 auto;

  .video {
    position: relative;
    width: 100%;
    max-width: 103rem;
    margin: 0 auto;
    margin-bottom: 3.9rem;
    .video_media {
      width: 100%;
      height: 22rem;
      border-radius: 7px;
      background: #000000;
      cursor: pointer;
      position: relative;
    }
    .video_info {
      position: absolute;
      bottom: 3.6rem;
      left: 50%;
      transform: translateX(-50%);
      width: fit-content;

      .video_title {
        font-style: normal;
        font-weight: bold;
        font-size: 3.6rem;
        line-height: 130%;
        color: #ffffff;
        margin-bottom: 1.5rem;
        cursor: pointer;

        span {
          display: block;
        }
      }
      .video_time {
        font-style: normal;
        font-weight: bold;
        font-size: 2rem;
        line-height: 130%;
        color: #ffffff;
        cursor: pointer;

        .play_btn {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}
