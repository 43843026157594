.container {
  padding-top: 1rem;
}
.title {
  font-size: 2.4rem;
  line-height: 3.8rem;
  margin: 0 2rem;
}
.item {
  margin: 0 2rem;
}
.item_inner {
  flex-direction: row;
  height: 5.2rem;
  border-radius: 2rem;
  background-color: #ffffff;
  padding: 0 2.4rem;
  margin-top: 1.5rem;
  border: 0.1rem solid #eaeaea;
  display: flex;
  align-items: center;
}
.item_txt {
  color: #1a1a1a;
  font-size: 1.4rem;
  line-height: 2rem;
}
.img {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 2.4rem;
  }
}
.red {
  color: #ff3e6c;
}
