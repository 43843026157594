.container {
  height: 100%;
}

.title {
  font-weight: 500;
  color: #ff3e6c;
  padding: 0 2rem 1rem 2rem;
  background: #ffffff;
  position: sticky;
  top: 0;
  font-size: 1.4rem;
}
.comments_list {
  padding-bottom: 9.5rem;
}

.comment_item {
  display: flex;
  margin: 2rem 2rem 0 2rem;
  font-size: 1.4rem;

  &:first-child {
    margin: 1rem 2rem 0 2rem;
  }

  .user_img {
    width: fit-content;
    flex-shrink: 0;
    width: 3rem;

    img {
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      box-shadow: 0px 1px 16px rgba(148, 158, 183, 0.5);
      object-fit: cover;
    }
  }

  .center {
    padding-left: 1rem;

    .text {
      .comment_text {
        display: inline;
        word-break: break-word;
        padding-right: 0.5rem;

        span {
          font-weight: bold;
        }
      }
      .time {
        color: #d0d0d0;
        display: inline-block;
      }
    }
    .sum {
      color: #ffffff;
      font-family: 'Circe';
      background: #ff3e6c;
      border-radius: 50px;
      font-weight: bold;
      width: fit-content;
      padding: 0.4rem 1rem 0.3rem 1rem;
      margin-top: 1rem;
    }
    .actions_block {
      // margin-top: 1.5rem;
      display: flex;
      color: #d0d0d0;
      font-weight: 300;
      .point {
        padding: 0 0.5rem;
        height: 0;
        transform: translateY(-0.3rem);
      }
    }
  }
  .right {
    width: fit-content;
    font-size: 1rem;
    flex-shrink: 0;
    margin-left: auto;
    padding-left: 1rem;

    .likes {
      display: flex;
      align-items: center;
      img {
        width: 1.3rem;
        padding-right: 0.5rem;
      }
    }
  }
}

.empty_list {
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #a0a0a0;
  display: flex;
  padding: 0 2rem;
  text-align: center;
  margin-top: 3rem;
}

.emoji_block {
  height: 3.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  font-size: 1.8rem;
  flex-shrink: 0;
}

.input_comment {
  background-color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.wish_photo {
  width: 100%;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}
