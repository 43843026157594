.header_section {
  position: relative;
  // background: #e5e5e5;

  &.search_opened {
    z-index: 30002;
  }
}
.header_container {
  font-family: 'Circe';
  padding: 0 2.1rem 0.8rem 2.3rem;
  position: relative;
  align-items: center;
  min-height: 6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 6rem;
  z-index: 100;

  &.header_container_landing {
    background-color: #f6f6f9;
  }

  .topmenu_button {
    // width: 5rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      align-items: center;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .topmenu_title {
    font-size: 1.7rem;
    line-height: 2rem;
    font-style: normal;
    font-weight: bold;
    // text-transform: uppercase;
    text-align: center;
    min-height: 2.1rem;
    width: fit-content;
    margin: 0 0 0 2.4rem;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    &.my_profile {
      margin-left: 0.7rem;
    }

    &.with_logo {
      overflow: visible;
    }

    svg {
      width: 13rem;
      height: auto;
      transition: all 0.3s ease-in-out;
      position: relative;
      top: 0.8rem;

      &.active {
        path {
          transition: all 0.3s ease-in-out;
          fill: #e0e0e0;
        }
      }
    }
  }

  .topmenu_links {
    display: flex;

    a {
      padding: 0.5rem;
    }

    .share_link {
      width: 3.2rem;
      height: 3.2rem;
      background: #ff3e6c;
      box-shadow: 0px 4px 10px rgba(255, 0, 76, 0.42);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 1rem;
    }

    .add_link {
      background: #ff3e6c;
      box-shadow: 0px 4px 10px rgba(255, 0, 76, 0.42);
      width: 3.2rem;
      height: 3.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      color: #fff;
      flex-shrink: 0;
      img {
        width: 1.2rem;
      }
    }

    button {
      background: #ff3e6c;
      box-shadow: 0px 4px 10px rgba(255, 0, 76, 0.42);
      width: 3.2rem;
      height: 3.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      color: #fff;
      flex-shrink: 0;
      border: none;
      padding: 0;
      img {
        width: 1.2rem;
      }
    }
  }
}
