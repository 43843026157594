.mentions {
  color: #ff3e6c;
  background: #ffffff;
  position: relative;
  z-index: 1;
}

.ment_user {
  color: #ff3e6c;
  &:hover {
    color: #ff3e6c;
  }
}

.input_comment {
  display: flex;
  background: #ffffff;
  align-items: center;
  padding: 1.1rem 2rem;
  width: calc(100% - 4rem);

  .user_img {
    padding-top: 0.6rem;
    img {
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      box-shadow: 0 0.1rem 1.6rem rgba(148, 158, 183, 0.5);
      object-fit: cover;
    }
  }

  .comment_input {
    font-size: 1.4rem;
    line-height: 1.6rem;
    font-family: 'Circe';
    border: none;
    background: #ffffff;
    margin-left: 1rem;
    width: calc(100% - 8rem);
    textarea {
      border: none;
      padding: 0;
      margin: 0 !important;
    }

    &::placeholder {
      color: #b9c1d2;
    }
  }

  .send_button {
    background: #ff3e6c;
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 50%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    padding: 0;

    img {
      width: 1.8rem;
    }
  }
}
