.item {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #eaeaea;
  padding: 2.5rem 0;
  color: #000000;

  .left_half {
    width: 3.6rem;
    flex-shrink: 0;
    position: relative;

    img {
      width: 3.6rem;
      height: 3.6rem;
      // box-shadow: 0px 1px 16px rgba(148, 158, 183, 0.5);
      border-radius: 50%;
      object-fit: cover;
      position: relative;
    }
  }

  .center {
    padding-left: 1rem;
    overflow: hidden;
    color: #000000;
  }

  .right_half {
    width: fit-content;
    margin-left: auto;
    flex-shrink: 0;
    display: flex;

    .time {
      color: #d0d0d0;
      font-weight: normal;
      margin-left: auto;
      padding-left: 1rem;
      font-size: 1.3rem;
      line-height: 1.5rem;
    }

    img {
      max-width: 6rem;
      max-height: 6rem;
      border-radius: 0.4rem;
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.8rem;
    color: #2d2d2d;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .sum {
    margin-top: 0.5rem;
    border-radius: 50px;
    padding: 0.4rem 0.8rem 0.3rem 0.8rem;
    font-style: normal;
    font-weight: bold;
    font-size: 1.3rem;
    line-height: 1.5rem;
    display: flex;
    align-items: center;
    width: fit-content;
    color: #ffffff;
    background: #ff3e6c;
    img {
      padding-right: 0.5rem;
    }
  }
  .type {
    margin-top: 1rem;
    font-size: 1.3rem;
    color: #d0d0d0;
  }
}

@media screen and (min-width: 768px) {
  .item {
    .center {
      padding-left: 3rem;
    }
  }
}
