.input_block {
  margin-top: 2rem;
  .input_label {
    position: relative;
    width: 100%;
    // height: 5.7rem;
    display: block;

    .little_text {
      position: absolute;
      top: -1rem;
      left: 2.5rem;
      z-index: 3;
      font-size: 1.4rem;
      color: #999999;
      transition: all 0.2s ease-in-out;
    }
    input {
      background: #ffffff;
      border-radius: 1rem;
      border: none;
      width: 100%;
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: #1a1a1a;
      padding: 2.3rem 2.5rem 1.3rem 2.5rem;
    }
  }

  &.password {
    // display:none;
    input {
      width: calc(100% - 5rem);
      border: 0.1rem solid #8747fe;
    }
    &.wrong {
      input {
        border-color: #ff3e6c;
      }
    }
    .little_text {
      top: 1.8rem;

      &.focus {
        top: 0.5rem;
        font-size: 1.2rem;
      }
    }
  }
}
