.button_container {
  .add_button_page {
    display: flex;
    align-items: center;
    height: 5.2rem;
    background: #ff3e6c;
    box-shadow: 0px 4px 10px rgba(255, 0, 76, 0.42);
    border-radius: 7px;
    width: calc(100% - 6rem);
    min-width: 33rem;
    z-index: 20;
    cursor: pointer;
    border: none;
    outline: none;

    &.block {
      position: relative;
      margin: 0 auto;
    }
    &.fixed {
      position: fixed;
      bottom: 8rem;
      left: 50%;
      transform: translateX(-50%);
    }

    .text {
      flex-shrink: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-style: normal;
      font-weight: bold;
      font-size: 1.5rem;
      line-height: 2.2rem;
      color: #ffffff;
    }
  }
}
