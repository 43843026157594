.container {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  .checkbox {
    width: 2.2rem;
    height: 2.2rem;
    background: #cecece;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
  .title {
    color: #747474;
    font-size: 1.5rem;
    line-height: 2.2rem;
    margin-left: 1rem;
  }
  &.checked {
    .checkbox {
      background-color: #ff3e6c;
    }
  }
}
