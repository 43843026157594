.status_order {
  padding: 1.5rem;
  background: #f8f9fb;
  border-radius: 10px;
  margin-bottom: 1rem;
  font-family: 'Circe';
  margin-top: 1.5rem;
  .status_order_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    .left {
      .status_number {
        font-style: normal;
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 130%;
        color: #000000;
      }
      .status_order_text_bold {
        font-style: normal;
        font-weight: bold;
        font-size: 1.5rem;
        line-height: 130%;
        color: #000000;
      }
    }
    .right {
      .status_progress_num {
        font-style: normal;
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 2.7rem;
        color: #b9b9b9;
      }
    }
  }
  .status_order_progress {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    .progress_item {
      border-radius: 5px;
      width: 5.7rem;
      height: 0.5rem;
      background: #dee0e1;
      &.progress_active {
        background: #ff3e6c;
      }
    }
  }
  .status_order_text {
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2.2rem;
    color: #555555;
    margin-bottom: 1.5rem;
  }

  .completed_btn {
    height: 5.2rem;
    background: linear-gradient(90.35deg, #11ddff -21.76%, #ac17ff 99.96%);
    box-shadow: 0px 4px 4px rgba(132, 74, 255, 0.4);
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    width: calc(100% - 4rem);
    margin: 0 auto;
    font-family: 'Circe';

    .img_delivered {
      margin-right: 1rem;
      width: 2.2rem;
      height: 2.2rem;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }

    .img_completed {
      // margin-right: 1rem;
      width: 2.2rem;
      height: 2.2rem;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }
    .text_completed {
      font-style: normal;
      font-weight: 700;
      font-size: 1.7rem;
      line-height: 2.5rem;
      color: #ffffff;
    }
  }
}
