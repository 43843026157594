.more_button {
  font-size: initial;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  transform: translate(0, 0);
  position: relative;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 7.5rem;
  z-index: 1;

  > img {
    width: 1.6rem;
  }

  .dropdown_menu {
    position: absolute;
    display: none;
    top: 3rem;
    right: 0;
    background: #ffffff;
    box-shadow: 0px 4px 40px #f0f0f0;
    border-radius: 10px;
    padding: 1rem 0;
    width: 24rem;

    > div,
    a {
      display: flex;
      align-items: center;
      padding: 1rem 2.5rem;
      font-family: 'Circe';
      font-weight: normal;
      font-size: 1.5rem;
      color: #000000;
      transition: all 0.3s ease;
      &:hover {
        background: #f8f9fb;
      }
    }

    &.opened {
      display: block;
    }
  }
}
.item {
  .img_block {
    width: 2rem;

    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 2rem;

    svg {
      width: 2rem;
      height: auto;
    }
  }
}
