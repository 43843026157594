.title {
  font-size: 2.4rem;
  line-height: 2.8rem;
  color: #000000;
  font-weight: 500;
  padding-right: 1rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 2rem;
  text-align: center;
}

.edit_content {
  padding: 2rem;

  .input_block {
    padding: 1.5rem 3rem;
    position: relative;
    border-radius: 1rem;
    background: #ffffff;
    box-shadow: 0px 4px 40px #f0f0f0;
    margin-top: 1.5rem;

    .input_text {
      font-size: 1.3rem;
      font-weight: normal;
      line-height: 1.5rem;
      color: #999999;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    textarea {
      font-family: 'Circe';
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: #000000;
      margin-top: 0.5rem;
      width: 100%;
      border: none;
      padding: 0;
      resize: none;

      &::placeholder {
        color: #999999;
        font-size: 1.3rem;
      }
    }
  }

  .categ_select {
    border: none;
    width: 65%;
    .select_control {
      border: none;
      width: 100%;
    }
    .select_value {
      margin-left: auto;
    }
    .select_value_label {
      color: #000000;
      font-family: 'Circe';
      font-size: 1.4rem;
    }
    .select_arrow {
      border-right-color: #2b2b2b;
      border-bottom-color: #2b2b2b;
    }
    .select_multival_wrapper {
      outline: none;
    }
    .select_menu_outer {
      z-index: 2;
      background: #ffffff;
      border-radius: 10px;
      padding: 1rem 0;
    }
    .select_option {
      box-shadow: none;
      font-size: 1.5rem;
      line-height: 230%;
      transition: all 0.2s ease-in-out;
      padding: 0 1rem;
      height: 4rem;
      font-family: 'Circe';
      background: #ffffff;
      text-align: right;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #000000;
    }
  }

  .checkbox_block {
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: #b5b5b5;
    margin: 1.5rem 0;

    .text {
      padding-left: 0.5rem;
    }
  }
}

.separator {
  background: #e2e2e2;
  margin: 0;
  opacity: 1;
  height: 1px;
}

.btn_container {
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    width: 27rem;
    height: 5rem;
  }
}
