.container {
  padding: 0 2rem;
}
.separator {
  background: #e2e2e2;
  margin: 0;
  opacity: 1;
  height: 0.1rem;
  margin-bottom: 1rem;
}
.checkbox {
  margin-top: 1.5rem;
}
.btn_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
  .btn {
    width: 27rem;
    height: 4.4rem;
  }
}

.auth {
  margin-bottom: 2rem;
}

.result {
  height: calc(100vh - 4rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .success_title {
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 2.8rem;
    margin-top: 3.5rem;
    color: #000000;
    text-align: center;
  }
  .success_subtitle {
    font-size: 1.4rem;
    line-height: 160%;
    color: #8f99a4;
    padding: 2rem;
    margin-top: 1.5rem;
    text-align: center;
  }
  .accept_button {
    .btn_idea {
      display: block;
      background: #ff3e6c;
      box-shadow: 0px 4px 10px rgba(255, 0, 76, 0.42);
      border-radius: 30px;
      padding: 0.9rem 2.5rem 1rem 2.5rem;
      font-family: 'Circe';
      font-weight: bold;
      font-size: 1.6rem;
      color: #ffffff;
      width: 13.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 2rem;
    }
    .idea_link {
      font-style: normal;
      font-weight: bold;
      font-size: 1.6rem;
      line-height: 2.4rem;
      text-align: center;
      color: #9a9a9a;
      cursor: pointer;
    }
    a.btn {
      display: block;
      background: #ff3e6c;
      box-shadow: 0px 4px 10px rgba(255, 0, 76, 0.42);
      border-radius: 30px;
      padding: 0.9rem 2.5rem 1rem 2.5rem;
      font-family: 'Circe';
      font-weight: bold;
      font-size: 1.6rem;
      color: #ffffff;
    }
    .success_close {
      font-size: 1.4rem;
      line-height: 1.6rem;
      display: flex;
      align-items: center;
      color: #ff004c;
      text-decoration: none;
      margin-top: 2rem;
      justify-content: center;

      img {
        transform: rotate(180deg);
        padding-left: 1rem;
      }
    }
  }
}

.clipboard_container {
  width: calc(100% - 2rem);
  margin-bottom: 2.7rem;
  cursor: pointer;
  .input_wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.3rem 2.3rem;

    .input_copy {
      width: 100%;
      border: none;
      font-style: normal;
      font-weight: bold;
      font-size: 1.6rem;
      line-height: 1.9rem;
      display: flex;
      align-items: center;
      color: #000000;
      padding-right: 1rem;
    }
    .img {
      width: 4.5rem;
      flex-shrink: 0;
      height: 4.5rem;
      position: relative;
      top: 3px;
    }
  }
}

.result_btn {
  width: 26rem;
  margin-bottom: 2rem;
  height: 5.2rem;
}

.bottom_prompt {
  padding: 2rem 3.5rem;
  font-size: 1.3rem;
  line-height: 1.5rem;
  background: #f9f9f9;
  border-radius: 1rem;
  margin-bottom: 2.5rem;

  .okay {
    cursor: pointer;
    margin-top: 1rem;
    font-size: 1.4rem;
    text-decoration: underline;
  }
}
