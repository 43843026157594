.title {
  font-size: 2.4rem;
  line-height: 2.8rem;
  color: #000;
  padding-right: 1rem;
  font-weight: 500;
  margin: 2rem 3rem 0 3rem;
}
.pay_item {
  margin-top: 1.5rem;
  margin: 0 3rem;
}
.select_container {
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.item_inner {
  flex-direction: row;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 1.8rem 2.5rem;
  border-radius: 0.7rem;
  margin: 1rem 0;
  box-shadow: 0 0.4rem 4rem #f0f0f0;
}
.item_txt {
  padding-left: 1rem;
  font-size: 1.4rem;
}
.img_block {
  width: 2rem;
  height: 2rem;
}
.selected_icon {
  margin-left: auto;
  svg {
    width: 1.4rem;
    height: auto;
  }
}
