.wishlist_block {
  width: calc(100% - 5rem - 25.5rem);
  padding-left: 5rem;
}
.settings_content {
  .main_title {
    font-weight: 500;
    font-size: 2.6rem;
    line-height: 3rem;
  }
  .settings_title {
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.1rem;
    margin-bottom: 1.5rem;
    color: #ff3e6c;
    margin-top: 4.5rem;

    &.first {
      margin-top: 2.3rem;
    }
  }
  .separator.settings {
    margin: 0;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    background: #eaeaea;
    opacity: 0.5;
    height: 1px;
  }
  .settings_item {
    display: flex;
    align-items: center;
    padding: 1.5rem 2rem;
    background: #ffffff;
    border-radius: 2rem;
    box-shadow: 0px 4px 40px #f0f0f0;
    margin-top: 1.5rem;

    &.input {
      align-items: flex-start;
      text-align: right;
      align-items: center;
    }
    .input_text {
      font-weight: bold;
      font-size: 1.4rem;
      color: #1a1a1a;
      text-align: left;
      // width: 7rem;
      width: fit-content;
      flex-shrink: 0;
      line-height: 1.6rem;
      padding-top: 0.2rem;
    }
    .input_block {
      margin-left: 1.3rem;
      position: relative;
      width: 100%;
      input {
        border: none;
        font-size: 1.4rem;
        font-family: 'Circe';
        text-align: right;
        color: #111111;
      }
      .subtext {
        font-size: 1.3rem;
        line-height: 1.5rem;
        color: #b0b0b0;
        display: flex;
        align-items: center;
        width: fit-content;
        margin-top: 0;
        margin-left: auto;
      }
    }
  }
  .more_gray_accept {
    width: 1.5rem;
    padding-left: 1rem;
    margin-right: 0.5rem;
  }
  .input_block_comment {
    background: #ffffff;
    box-shadow: 0px 4px 40px #f0f0f0;
    border-radius: 2rem;
    padding: 1.5rem 2rem;
    margin-top: 1.5rem;
    width: calc(100% - 4rem);

    .input_text {
      font-weight: bold;
      font-size: 1.4rem;
      color: #1a1a1a;
      line-height: 1.6rem;
      padding-top: 0.2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      > div:last-child {
        font-style: normal;
        font-weight: normal;
        font-size: 1.2rem;
        line-height: 1.4rem;
        color: #b8b8b8;
      }
    }
    textarea {
      font-size: 1.4rem;
      font-family: 'Circe';
      width: 100%;
      margin-top: 1rem;
      border: none;
      padding: 0;
      resize: none;
    }
  }
  .phoneInput {
    width: fit-content;
    margin-left: auto;
    input {
      padding-right: 0;
      margin-left: auto;
      width: 16.5rem;
      text-align: left !important;
      &:focus {
        outline: none;
        border: none;
        box-shadow: none;
      }
    }
  }
  .birthdate {
    background: #ffffff;
    // -webkit-appearance: none;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
  }
  .birthdate::-webkit-inner-spin-button {
    display: none;
  }
  .birthdate::-webkit-calendar-picker-indicator {
    display: none;
  }
  .birthdate::-webkit-clear-button {
    display: none;
  }

  .accept_icon {
    position: absolute;
    right: -1rem;
    top: 35%;
    transform: translateY(-50%);
    visibility: hidden;
    width: 1.4rem;
    padding-left: 1rem;

    &.show {
      visibility: visible;
    }
  }

  .loader_mini {
    position: absolute;
    right: -1rem;
    top: 35%;
    width: 1.4rem;
    transform: translateY(-50%);
    visibility: hidden;

    &.show {
      visibility: visible;
    }
  }
  .flag_dropdown {
    border: none;
    background: #ffffff;
  }
  .phoneDropdown {
    width: 20rem;
  }
  .confirm_phone {
    font-size: 1.2rem;
    line-height: 1.4rem;
    text-decoration-line: underline;
    color: #ff3e6c;
    cursor: pointer;
  }
  .save_button_block {
    margin-top: 3.5rem;
    // margin: 0 2rem;
    box-shadow: none;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 1.8rem;
    line-height: 2.1rem;
    text-transform: none;
    font-weight: bold;
    font-family: 'Circe';
    background: #ff3e6c;
    border-radius: 3rem;
    height: 5rem;
    border: none;
    color: #ffffff;
  }
  .loading {
    opacity: 0.5;
    img {
      width: 1.4rem;
    }
  }
  .msg {
    width: 70%;
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: #ff3e6c;
    text-align: center;
    transform: translateY(-5px);
    padding: 1.5rem 0;
    margin: 0 auto;
  }
}

.soc_item {
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 40px #f0f0f0;
  margin-top: 1.5rem;
  padding: 1rem 1rem;
  background: #ffffff;
  border-radius: 2rem;
  color: #000;
  .soc_icon {
    width: 6rem;
    height: 6rem;
    flex-shrink: 0;
    display: flex;
    img {
      width: 6rem;
      height: 6rem;
    }
  }
  .soc_info {
    flex: 1;
    padding-left: 1rem;
  }
  .soc_title {
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: bold;
    color: #000;
  }
  .soc_subtitle {
    font-size: 1.4rem;
    line-height: 2rem;
    color: #000;
  }
  .soc_stat_icon {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 1rem;
    width: 2.5rem;
    img {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
}

@media screen and (max-width: 1400px) {
  .phoneInput {
    input {
      width: 17.5rem !important;
    }
  }
}
@media screen and (max-width: 1200px) {
  .phoneInput {
    input {
      width: 18.5rem !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .wishlist_block {
    width: calc(100% - 4rem);
    padding: 0 2rem;
    padding-bottom: 10rem;
  }
  .settings_content {
    .main_title {
      font-weight: 500;
      font-size: 2.6rem;
      line-height: 3rem;
    }
    .settings_title {
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 2.1rem;
      margin-bottom: 1.5rem;
      color: #ff3e6c;
      margin-top: 4.5rem;

      &.first {
        margin-top: 2.3rem;
      }
    }
    .separator.settings {
      margin: 0;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      background: #eaeaea;
      opacity: 0.5;
      height: 1px;
    }
    .settings_item {
      display: flex;
      align-items: center;
      padding: 1.5rem 2rem;
      background: #ffffff;
      border-radius: 2rem;
      box-shadow: 0px 4px 40px #f0f0f0;
      margin-top: 1.5rem;

      &.input {
        align-items: flex-start;
        text-align: right;
        align-items: center;
      }
      .input_text {
        font-weight: bold;
        font-size: 1.4rem;
        color: #1a1a1a;
        text-align: left;
        // width: 7rem;
        width: fit-content;
        flex-shrink: 0;
        line-height: 1.6rem;
        padding-top: 0.2rem;
        max-width: 7rem;
      }
      .input_block {
        margin-left: 1.3rem;
        position: relative;
        width: 100%;
        input {
          border: none;
          font-size: 1.4rem;
          font-family: 'Circe';
          text-align: right;
          color: #111111;
        }
        .subtext {
          font-size: 1.3rem;
          line-height: 1.5rem;
          color: #b0b0b0;
          display: flex;
          align-items: center;
          width: fit-content;
          margin-top: 0;
          margin-left: auto;
          .red_msg {
            color: #ff3e6c;
          }
        }

        .more_gray_accept {
          width: 1.5rem;
          padding-left: 1rem;
          margin-right: 0.5rem;
        }
      }
      &.photo {
        .user_img {
          width: 4.4rem;
          height: 4.4rem;
          border-radius: 50%;
          position: relative;
          box-shadow: 0px 1px 16px rgba(148, 158, 183, 0.5);
          overflow: hidden;
          .inner_img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .shadow_box {
            background: rgba(5, 5, 5, 0.3);
            width: 100%;
            height: 100%;
            position: absolute;

            .camera_icon {
              position: absolute;
              display: block;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);

              svg {
              }
            }
          }
        }
        .item_text {
          font-weight: bold;
          font-size: 1.4rem;
          line-height: 1.6rem;
          margin-left: 4rem;
          color: #000000;
        }
        .arrow {
          margin-left: auto;
        }
      }
    }
    .input_block_comment {
      background: #ffffff;
      box-shadow: 0px 4px 40px #f0f0f0;
      border-radius: 2rem;
      padding: 1.5rem 2rem;
      margin-top: 1.5rem;
      width: calc(100% - 4rem);

      .input_text {
        font-weight: bold;
        font-size: 1.4rem;
        color: #1a1a1a;
        line-height: 1.6rem;
        padding-top: 0.2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > div:last-child {
          font-style: normal;
          font-weight: normal;
          font-size: 1.2rem;
          line-height: 1.4rem;
          color: #b8b8b8;
        }
      }
      textarea {
        font-size: 1.4rem;
        font-family: 'Circe';
        width: 100%;
        margin-top: 1rem;
        border: none;
        padding: 0;
        resize: none;
      }
    }
    .phoneInput {
      width: fit-content;
      margin-left: auto;
      input {
        margin-left: auto;
        width: 16.5rem !important;
        padding-right: 0;
        text-align: left !important;
        &:focus {
          outline: none;
          border: none;
          box-shadow: none;
        }
      }
    }
    .birthdate {
      background: #ffffff;
      // -webkit-appearance: none;
      -webkit-appearance: textfield;
      -moz-appearance: textfield;
    }
    .birthdate::-webkit-inner-spin-button {
      display: none;
    }
    .birthdate::-webkit-calendar-picker-indicator {
      display: none;
    }
    .birthdate::-webkit-clear-button {
      display: none;
    }

    .accept_icon {
      position: absolute;
      right: -1rem;
      top: 35%;
      transform: translateY(-50%);
      visibility: hidden;
      width: 1.4rem;
      padding-left: 1rem;

      &.show {
        visibility: visible;
      }
    }

    .loader_mini {
      position: absolute;
      right: -1rem;
      top: 35%;
      width: 1.4rem;
      transform: translateY(-50%);
      visibility: hidden;

      &.show {
        visibility: visible;
      }
    }
    .flag_dropdown {
      border: none;
      background: #ffffff;
    }
    .phoneDropdown {
      width: 20rem;
    }
    .confirm_phone {
      font-size: 1.2rem;
      line-height: 1.4rem;
      text-decoration-line: underline;
      color: #ff3e6c;
      cursor: pointer;
    }
    .save_button_block {
      margin-top: 3.5rem;
      position: fixed;
      bottom: 8rem;
      width: calc(100% - 5rem);

      button {
        // width: 21rem;
        // margin: 0 auto;
      }
    }
    .msg {
      width: 70%;
      font-size: 1.4rem;
      line-height: 1.6rem;
      color: #ff3e6c;
      text-align: center;
      transform: translateY(-5px);
      padding: 1.5rem 0;
      margin: 0 auto;
    }
  }
}
.confirmed {
  font-size: 1.3rem;
  line-height: 1.5rem;
  color: #b0b0b0;

  &.red_msg {
    color: #ff3e6c;
  }
  .more_gray_accept {
    svg {
      width: 1.5rem;
      height: fit-content;
      padding: 0 0 0 1rem;
      margin: 0 0.5rem 0 0;
    }
  }
}

.input_wrapper {
  position: relative;

  .subtext {
    position: absolute;
    right: 0;
    bottom: 1.5rem;
    right: 2rem;
  }
}
