.container {
  padding-top: 3rem;
  padding-bottom: 1rem;
  flex-direction: row;
  padding: 0 4.5rem;
  display: flex;
  justify-content: space-between;
}
.item {
  width: 7rem;
}
.item_wrapper {
  width: calc(100% - 9rem);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.item_wrapper_half {
  width: 50%;
}
.icon {
  width: 7rem;
  height: 7rem;
  background-color: #f9fafc;
  border-radius: 5rem;
}
.icon_inner {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  img {
    width: 2.6rem;
    height: 2.6rem;
    object-fit: contain;
  }
}
.item_txt {
  margin-top: 1.5rem;
  font-size: 1.3rem;

  line-height: 1.9rem;
  color: #747474;
  min-height: 4.4rem;
  //   textalignvertical: center;
  text-align: center;
}
