.modal_window {
  height: 98%;
}
.content {
  padding: 0 3rem 3rem;
  .headline {
    text-align: center;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.1rem;
    color: #121212;
    margin-top: 1rem;
    text-transform: uppercase;
  }
  .img_block {
    width: calc(100% - 2rem);
    margin: 0 auto;
    margin-top: 4rem;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .title {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 2.8rem;
    color: #000000;
    margin-top: 4rem;
  }
  .text {
    margin-top: 3rem;
    font-size: 1.6rem;
    line-height: 2.1rem;
  }
}
