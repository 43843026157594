.swiper_container_friends {
  min-height: calc(100vh - 21.6rem);
}
.empty_title {
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #a0a0a0;
  text-align: center;
  margin-top: 10rem;
}
.cart_nav_block {
  margin: 1rem;
  background: #f9f9f9;
  border-radius: 2rem;
  .cart_nav {
    display: flex;
    padding: 0.5rem 1rem;
    justify-content: space-between;
    transition: all 0.3s ease;
    div {
      width: 50%;
      justify-content: center;
      font-size: 1.4rem;
      line-height: 1.6rem;
      display: flex;
      align-items: center;
      padding: 0.5rem 1.5rem;
      color: #1a1a1a;
      transition: all 0.3s ease;
      cursor: pointer;

      &.active {
        background: #ffffff;
        box-shadow: 0px 2px 5px rgba(103, 103, 103, 0.25);
        border-radius: 2rem;
        font-weight: bold;
      }
    }
  }
}
.friends_container {
  > div:last-child {
    border-bottom: none !important;
  }
}
