.container {
  .inner_content {
    padding: 10.3rem 0 13rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 144rem;
    // width: 100%;
    margin: 0 auto;

    .img_block {
      position: relative;
      margin-bottom: 8rem;

      .number {
        position: absolute;
        width: 8rem;
        height: 8rem;
        right: -3rem;
        top: -3rem;

        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: cover;
        }
      }

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }
    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 4.8rem;
      line-height: 100%;
      color: #000000;
      margin-bottom: 3.5rem;
    }
    .subtitle {
      font-style: normal;
      font-weight: bold;
      font-size: 2.1rem;
      line-height: 100%;
      color: #000000;
      margin-bottom: 9.5rem;
    }
    .ios_android {
      display: flex;
      justify-content: center;

      .link {
        .item {
          margin-right: 3.5rem;
          width: 26.5rem;
          height: 9rem;

          img {
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .inner_content {
      padding: 6.2rem 4rem 5rem;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: unset;

      .img_block {
        position: relative;
        margin-bottom: 6.5rem;
        width: 9rem;
        height: 9rem;

        .number {
          position: absolute;
          width: 3rem;
          height: 3rem;
          right: -1rem;
          top: -1rem;

          img {
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
          }
        }

        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: cover;
        }
      }
      .title {
        font-size: 3.2rem;
        line-height: 100%;
        margin-bottom: 1rem;
      }
      .subtitle {
        font-weight: normal;
        font-size: 1.4rem;
        line-height: 130%;
        margin-bottom: 3rem;
      }
      .ios_android {
        display: flex;
        justify-content: center;

        .link {
          .item {
            margin-right: 1.1rem;
            width: 14.2rem;
            height: 4.8rem;

            img {
              max-width: 100%;
              max-height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}
