.header_btn {
  width: 3rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content {
  position: relative;

  .ref_btn {
    width: calc(100% - 6rem);
    margin: 1rem 3rem;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    text-align: center;
    justify-content: center;

    .ref_text {
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-family: 'Circe';
      font-weight: bold;
      text-align: center;
      margin-top: 3rem;
    }

    button,
    a {
      background: #ffffff;
      box-shadow: 0px 0.4rem 1rem rgba(200, 200, 200, 0.42);
      color: #000000;
      width: 100%;
      border: none;
      outline: none;
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-family: 'Circe';
      font-weight: bold;
      border-radius: 3rem;
      height: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .red_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 3rem 0 3rem;

    .text {
      font-style: normal;
      font-weight: 700;
      font-size: 1.7rem;
      line-height: 2.5rem;
      color: #000000;
    }
  }

  .cart_nav_block {
    margin: 1.5rem 2rem 2.3rem 2rem;
  }
  .cart_nav {
    display: flex;
    align-items: center;
    // justify-content: space-between;

    border-radius: 2rem;
    padding: 0.5rem 1rem;
    > a {
      display: block;
      // width: 50%;
      padding: 0.4rem 1.5rem;
      text-align: center;
      font-weight: 700;
      font-size: 1.7rem;
      line-height: 2.5rem;
      color: #1a1a1a;
      transition: all 0.2s ease;
      margin-right: 0.5rem;
      &.active {
        background: #000000;
        color: #ffffff;
        box-shadow: 0px 0.2rem 0.5rem rgba(103, 103, 103, 0.25);
        border-radius: 2rem;
        font-weight: bold;
      }
    }
  }
  .history_items_block {
    margin: 3rem 3rem 0rem 3rem;
    .title {
      color: #9b9b9b;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 1.6rem;
    }
  }
}

.empty_history {
  font-size: 1.4rem;
  line-height: 1.5rem;
  text-align: center;
  margin-top: 5rem;
  font-weight: 300;
  color: #a0a0a0;
}

.share_button_block {
  width: calc(100% - 6rem);
  margin: 1rem 3rem;
  border-radius: 1rem;
  height: 4rem;

  button {
    width: 100% !important;
  }
}

.desk_content {
  width: 100%;
  .headline {
    font-weight: bold;
    font-size: 3.2rem;
    line-height: 3.7rem;
  }
  .content_container {
    margin-top: 4rem;
    display: flex;
    justify-content: space-between;
    .right_block {
      width: calc(100% - 34rem);
      .content_item {
        padding-bottom: 4.5rem;
        margin-top: 4.5rem;
        border-bottom: 1px solid #f0f0f0;
        &:last-child {
          border: none;
        }
        &:first-child {
          margin-top: 0;
        }
      }
      .title {
        font-size: 1.8rem;
        line-height: 2.1rem;
        font-weight: bold;
        color: #ff3e6c;
      }
      .subtitle {
        font-size: 1.4rem;
        line-height: 1.6rem;
        color: #464646;
        margin-top: 2rem;
      }
      .link_block {
        display: flex;
        background: #ffffff;
        box-shadow: 0px 0.4rem 4rem #f0f0f0;
        border-radius: 1rem;
        padding: 1rem 2.5rem;
        align-items: center;
        justify-content: space-between;
        width: calc(100% - 8rem);
        margin-top: 2rem;

        .left {
          width: 100%;
        }

        .little_text {
          font-size: 1.3rem;
          line-height: 1.5rem;
          color: #999999;
        }
        .link {
          margin-top: 0.5rem;
          font-size: 1.6rem;
          line-height: 1.9rem;
          cursor: pointer;
          width: 100%;
          outline: none;
          border: none;
        }
        .share_buttons {
          display: flex;
          button {
            margin-left: 1rem;
          }
        }
      }
    }
  }
}

.balance_info {
  background: #f8f9fb;
  padding: 1.5rem 0 2.7rem;
  margin-top: 2.2rem;
  .balance_info_title {
    font-family: 'Circe';
    font-style: normal;
    font-weight: 700;
    font-size: 1.7rem;
    line-height: 2.5rem;
    color: #000000;
    margin: 0 2rem;
    margin-bottom: 1.5rem;
    position: relative;

    .close_btn {
      position: absolute;
      right: 0;
      top: -1rem;
      width: 1.1rem;
      height: 1.1rem;
      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }
  }
}

.profile_swiper {
  padding: 0 1.6rem;

  .swiper_slide {
    width: 19.2rem;

    .slide_content {
      width: 16.9rem;
      height: 16.2rem;
      padding: 1.5rem;
      border-radius: 10px;
      position: relative;
      background: #ffffff;

      &.disabled {
        .title {
          color: #d2d2d2;
        }
        .subtitle {
          color: #c9c9c9;
        }
        .button {
          background: #e6e6e6;
        }
      }

      .top_icon {
        display: flex;
        margin-bottom: 1rem;
        .icon {
          svg {
            path {
            }
          }
        }
        .success {
          margin-left: auto;
          display: flex;
          align-items: center;
          .img_block {
            width: 1.6rem;
            height: 1.217rem;
            img {
              max-width: 100%;
              max-height: 100%;
            }
          }
          .text {
            font-size: 1.2rem;
            line-height: 130%;
            color: #c0c0c0;
            margin-left: 0.9rem;
          }
        }
      }
      .title {
        font-weight: bold;
        font-size: 1.7rem;
        line-height: 2.5rem;
        color: #000000;
        margin-bottom: 0.6rem;
      }
      .subtitle {
        font-size: 1.2rem;
        line-height: 130%;
        color: #747474;
        margin-bottom: 1.9rem;
      }
      .button {
        background: #ff3e6c;
        border-radius: 30px;
        height: 3.2rem;
        width: 14.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;

        .text {
          font-size: 1.5rem;
          line-height: 2.2rem;
          color: #ffffff;
        }
      }
    }
  }
}
