.title {
  font-family: 'Circe';
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 3.5rem;

  .viewMobile & {
    font-size: 20px;
    line-height: 29px;
  }
}

.subtitle {
  font-size: 1.4rem;
  line-height: 160%;
  margin-top: 2rem;

  &.short {
    max-width: 70%;
  }
}

.separator {
  height: 1px;
  background: #eaeaea;
  opacity: 0.5;
}

.block-first {
  display: flex;

  .viewMobile & {
    flex-direction: column;
    text-align: center;
  }

  .left {
    width: 43%;

    .viewMobile & {
      width: 100%;
    }
  }

  .title {
    font-size: 3.2rem;
    line-height: 4.7rem;
    font-family: 'Circe';
    font-weight: bold;
    color: #0a0a0a;

    .viewMobile & {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 2rem;
    }
  }

  .subtitle {
    font-family: 'Circe';
    font-weight: bold;
    font-size: 2rem;
    line-height: 2.9rem;
    color: #0a0a0a;
    margin-top: 5rem;

    .viewMobile & {
      font-size: 20px;
      line-height: 29px;
      margin-top: 0;
    }
  }

  .text {
    margin-top: 3rem;
    font-size: 1.4rem;
    line-height: 160%;
    color: #000000;
  }

  .right {
    width: 57%;
    display: flex;
    align-items: center;
    flex-direction: column;

    .viewMobile & {
      width: 100%;
      margin-bottom: 0;
      margin-top: 6rem;
    }

    .circlestat {
      position: relative;
      margin-top: 10rem;
      width: 27rem;
      height: 27rem;

      .viewMobile & {
        width: 21rem;
        height: 21rem;
        margin-top: 0;
      }

      & .progressBar {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;

        & > div {
          .viewMobile & {
            width: 21rem !important;
          }
        }

        & svg {
          width: 27rem;
          height: 27rem;

          .viewMobile & {
            width: 21rem;
            height: 21rem;
          }
        }
      }

      .circle-content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 15rem;
        height: 15rem;
        display: flex;
        align-items: center;
        justify-content: center;

        .viewMobile & {
          width: 9rem;
          height: 9rem;
        }

        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
        }
      }

      .user-circle {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;

        &.first {
          top: -4rem;
          left: -7rem;

          .sum {
            font-family: 'Circe';
            font-weight: bold;
            font-size: 1.8rem;
            line-height: 2.7rem;
            text-align: center;
            padding: 0.4rem 1rem 0.3rem 1rem;
            color: #ffffff;
            background: #ff3e6c;
            border-radius: 50px;
            position: relative;
            top: -1rem;
          }

          .viewMobile & {
            top: -2rem;
            left: -3.5rem;

            img {
              width: 5.4rem;
              height: 5.4rem;
            }

            .sum {
              font-size: 1.4rem;
              line-height: 2rem;
              height: 2rem;
              padding: 0 0.4rem;
            }
          }
        }
        &.second {
          right: -5rem;
          bottom: -4rem;
          .sum {
            font-family: 'Circe';
            font-weight: bold;
            font-size: 1.4rem;
            line-height: 2.1rem;
            text-align: center;
            padding: 0.4rem 1rem 0.3rem 1rem;
            color: #ffffff;
            background: #7049e5;
            border-radius: 50px;
            position: relative;
            top: -1rem;
          }

          .viewMobile & {
            right: -3.5rem;
            bottom: -2.5rem;

            img {
              width: 4.6rem;
              height: 4.6rem;
            }

            .sum {
              font-size: 1.4rem;
              line-height: 2rem;
              height: 2rem;
              padding: 0 0.4rem;
            }
          }
        }
      }
    }

    .subinfo {
      text-align: center;
      font-size: 1.4rem;
      line-height: 160%;
      color: #b0b0b0;
      margin-top: 1.5rem;
    }
  }
}

.separator {
  width: 100%;
  margin: 6rem 0;
  background: #e4e4e4;
  opacity: 1;

  .viewMobile & {
    margin: 2.8rem 0;
  }

  &.high {
    margin: 8.5rem 0;
  }
}

.block-second {
  display: flex;

  .viewMobile & {
    text-align: center;
    flex-direction: column;
  }

  .left {
    font-family: 'Circe';
    font-weight: bold;
    font-size: 2rem;
    line-height: 2.9rem;
    color: #0a0a0a;
    width: 33%;
    flex-shrink: 0;

    .viewMobile & {
      width: 100%;
      margin-top: 1.8rem;
      margin-bottom: 1.8rem;

      .title {
        font-size: 2.4rem;
        line-height: 3.5rem;
      }
    }
  }

  .right {
    font-size: 1.4rem;
    line-height: 160%;
    color: #000000;
    max-width: 35rem;

    .viewMobile & {
      width: 100%;
    }
  }
}

.block-third {
  display: flex;
  align-items: center;

  .viewMobile & {
    flex-direction: column;
    text-align: center;

    .title {
      font-size: 24px;
      line-height: 35px;
    }
  }

  .left {
    flex-shrink: 0;

    .viewMobile & {
      width: 100%;
    }

    img {
      width: 28rem;
    }
    // .house-img {
    //     width:37rem;
    // }
  }

  .right {
    margin-left: 5rem;

    .viewMobile & {
      width: 100%;
      margin-left: 0;
    }
  }

  &.reverse {
    flex-direction: row-reverse;

    .viewMobile & {
      flex-direction: column;
    }

    .right {
      margin-left: 0;
      width: 41rem;

      .viewMobile & {
        width: 100%;
      }
    }

    .left {
      margin-left: 5rem;
      width: 28rem;
      height: 37rem;
      position: relative;

      img {
        position: absolute;
        width: 37rem;
        top: 0;
        left: 0;

        .viewMobile & {
          position: relative;
          width: 100%;
        }
      }

      .viewMobile & {
        width: 100%;
        height: auto;
        margin-left: 0;
      }
    }
  }
}

.block-fourth {
  text-align: center;
  margin: 0 auto;
  max-width: 49rem;
  margin-top: 10rem;

  .viewMobile & {
    max-width: 100%;
    margin-top: 5rem;

    .title {
      font-size: 24px;
      line-height: 35px;
    }
  }
}

.block-five {
  .title {
    text-align: center;

    .viewMobile & {
      font-size: 24px;
      line-height: 35px;
    }
  }

  .subtitle {
    max-width: 59rem;
    text-align: center;
    margin: 2rem auto 0 auto;

    p {
      margin: 0.5rem 0;
    }
  }

  .flex-cards {
    display: flex;
    align-items: center;
    margin-top: 5rem;
    justify-content: space-between;

    .viewMobile & {
      flex-direction: column;
    }

    .card {
      background: #ffffff;
      box-shadow: 0px 4px 20px #eaeaea;
      border-radius: 10px;
      width: calc(50% - 7.5rem);
      height: 33rem;
      text-align: left;
      padding: 5rem 2rem 4rem 4rem;
      position: relative;

      .viewMobile & {
        width: 100%;
        box-sizing: border-box;
        height: auto;
        margin-bottom: 4rem;
        padding-left: 3.6rem;
        padding-right: 3.6rem;
      }

      .title {
        margin-top: 2rem;
        color: #ff3e6c;
        font-size: 2rem;
        text-align: left;
      }
      .subtitle {
        margin-top: 1rem;
        text-align: left;
      }

      .btn-block {
        position: absolute;
        bottom: 4rem;
        left: 4rem;

        .viewMobile & {
          position: relative;
          left: 0;
          bottom: 0;
        }
      }
    }
  }
}

.block-six {
  margin: 0 auto;
  max-width: 59rem;
  text-align: center;
  // padding-bottom:10rem;

  .title {
    .viewMobile & {
      font-size: 24px;
      line-height: 35px;
    }
  }

  .links-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3rem;

    .viewMobile & {
      flex-direction: column;
      justify-content: initial;
    }

    .link-item {
      font-size: 1.4rem;
      color: #ff3e6c;
      background: transparent;
      border: 0;
      padding: 0;
      width: 100%;

      .viewMobile & {
        margin-bottom: 2.4rem;
      }
    }
  }
}

.btn-block {
  margin-top: 3rem;

  .btn {
    width: fit-content;
    display: block;
    font-family: 'Circe';
    font-weight: bold;
    font-size: 1.6rem;
    text-align: center;
    padding: 1rem 4rem;
    border-radius: 30px;
    border: none;

    .viewMobile & {
      width: 100%;
      box-sizing: border-box;
      padding: 1.3rem 0;
      height: 4.2rem;
      margin: 0 auto;
    }
  }

  .red-btn {
    color: #ffffff;
    background: #ff3e6c;
    box-shadow: 0px 4px 10px rgba(255, 0, 76, 0.42);
  }
  .white-btn {
    background: #ffffff;
    box-shadow: 0px 4px 20px #eaeaea;
    color: #000000;
    margin: 0 auto;
  }
}
