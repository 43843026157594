.progress_bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem;
  position: absolute;
  top: 3rem;
  left: 0;
  z-index: 3;
  box-sizing: border-box;
  width: 100%;
  transition: opacity 0.3s ease;
  opacity: 1;

  &.hidden {
    opacity: 0;
  }
}
.progress_item {
  background: rgba(240, 240, 240, 0.6);
  border-radius: 0.3rem;
  height: 0.4rem;
  overflow: hidden;
}
.progress_inner {
  background: #fff;
  height: 0.4rem;
  transform-origin: left center;
}
