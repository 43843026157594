.title {
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #ff3e6c;
  font-weight: bold;
  margin: 2rem 2rem 0rem;

  &:first-child {
    margin-top: 0;
  }
}

.bday_container {
  background: #f8f9fb;
  box-shadow: 0px 1px 0px #f0f0f0;
  padding: 2.5rem 0 3rem;

  .bday_info {
    display: flex;
    justify-content: space-between;
    margin: 0 2rem;
    margin-bottom: 0.5rem;

    .text {
      font-family: 'Circe';
      font-style: normal;
      font-weight: 700;
      font-size: 1.7rem;
      line-height: 2.5rem;
      color: #ff3e6c;
    }
    .all {
      font-family: 'Circe';
      font-style: normal;
      font-weight: 700;
      font-size: 1.7rem;
      line-height: 2.5rem;
      color: #adadad;
    }
  }

  .title {
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 100%;
    color: #747474;
    margin-top: 0;
  }
}
