.donate_button_block {
  margin-top: 1.5rem;

  button {
    font-family: 'Circe';
    margin: 0 auto;
    padding: 0.5rem 0;
    background: #ff3e6c;
    border: none;
    box-shadow: none;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    font-size: 1.8rem;
    line-height: 2.1rem;
    text-transform: none;
    font-weight: bold;
    border-radius: 3rem;
    height: 50px;
    color: #ffffff;
    max-width: 30rem;
    width: 100%;

    &.gpay_checkout {
      height: 7.5rem;
      background: #000000;
      border-radius: 0.5rem;
      padding: 0;

      img {
        margin-left: 0;
        height: 3rem;
      }

      .text {
        display: flex;

        img {
          width: auto;
          display: block;
        }
      }
    }

    &.apple_pay_checkout {
      background: #000000;

      .text {
        display: flex;
        font-weight: normal;
      }

      img {
        width: auto;
        height: 2rem;
        display: block;
        margin-left: 1rem;
      }
    }

    .loader {
      img {
        height: 2rem;
        display: block;
        margin: 0;
      }
    }
    &.disabled {
      // opacity: 0.7;
      pointer-events: none;
    }
  }
  &.disabled {
    opacity: 0.7;
    pointer-events: none;
  }
}
